import { getHelloKlarityProviderSettingData } from 'api/operation';
import { useCallback, useEffect, useState } from 'react';
import { IGetProviderMappingDataInput, IProviderMappingDataResp } from 'types/operation';

const useGetProviderSettingData = (autoLoad?: boolean, props?: IGetProviderMappingDataInput): [IProviderMappingDataResp | undefined, boolean, string, (p: IGetProviderMappingDataInput) => void] => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetch = useCallback(async (params: IGetProviderMappingDataInput = {}) => {
        setLoading(true);

        try {
            const res = await getHelloKlarityProviderSettingData(params);

            if (res && !res.error) {
                setData(res.data?.data);
            } else {
                setError(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (autoLoad) {
            fetch(props);
        }
    }, [autoLoad, fetch, props]);

    return [data, loading, error, fetch];
};

export default useGetProviderSettingData;
