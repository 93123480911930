import React from 'react';
import s from './s.module.less';
import InviteBanner from './components/InviteBanner';
import ReferDetailInfo from './components/ReferDetailInfo';
import useGetProviderReferralInfo from 'hooks/referral/useGetProviderReferralInfo';
import { Spin } from 'antd';
import NonReferralData from './components/NonReferralData';

const ReferNRewards = () => {
    const [providerReferralInfo, loading] = useGetProviderReferralInfo();

    return (
        <Spin spinning={loading}>
            <div className={s.wrap}>
                {
                    providerReferralInfo ? (
                        <InviteBanner referralInfo={providerReferralInfo} />
                    ) : (
                        <NonReferralData />
                    )
                }
                <ReferDetailInfo />
            </div>
        </Spin>
    );
};

export default ReferNRewards;
