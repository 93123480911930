import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import s from './s.module.less';
import { Button, Checkbox, message } from 'antd';
// import ImageWithMainBackgroundColor from "components/ImageWithMainBackgroundColor/ImageWithMainBackgroundColor";
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import AlertStore from 'store/GlobalAlert';
import { joinCampaign } from 'api/campaign';
import CampaignStore from 'store/Campaign';
import { closeCampaignModal, showCampaignTermsNConditionsModal } from 'utils/globalLayerControl';
import dayjs from 'utils/dayjs';
import CampaignConfig from 'data/CampaignConfig';
import { replaceCurlyBracesAndContent } from 'utils/common';

const JoinCampaignModalTemplate = () => {
    const defaultProviderNumbers = useRef([35, 73]);
    const [, setGlobalAlert] = AlertStore.useStore();
    const [getSelectedCampaign, setSelectedCampaign] = CampaignStore.useStore();
    const [checkedOption, setCheckedOption] = React.useState<string>('');
    const [hasCheckedTerms, setHasCheckedTerms] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const selectedCampaign = getSelectedCampaign('selectedCampaign');
    const priceList = selectedCampaign?.stringDiscountValue ? selectedCampaign?.stringDiscountValue.split(',') : [];
    const providerEndDateTime = selectedCampaign?.providerEndDateTime || '';
    const providerStartDateTime = selectedCampaign?.providerStartDateTime || '';
    const campaignTitle = selectedCampaign?.campaignName || '';
    const campaignDesc = selectedCampaign?.description || '';
    const limitPerUser = selectedCampaign?.limitPerUser;
    const excludedStates = selectedCampaign?.excludedStates;
    const stringJoinedCount = selectedCampaign?.stringJoinedCount ? selectedCampaign?.stringJoinedCount.split(',') : defaultProviderNumbers.current;
    const campaignCode = selectedCampaign?.campaignCode;

    const configs = CampaignConfig[campaignCode as keyof typeof CampaignConfig];

    useEffect(() => {
        // 由于getSelectedCampaign('selectedCampaign')会返回新的
        // 这里dependency 因为是string，取巧可以这样处理，如果后面改成复杂类型了，需要注意会无限触发这里的useEffect.
        const price = selectedCampaign?.stringDiscountValue ? selectedCampaign?.stringDiscountValue.split(',') : [];
        if (price && price.length > 0) {
            const highestDiscount = Math.max(...price.map(Number));

            setCheckedOption(String(highestDiscount));
        }
    }, [selectedCampaign?.stringDiscountValue]);

    const statusTag = useMemo(() => {
        return !providerStartDateTime && !providerEndDateTime ? '' : (
            <div
                className={s.statusTag}
                style={{
                    background: configs?.homepageBanner?.limitationBackground,
                    color: configs?.homepageBanner?.limitationColor,
                }}
            >Limited-time: {providerStartDateTime ? dayjs(providerStartDateTime).format('MMM DD') : ''} - {providerEndDateTime ? dayjs(providerEndDateTime).format('MMM DD') : ''}
            </div>
        );
    }, [providerEndDateTime, providerStartDateTime, configs]);

    const handleJoin = useCallback(async () => {
        if (selectedCampaign) {
            setLoading(true);
            const { campaignId } = selectedCampaign;
            try {
                const res = await joinCampaign({
                    campaignId,
                    discountValue: Number(checkedOption),
                });

                if (res && !res.error) {
                    closeCampaignModal();
                    setSelectedCampaign('forceRefetchCampaignList', {});
                    setSelectedCampaign('selectedCampaign', null);
                    const successText = replaceCurlyBracesAndContent(configs.joinedSuccessMessage.text, `${checkedOption}%`);
                    setGlobalAlert('info', {
                        type: 'success',
                        show: true,
                        message: <div className={s.alertText}>{successText}</div>,
                    });
                } else {
                    message.error(res.error);
                }
            } catch (e) {
                console.error(e);
            }
            setLoading(false);
        }
    }, [selectedCampaign, checkedOption, configs]);

    const handleShowTermsNConditions = useCallback((e: any) => {
        e.stopPropagation();
        showCampaignTermsNConditionsModal();
    }, []);

    if (!configs) {
        return null;
    }

    return (
        <div className={s.modalContent}>
            {statusTag}
            <div className={s.modalTitle}>{campaignTitle}</div>
            <div className={s.modalDesc}>{campaignDesc}</div>
            <div className={s.detailInfo}>
                <div className={s.label}>Excluded States for Participation:</div>
                <div className={s.value}>{excludedStates || 'None'}</div>
            </div>
            <div className={s.detailInfo}>
                <div className={s.label}>Limit per user:</div>
                <div className={s.value}>{limitPerUser || 'Unlimited'}</div>
            </div>
            <div
                className={s.pickBox}
                style={{
                    background: configs.SelectionPopup.selectionAreaBackground,
                }}
            >
                <img
                    className={s.campaignIcon}
                    src={configs.SelectionPopup.icon.url}
                    style={{
                        width: configs.SelectionPopup.icon.width,
                        height: configs.SelectionPopup.icon.height,
                    }}
                />
                <div className={s.boxInner}>
                    <div className={s.pickTitle}>{'Choose the discounts you\'d like to offer and take advantage of this unique opportunity to increase your visibility and maximize bookings during this high-demand period.'}</div>
                    <div className={s.pickOptions}>
                        {
                            priceList.map((i: string, index: number) => {
                                const isChecked = checkedOption === i;
                                const joinedCount = stringJoinedCount[index];

                                return (
                                    <div key={i} className={`${s.option} ${isChecked ? s.checked : ''}`} onClick={() => setCheckedOption(i)}>
                                        <div className={s.checkedIcon} />
                                        <div className={s.optionTitle}>{i}% off</div>
                                        <div className={s.optionDesc}>On all initial visits</div>
                                        <div className={s.avatarsBox}>
                                            <div className={s.providerAvatars}>
                                                <div className={s.avatarGroup} />
                                            </div>
                                            <div className={s.text}>{joinedCount} providers joined</div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={s.termsBox}>
                <Checkbox checked={hasCheckedTerms} onChange={(e: CheckboxChangeEvent) => setHasCheckedTerms(e.target.checked)}>By checking this box, I have reviewed and accept the <a className={s.linkPopup} onClick={handleShowTermsNConditions}>Terms & Conditions</a>.</Checkbox>
                <Button type="primary" loading={loading} disabled={!hasCheckedTerms} onClick={handleJoin}>{`Confirm with offering ${checkedOption}% off on all your initial visits`}</Button>
            </div>
        </div>
    );
};

export default JoinCampaignModalTemplate;
