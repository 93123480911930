import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
import LineBar from '../../LineBar';
import { ICampaign } from 'types/campaign';
import dayjs from 'utils/dayjs';
import CampaignStore from 'store/Campaign';
import { showCampaignModal, showLeaveCampaignModal } from 'utils/globalLayerControl';
import CampaignConfig from 'data/CampaignConfig';

interface IProps {
    campaign: ICampaign;
}

const Reach = ({
    campaign,
}: IProps) => {
    const [, setSelectedCampaign] = CampaignStore.useStore();
    const { campaignName, description, providerEndDateTime, providerStartDateTime, joined, iconUrl, campaignCode } = campaign;
    const configs = CampaignConfig[campaignCode as keyof typeof CampaignConfig];

    const handleJoin = useCallback(() => {
        setSelectedCampaign('selectedCampaign', campaign);
        showCampaignModal();
    }, [campaign]);

    const handleLeaveGroup = useCallback(() => {
        setSelectedCampaign('selectedCampaign', campaign);
        showLeaveCampaignModal();
    }, [campaign]);

    const statusTag = useMemo(() => {
        return !providerStartDateTime && !providerEndDateTime ? '' : (
            <div
                className={s.statusTag}
                style={{
                    background: configs?.homepageBanner?.limitationBackground,
                    color: configs?.homepageBanner?.limitationColor,
                }}
            >{providerStartDateTime ? dayjs(providerStartDateTime || '').format('MMM DD') : ''} - {providerEndDateTime ? dayjs(providerEndDateTime || '').format('MMM DD') : ''}
            </div>
        );
    }, [providerEndDateTime, providerStartDateTime, configs]);

    if (!configs) {
        return null;
    }

    return (
        <div className={s.wrap}>
            <LineBar
                statusTag={statusTag}
                lineBarId="kiwi-2-1"
                icon="icon-7"
                backgroundImage={iconUrl}
                title={campaignName}
                description={description}
                cta={joined ? 'Leave the campaign' : 'Join now'}
                details=""
                onCta={joined ? handleLeaveGroup : handleJoin}
            />
        </div>
    );
};

export default Reach;
