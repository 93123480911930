// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/referral/non_referral.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--rTUqm {
  padding: 24px 48px;
  border-radius: 8px;
  background: var(--white, #FFF);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
}
.s-module__wrap--rTUqm .s-module__icon--SLG5U {
  margin-right: 24px;
  flex: 95px;
  height: 95px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  background-size: cover;
}
.s-module__wrap--rTUqm .s-module__content--FpAug .s-module__title--MlfPz {
  margin-bottom: 16px;
  color: var(--Primary-Black, #15191E);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 100% */
}
.s-module__wrap--rTUqm .s-module__content--FpAug .s-module__desc--gbPBA {
  color: var(--gray-700, #374151);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}
`, "",{"version":3,"sources":["webpack://./src/components/ReferNRewards/components/NonReferralData/s.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kBAAA;EACA,8BAAA;EACA,uFAAA;EACA,aAAA;AACJ;AANA;EAQQ,kBAAA;EACA,UAAA;EACA,YAAA;EACA,oEAAA;EACA,sBAAA;AACR;AAbA;EAiBY,mBAAA;EACA,oCAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EADV,SAAS;AACX;AAvBA;EA2BY,+BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EADV,aAAa;AACf","sourcesContent":[".wrap {\n    padding: 24px 48px;\n    border-radius: 8px;\n    background: var(--white, #FFF);\n    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);\n    display: flex;\n\n    .icon {\n        margin-right: 24px;\n        flex: 95px;\n        height: 95px;\n        background: url('assets/referral/non_referral.svg') no-repeat center;\n        background-size: cover;\n    }\n\n    .content {\n        .title {\n            margin-bottom: 16px;\n            color: var(--Primary-Black, #15191E);\n            font-family: Inter;\n            font-size: 20px;\n            font-style: normal;\n            font-weight: 700;\n            line-height: 20px; /* 100% */\n        }\n\n        .desc {\n            color: var(--gray-700, #374151);\n            font-family: Inter;\n            font-size: 14px;\n            font-style: normal;\n            font-weight: 500;\n            line-height: 20px; /* 142.857% */\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--rTUqm`,
	"icon": `s-module__icon--SLG5U`,
	"content": `s-module__content--FpAug`,
	"title": `s-module__title--MlfPz`,
	"desc": `s-module__desc--gbPBA`
};
export default ___CSS_LOADER_EXPORT___;
