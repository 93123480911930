import { Request } from '../request';

export enum ETimeType {
    THIS_WEEK = 'LAST_7_DAYS',
    LAST_14_DAYS = 'LAST_14_DAYS',
    LAST_30_DAYS = 'LAST_30_DAYS',
    LAST_90_DAYS = 'LAST_90_DAYS',
}

export const getDashboard = (timeSelection: number) => Request.get({ url: `/schedule-server/provider/home/dashboard/${timeSelection}`, disableErrorNote: true });
export const getTasksStatus = () => Request.get({ url: '/schedule-server/provider/home/task/status' });
export const getQuickAction = () => Request.get({ url: 'schedule-server/provider/home/quick-actions' });
