import React, { useState, useEffect, useMemo } from 'react';
import { Button, Form, Input, Modal, DatePicker, Switch, Select, Radio, InputNumber } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import commonS from 'styles/common.module.less';
import s from './s.module.less';

import { ECampaignConfigStatus, EDiscoutType, Service, TCampaignConfigData } from 'types/common';
import { FormFieldEnum, FormConfigItemModal } from './types';

import ProviderStore from 'store/Provider';

import { jumpToFormError } from 'utils/common';
import { useNavigate } from 'react-router-dom';
import { REQUIRED_FIELD } from 'constants/common';
import useOpsSubscriptionPlan from 'hooks/useOpsSubscriptionPlan';
import HtmlContentInput from 'components/form/HtmlContentInput';
import { saveOrUpdateReferralPrograms, setReferralProgramStatus } from 'api/operation';

type Props = {
    item?: TCampaignConfigData;
    onCancel?: () => void
    onSuccess?: () => void
    show?: boolean,
    allItems?: TCampaignConfigData[],
};

const ReferralUpdateModal = ({ show, onCancel, onSuccess, item, allItems }: Props) => {
    const [modal, contextHolder] = Modal.useModal();
    const navigate = useNavigate();
    const [submiting, setSubmiting] = useState(false);
    const [getUser] = ProviderStore.useStore();
    const [planList] = useOpsSubscriptionPlan();
    const kiwiPlanList = useMemo(() => planList.filter((e) => e.bizType === 'kiwi-plan' && !!e.priceId), [planList]);
    const user = getUser('data');
    const [form] = Form.useForm<FormConfigItemModal>();
    const [rewardRerAll, setRewardRerAll] = useState(false);
    const [rewardReeAll, setRewardReeAll] = useState(false);

    const [refereeType, setRefereeType] = useState(EDiscoutType.PERCENT_OFF);
    const [referrerType, setReferrerType] = useState(EDiscoutType.PERCENT_OFF);

    const [hasTimeError, setHasTimeError] = useState(false);

    React.useEffect(() => {
        if (!show) {
            form.resetFields();
            form.setFieldsValue({ [FormFieldEnum.refereeDiscountType]: EDiscoutType.PERCENT_OFF, [FormFieldEnum.referrerRewardDiscountType]: EDiscoutType.PERCENT_OFF });
            setRewardRerAll(false);
            setRewardReeAll(false);
        }
    }, [show]);

    const handleExistingPlan = (id: number) => {
        const exist = allItems?.find((item) => item.id === id);
        Modal.confirm({
            title: 'Date range has been occupied',
            content: (
                <div>
                    <p>{`The date range has been occupied by ${exist?.name}, do you want to pause it and schedule new one?`}</p>
                </div>
            ),
            wrapClassName: s.existModalWrap,
            okText: 'Pasue and schedule',
            onOk() {
                handlePausePlan(id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handlePausePlan = async (id: number) => {
        setSubmiting(true);
        const result = await setReferralProgramStatus({
            id,
            status: ECampaignConfigStatus.PAUSED,
        });
        if (!result.error) {
            const ret = await onSave();
        }
        setSubmiting(false);
    };
    const onSave = async () => {
        if (hasTimeError) {
            return;
        }
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();

            const params: Partial<TCampaignConfigData> = {
                name: formValues.name, //"30% off for both referral and referee",
                rules: formValues.rules, //"Refer a friend and both you and your friend will get 30% off on your next purchase.",
                referralTitle: formValues.referralTitle, //"Refer a Friend and Get 30% Off",
                referralMarketingContent: '', //"Share the love! Refer a friend and both of you will enjoy 30% off on your next purchase. It's a win-win!",
                refereeDiscountType: formValues.refereeDiscountType, //"PERCENT_OFF",
                refereeDiscountValue: formValues.refereeDiscountValue, //30.00,
                refereeApplyAllProducts: rewardReeAll, //false,

                refereeRewardPrice: rewardReeAll ? '' : formValues.refereeRewardPrice?.join(','), //["price_1PjKxXLWCWKPYTPjpR1glkou", "price_1PjKz9LWCWKPYTPjHF0l6jj3"],
                status: ECampaignConfigStatus.SCHEDULED,
                landingPageTitle: formValues.landingPageTitle, //"Refer a Friend and Save 30%",
                landingPageContent: formValues.landingPageContent, //"Refer a friend and both of you will get 30% off on your next purchase. Start saving today!",
                emailMsgContent: formValues.emailMsgContent, //"Hi [Friend's Name],\n\n[Your Name] has referred you to our store. As a special thank you, you'll get 30% off on your next purchase. Enjoy shopping!\n\nBest regards,\nThe [Store Name] Team",
                startDateTime: formValues.duration && formValues.duration.length > 0 ? formValues.duration[0].utc().format('YYYY-MM-DDTHH:mm:ssZ') : null,
                endDateTime: formValues.duration && formValues.duration.length > 1 && formValues.duration[1] ? formValues.duration[1]!.utc().format('YYYY-MM-DDTHH:mm:ssZ') : null, //"2024-12-31T23:59:59",
                referralReward: {
                    //更新带回来
                    // "stripeReferrerRewardCouponId": "ref_reward_coupon_123456789",
                    // PERCENT_OFF,AMOUNT_OFF
                    referrerRewardDiscountType: formValues.referrerRewardDiscountType, // "PERCENT_OFF",
                    referrerRewardDiscountValue: formValues.referrerRewardDiscountValue, // 30,
                    referrerRewardApplyAllProducts: rewardRerAll, //false,
                    referrerRewardPrice: rewardRerAll ? '' : formValues.referrerRewardPrice?.join(','), //["price_1PjKxXLWCWKPYTPjpR1glkou", "price_1PjKz9LWCWKPYTPjHF0l6jj3"],
                },
            };
            // const lowestPriceVal = formValues[ServiceFormFieldEnum.LOWEST_PRICE] as number;
            // const highestPriceVal = formValues[ServiceFormFieldEnum.HIGHEST_PRICE] as number;
            // if (enableSlidingScaleCheckStatus && lowestPriceVal > highestPriceVal) {
            //     message.error('Highest price should be greater than lowest price');
            //     return;
            // }
            // if (!enableSlidingScaleCheckStatus) {
            //     params.lowestPrice = undefined;
            //     params.highestPrice = undefined;
            // }
            // let updateFun = isOps ? updateProviderService : providerServiceUpdate;
            // let createFun = isOps ? createProviderService : providerServiceCreate;
            // if (isTemplate) {
            //     updateFun = serviceTemplateUpdate;
            //     createFun = serviceTemplateCreate;
            // }
            // const apiFunc = isEdit ? updateFun(params, serviceId!) : createFun(params);
            const result = await saveOrUpdateReferralPrograms(params);
            if (!result?.data?.data && !result.error) {
                onSuccess?.();
            } else {
                handleExistingPlan(result?.data?.data);
                return;
            }
        } catch (error) {
            console.error(error);
            jumpToFormError(document.getElementById('services_modal')?.parentNode as any || undefined);
        } finally {
            setSubmiting(false);
        }
    };

    useEffect(() => {
        if (item && planList && planList.length > 0) {
            const supportPriceIds = planList.map((item) => item.priceId);
            // const {
            //     colorId,
            //     description,
            //     duration, name,
            //     personAddress,
            //     personCity, personState,
            //     personType, personZip, sendForm,
            //     telehealthType, price, type,
            //     addressId, enableSlidingScale,
            //     lowestPrice, highestPrice,
            // } = serviceItem;

            // if (telehealthType) setIsCheckedTelehealth(true);
            // if (personType) setIsCheckedInPerson(true);
            // if (isEdit && !telehealthType && !personType) setIsCheckedNoMeeting(true);

            form.setFieldsValue({
                [FormFieldEnum.name]: item.name,
                [FormFieldEnum.rules]: item.rules,
                [FormFieldEnum.referralTitle]: item.referralTitle,
                [FormFieldEnum.referralMarketingContent]: item.referralMarketingContent || '',
                [FormFieldEnum.refereeDiscountType]: item.refereeDiscountType,
                [FormFieldEnum.refereeDiscountValue]: item.refereeDiscountValue,
                [FormFieldEnum.refereeApplyAllProducts]: item.refereeApplyAllProducts,
                [FormFieldEnum.refereeRewardPrice]: item.refereeRewardPrice?.split(',').filter(Boolean).filter((item) => supportPriceIds.includes(item)),
                [FormFieldEnum.referrerRewardDiscountType]: item.referralReward?.referrerRewardDiscountType,
                [FormFieldEnum.referrerRewardDiscountValue]: item.referralReward?.referrerRewardDiscountValue,
                [FormFieldEnum.referrerRewardApplyAllProducts]: item.referralReward?.referrerRewardApplyAllProducts,
                [FormFieldEnum.referrerRewardPrice]: item.referralReward?.referrerRewardPrice?.split(',').filter(Boolean).filter((item) => supportPriceIds.includes(item)),
                [FormFieldEnum.landingPageTitle]: item.landingPageTitle,
                [FormFieldEnum.landingPageContent]: item.landingPageContent,
                [FormFieldEnum.emailMsgContent]: item.emailMsgContent,
                [FormFieldEnum.duration]: item.startDateTime ? [dayjs(item.startDateTime), item.endDateTime ? dayjs(item.endDateTime) : null] : undefined,
            });
            setRewardRerAll(!!item.referralReward?.referrerRewardApplyAllProducts);
            setRewardReeAll(!!item.refereeApplyAllProducts);
            // setEnableSlidingScaleCheckStatus(!!enableSlidingScale);
        }
    }, [item, form, planList]);

    const disableEdit = !!item;

    const isOverLap = (time1: { start: dayjs.Dayjs, end: dayjs.Dayjs | null | undefined }, time2: { start: dayjs.Dayjs, end: dayjs.Dayjs | null | undefined }) => {
        if (!time1.end && !time2.end) {
            return true;
        }
        if (!time1.end && time2.end) {
            return !time2.end.isBefore(time1.start);
        }
        if (time1.end && !time2.end) {
            return !time1.end.isBefore(time2.start);
        }
        return !(time1.start.isAfter(time2.end) || time1.end!.isBefore(time2.start));
    };

    const handleRangeChange = (start: dayjs.Dayjs | null | undefined, end: dayjs.Dayjs | null | undefined) => {
        const exist = allItems?.filter((item) => item.status === ECampaignConfigStatus.SCHEDULED || item.status === ECampaignConfigStatus.IN_PROGRESS);
        let isOverLapTime = false;
        if (exist && exist.length > 0) {
            exist.forEach((item) => {
                const time1 = { start: start!, end };
                const time2 = { start: dayjs(item.startDateTime), end: item.endDateTime ? dayjs(item.endDateTime) : null };
                console.log(isOverLap(time1, time2));
                if (isOverLap(time1, time2)) {
                    isOverLapTime = true;
                }
            });
        }
        setHasTimeError(isOverLapTime);
        if (isOverLapTime) {
            setTimeout(() => {
                form.setFields([
                    {
                        name: 'duration',
                        errors: ['The selected dates overlap with an existing referral program'],
                    },
                ]);
            }, 500);
        }
    };

    return (
        <>
            <Modal
                title={item ? 'View referral program' : 'New referral program'}
                closable
                className={`${commonS.modalFixHeightWrap} ${s.modalWrap}`}
                width="900px"
                destroyOnClose
                onCancel={onCancel}
                okText="Save"
                open={show}
                footer={
                    item ?
                        <div>
                            <Button onClick={onCancel}>Done</Button>
                        </div> :
                        <div>
                            <Button onClick={onCancel}>Cancel</Button>
                            <Button loading={submiting} type="primary" onClick={onSave}>Save</Button>
                        </div>
                }
                onOk={onSave}
            >
                <div className={s.wrap} id="services_modal">
                    <Form
                        form={form}
                        name="basic"
                        className={commonS.formStyle1}
                        initialValues={{ [FormFieldEnum.refereeDiscountType]: EDiscoutType.PERCENT_OFF, [FormFieldEnum.referrerRewardDiscountType]: EDiscoutType.PERCENT_OFF }}
                        autoComplete="off"
                        layout="vertical"
                        scrollToFirstError
                    >
                        <>
                            <h3>Basic information</h3>
                            <div className={s.itemWrap}>
                                <h4>Referral program information </h4>
                                <div className={s.row}>
                                    <Form.Item
                                        className={s.rowItem}
                                        label="Referral program name"
                                        name={FormFieldEnum.name}
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <Input
                                            disabled={disableEdit}
                                            style={{ width: '100%' }}
                                            maxLength={60}
                                            showCount
                                        />
                                    </Form.Item>
                                </div>
                                <div className={s.row}>
                                    <Form.Item
                                        className={s.rowItem}
                                        label="Rules"
                                        name={FormFieldEnum.rules}
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <Input.TextArea
                                            disabled={disableEdit}
                                            rows={5}
                                            style={{ width: '100%' }}
                                            maxLength={600}
                                            showCount
                                        />
                                    </Form.Item>
                                </div>
                                <div className={s.row}>
                                    <Form.Item
                                        className={s.rowItem}
                                        label="Duration"
                                        name={FormFieldEnum.duration}
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <DatePicker.RangePicker
                                            disabled={disableEdit}
                                            allowEmpty={[false, true]}
                                            style={{ width: '500px' }}
                                            showTime={{ format: 'HH:mm' }}
                                            format="YYYY-MM-DD HH:mm"
                                            onOpenChange={(open) => {
                                                if (!open) {
                                                    const star = form.getFieldValue(FormFieldEnum.duration)?.[0];
                                                    const end = form.getFieldValue(FormFieldEnum.duration)?.[1];
                                                    handleRangeChange(star, end);
                                                }
                                            }}
                                            disabledDate={(current) => {
                                                // 返回 true 将禁用该日期
                                                return current && current < dayjs().startOf('day');
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.itemWrap}>
                                <h4>Referral program marketing</h4>
                                <div className={`${s.row} ${s.column}`}>
                                    <div className={`${s.label} ${s.required}`}>Referral promotion title</div>
                                    <div className={s.desc}>{'If the referral input requires variable, please put the variable with ‘{}’, e.g. Earn 30% off -> Earn {30%} off'}</div>
                                    <Form.Item
                                        className={s.rowItem}
                                        // label="Referral promotion title"
                                        name={FormFieldEnum.referralTitle}
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <Input
                                            disabled={disableEdit}
                                            style={{ width: '100%' }}
                                            maxLength={150}
                                            showCount
                                        />
                                    </Form.Item>
                                </div>
                                {/* <div className={s.row}>
                                    <Form.Item
                                        className={s.rowItem}
                                        label="Referral marketing content"
                                        name={FormFieldEnum.referralMarketingContent}
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <Input.TextArea
                                            disabled={disableEdit}
                                            rows={5}
                                            style={{ width: '100%' }}
                                            maxLength={600}
                                            showCount
                                        />
                                    </Form.Item>
                                </div> */}
                            </div>
                            <div className={s.itemWrap}>
                                <h4>Referrer rewards</h4>
                                <div className={s.row}>
                                    <Form.Item
                                        className={s.rowItem}
                                        label="Products applied"
                                        name={FormFieldEnum.referrerRewardApplyAllProducts}
                                        style={{ width: '100%' }}
                                    >
                                        <Switch
                                            disabled={disableEdit}
                                            onChange={(e) => {
                                                setRewardRerAll(e);
                                            }}
                                            checked={rewardRerAll}
                                        />
                                        <span style={{ marginLeft: '16px' }}>Apply to all products</span>
                                    </Form.Item>
                                </div>
                                {
                                    !rewardRerAll && (
                                        <div className={s.row}>
                                            <Form.Item
                                                className={s.rowItem}
                                                label={null}
                                                name={FormFieldEnum.referrerRewardPrice}
                                                style={{ width: '100%', marginTop: '-20px' }}
                                                rules={[{ required: true, message: REQUIRED_FIELD }]}
                                            >
                                                <Select
                                                    disabled={disableEdit}
                                                    mode="multiple"
                                                    style={{ width: '50%' }}
                                                    placeholder="Select a plan"
                                                >
                                                    {
                                                        kiwiPlanList?.map((item) => {
                                                            return (
                                                                <Select.Option value={item.priceId} key={item.priceId}>
                                                                    {item.type} {item.subType}
                                                                </Select.Option>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    )
                                }
                                <div className={s.row}>
                                    <Form.Item
                                        className={s.rowItem}
                                        label="Discount type"
                                        name={FormFieldEnum.referrerRewardDiscountType}
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <Radio.Group
                                            disabled={disableEdit}
                                            onChange={(e) => {
                                                setReferrerType(e.target.value);
                                            }}
                                        >
                                            <Radio value={EDiscoutType.PERCENT_OFF}>Percentage off</Radio>
                                            <Radio value={EDiscoutType.AMOUNT_OFF}>Fixed amount</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div className={s.row}>
                                    <Form.Item
                                        className={s.rowItem}
                                        label={null}
                                        name={FormFieldEnum.referrerRewardDiscountValue}
                                        style={{ width: '100%', marginTop: '-20px' }}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <InputNumber
                                            disabled={disableEdit}
                                            placeholder={referrerType === EDiscoutType.PERCENT_OFF ? '% off' : 'Input amount'}
                                            style={{ width: '300px' }}
                                            max={referrerType === EDiscoutType.PERCENT_OFF ? 100 : undefined}
                                            addonAfter={referrerType === EDiscoutType.PERCENT_OFF ? '%' : 'USD'}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className={s.itemWrap}>
                                <h4>Referee rewards</h4>
                                <div className={s.row}>
                                    <Form.Item
                                        className={s.rowItem}
                                        label="Products applied"
                                        name={FormFieldEnum.refereeApplyAllProducts}
                                        style={{ width: '100%' }}
                                    >
                                        <Switch
                                            disabled={disableEdit}
                                            onChange={(e) => {
                                                setRewardReeAll(e);
                                            }}
                                            checked={rewardReeAll}
                                        />
                                        <span style={{ marginLeft: '16px' }}>Apply to all products</span>
                                    </Form.Item>
                                </div>
                                {
                                    !rewardReeAll && (
                                        <div className={s.row}>
                                            <Form.Item
                                                className={s.rowItem}
                                                label={null}
                                                name={FormFieldEnum.refereeRewardPrice}
                                                style={{ width: '100%', marginTop: '-20px' }}
                                                rules={[{ required: true, message: REQUIRED_FIELD }]}
                                            >
                                                <Select
                                                    disabled={disableEdit}
                                                    mode="multiple"
                                                    style={{ width: '50%' }}
                                                    placeholder="Select a plan"
                                                >
                                                    {
                                                        kiwiPlanList?.map((item) => {
                                                            return (
                                                                <Select.Option value={item.priceId} key={item.priceId}>
                                                                    {item.type} {item.subType}
                                                                </Select.Option>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    )
                                }
                                <div className={s.row}>
                                    <Form.Item
                                        className={s.rowItem}
                                        label="Discount type"
                                        name={FormFieldEnum.refereeDiscountType}
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <Radio.Group
                                            disabled={disableEdit}
                                            onChange={(e) => {
                                                setRefereeType(e.target.value);
                                            }}
                                        >
                                            <Radio value={EDiscoutType.PERCENT_OFF}>Percentage off</Radio>
                                            <Radio value={EDiscoutType.AMOUNT_OFF}>Fixed amount</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div className={s.row}>
                                    <Form.Item
                                        className={s.rowItem}
                                        label={null}
                                        name={FormFieldEnum.refereeDiscountValue}
                                        style={{ width: '100%', marginTop: '-20px' }}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <InputNumber
                                            disabled={disableEdit}
                                            placeholder={refereeType === EDiscoutType.PERCENT_OFF ? '% off' : 'Input amount'}
                                            style={{ width: '300px' }}
                                            max={refereeType === EDiscoutType.PERCENT_OFF ? 100 : undefined}
                                            addonAfter={refereeType === EDiscoutType.PERCENT_OFF ? '%' : 'USD'}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <h3>Referral message example</h3>
                            <div className={s.row}>
                                <Form.Item
                                    className={s.rowItem}
                                    label="Referral message example content"
                                    name={FormFieldEnum.emailMsgContent}
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: REQUIRED_FIELD }]}
                                >
                                    <Input.TextArea
                                        disabled={disableEdit}
                                        rows={5}
                                        style={{ width: '100%' }}
                                        maxLength={600}
                                        showCount
                                    />
                                </Form.Item>
                            </div>

                            <h3>Referee landing page</h3>
                            <div className={s.row}>
                                <Form.Item
                                    className={s.rowItem}
                                    label="Title"
                                    name={FormFieldEnum.landingPageTitle}
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: REQUIRED_FIELD }]}
                                >
                                    <Input.TextArea
                                        disabled={disableEdit}
                                        rows={5}
                                        style={{ width: '100%' }}
                                        maxLength={600}
                                        showCount
                                    />
                                </Form.Item>
                            </div>
                            {
                                show && (
                                    <div className={s.row}>
                                        <Form.Item
                                            className={s.rowItem}
                                            label="Content"
                                            name={FormFieldEnum.landingPageContent}
                                            style={{ width: '100%' }}
                                            rules={[{ required: !item, message: REQUIRED_FIELD }]}
                                        >
                                            <HtmlContentInput
                                                disabled={disableEdit}
                                                header={null}
                                                footer={null}
                                                maxLength={600}
                                                height="300px"
                                            />
                                        </Form.Item>
                                    </div>
                                )
                            }
                        </>

                    </Form>
                </div>
            </Modal>
            {contextHolder}
        </>
    );
};

export default ReferralUpdateModal;
