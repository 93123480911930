// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__title--GGjwq {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--gray-900, #111827);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 24px;
}
.s-module__right--CwkfX {
  flex-shrink: 0;
}
.s-module__left--hZb2T {
  flex-grow: 1;
}
.s-module__content--Q_Jdr {
  color: var(--gray-900, #111827);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.s-module__item--ZBLo4 {
  margin-bottom: 12px;
}
.s-module__checkWrap--trc3_ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.s-module__select--eYtRk {
  width: 100%;
  margin-top: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PorivderMappingModals/SFUUpdateModal/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACJ;AAEA;EACI,cAAA;AAAJ;AAGA;EACI,YAAA;AADJ;AAIA;EACI,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAFJ;AAKA;EACI,mBAAA;AAHJ;AAOA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AALJ;AAQA;EACI,WAAA;EACA,gBAAA;AANJ","sourcesContent":[".title {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    color: var(--gray-900, #111827);\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 20px;\n    margin-bottom: 24px;\n}\n\n.right {\n    flex-shrink: 0;\n}\n\n.left {\n    flex-grow: 1;\n}\n\n.content {\n    color: var(--gray-900, #111827);\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 20px;\n}\n\n.item {\n    margin-bottom: 12px;\n\n}\n\n.checkWrap {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.select {\n    width: 100%;\n    margin-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `s-module__title--GGjwq`,
	"right": `s-module__right--CwkfX`,
	"left": `s-module__left--hZb2T`,
	"content": `s-module__content--Q_Jdr`,
	"item": `s-module__item--ZBLo4`,
	"checkWrap": `s-module__checkWrap--trc3_`,
	"select": `s-module__select--eYtRk`
};
export default ___CSS_LOADER_EXPORT___;
