import { useEffect, useState } from 'react';
import { getKlaritySpecialtySFU } from 'api/operation';
import { TSFUConfig } from 'types/common'

const useGetSFUSetting = (speId?: number): [TSFUConfig[], boolean, () => void] => {
    const [data, setData] = useState<TSFUConfig[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | undefined>('');

    const fetch = async () => {
        if (!speId) {
            return;
        }
        setLoading(true);

        try {
            const res = await getKlaritySpecialtySFU(speId);
            if (res && !res.error) {
                setData(res.data.data);
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (speId) {
            fetch();
        }
    }, [speId]);

    return [data, loading, fetch];
};

export default useGetSFUSetting;
