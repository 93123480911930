import React from 'react';
import s from './s.module.less';
import type { TAssessmentItem } from 'types/operation';
import cx from 'classnames';

type IProps = {
    isMobileMode: boolean;
    previewItem: TAssessmentItem;
};

const PreviewIntro: React.FC<IProps> = (props) => {
    const { isMobileMode, previewItem } = props;
    console.log('previewItem: ', previewItem);
    return (
        <div className={isMobileMode ? s.previewMobile : s.previewPc}>
            <div className={s.content}>
                <div className={s.decoration}>
                    <i />
                    <i />
                    <i />
                    <i />
                </div>
                <div className={cx(s.contentInner, !isMobileMode && s.contentInnerPc)}>
                    <div className={cx(s.contentInnerWrap, !isMobileMode && s.contentInnerWrapPc)}>
                        {previewItem.imgUrl && isMobileMode && (
                            <img
                                src={previewItem.imgUrl}
                                className={cx(s.img, isMobileMode && s.imgMobile)}
                                alt={previewItem.title}
                            />
                        )}
                        <h3 className={s.title}>{previewItem.title || '-'}</h3>
                        {previewItem.description && (
                            <p className={s.description}>{previewItem.description}</p>
                        )}

                        <div className={s.info}>
                            <p className={s.finishMinute}>
                                About {previewItem.finishMinute || '-'} mins
                            </p>
                            <p className={s.questionNumber}>
                                {previewItem.questionNumber || '-'} questions
                            </p>
                            <p className={s.participantsNumber}>
                                Over {previewItem.participantsNumber || '-'} participants
                            </p>
                        </div>
                        <div className={s.cta}>Start now</div>
                        <p className={s.tips}>
                            * This is not a substitute for a professional diagnosis.
                        </p>
                    </div>
                    {previewItem.imgUrl && !isMobileMode && (
                        <img
                            src={previewItem.imgUrl}
                            className={cx(s.img, !isMobileMode && s.imgPc)}
                            alt={previewItem.title}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PreviewIntro;
