import React from 'react';
import { getPayPlanList } from 'api/subscription';
import { SUBSCRIPTION_PLANS } from 'constants/subscription';
import { SUBSCRIPTION_PLANS as KLARITY_SUBSCRIPTION_PLANS } from 'constants/klarityPlanSubscription';
import { TSubscriptionItem as TKlaritySubscriptionItem, TSubscriptionItem, TSubscriptionItemFromServer, TSubscriptionSubType } from 'types/subscription';
import SubscriptionStore from 'store/Subscription';

type TPlans = {
    kiwiPlan: TSubscriptionItem[];
    klarityPlan: TKlaritySubscriptionItem[];
};

const globalData: TSubscriptionItem[] = SUBSCRIPTION_PLANS;
const klarityGlobalData: TKlaritySubscriptionItem[] = KLARITY_SUBSCRIPTION_PLANS;
// let hasSynServerData = false;

const useSubscriptionPlan = (): [TPlans, boolean | null, () => void, TSubscriptionItemFromServer[]] => {
    const [loading, setLoading] = React.useState<boolean | null>(null);
    const [getSubscription] = SubscriptionStore.useStore();
    const [items, setItems] = React.useState<TPlans>({
        kiwiPlan: globalData,
        klarityPlan: klarityGlobalData,
    });
    const [originData, setOriginData] = React.useState<TSubscriptionItemFromServer[]>([]);

    const dataFac = (originalData: (TSubscriptionItem | TKlaritySubscriptionItem)[], respData: TSubscriptionItemFromServer[]) => {
        const synData = originalData.slice();
        for (let i = 0; i < synData.length; i++) {
            const current = synData[i];
            //target is get diff price
            const monthlyItem = respData.find((item) => item.type === current.type && item.subType === TSubscriptionSubType.MONTHLY_BILLING);
            if (monthlyItem) {
                current.monthlyPrice = monthlyItem.price;
                current.monthlyPlanId = monthlyItem.id;
                current.monthlyProductCode = monthlyItem.productCode;
                current.monthlyPriceId = monthlyItem.priceId;
                if (monthlyItem?.description) {
                    current.des = monthlyItem?.description;
                }
            }

            const quarterlyItem = respData.find((item) => item.type === current.type && item.subType === TSubscriptionSubType.QUARTERLY_BILLING);
            if (quarterlyItem) {
                current.quarterlyPrice = quarterlyItem.price;
                current.quarterlyPlanId = quarterlyItem.id;
                current.quarterlyProductCode = quarterlyItem.productCode;
                current.quarterlyPriceId = quarterlyItem.priceId;
            }

            const semiAnnualItem = respData.find((item) => item.type === current.type && item.subType === TSubscriptionSubType.SEMI_ANNUAL_BILLING);
            if (semiAnnualItem) {
                current.semiAnnualPrice = semiAnnualItem.price;
                current.semiAnnualPlanId = semiAnnualItem.id;
                current.semiAnnualProductCode = semiAnnualItem.productCode;
                current.semiAnnualPriceId = semiAnnualItem.priceId;
            }

            const yearlyItem = respData.find((item) => item.type === current.type && item.subType === TSubscriptionSubType.YEARLY_BILLING);
            if (yearlyItem) {
                current.yearlyPrice = yearlyItem.price;
                current.yearlyPlanId = yearlyItem.id;
                current.yearlyProductCode = yearlyItem.productCode;
                current.yearlyPriceId = yearlyItem.priceId;
            }

            // 限制目前每种付费周期上都有，目前所有周期的限制都一样，如果后续周期不一样的话，这里需要改动。
            current.freeChannelLimit = monthlyItem?.freeChannelLimit || null;
            current.paidChannelLimit = monthlyItem?.paidChannelLimit || null;
            current.premiumChannelLimit = monthlyItem?.premiumChannelLimit || null;
        }

        return synData;
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await getPayPlanList();
            if (!result.error) {
                // hasSynServerData = true;
                setOriginData(result?.data?.data || []);
                const data: TSubscriptionItemFromServer[] = result?.data?.data || [];
                const kiwiPlanSynData = dataFac(globalData, data);
                const klarityPlanSynData = dataFac(klarityGlobalData, data);

                setItems({
                    kiwiPlan: kiwiPlanSynData as TSubscriptionItem[],
                    klarityPlan: klarityPlanSynData as TKlaritySubscriptionItem[],
                });
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!loading) {
            fetchData();
        }
    }, []);

    const currentPlan = getSubscription('currentPlan');

    if (currentPlan) {
        Object.values(items).flat().forEach((item) => {
            if (item.type === currentPlan.type) {
                item.isCurrent = true;
            }
        });
    }
    return [items, loading, fetchData, originData];
};

export default useSubscriptionPlan;
