import React, { useCallback, useEffect, useMemo } from 'react';
import s from './s.module.less';
import { Button, message, Spin } from 'antd';
import { shareToDoximity, shareToFacebook, shareToLinkedin, shareToTwitter, shareToEmail } from 'utils/share';
import { generateProviderReferralLink } from 'api/referral';
import { getUserId, getReferralLinkMap, setReferralLinkMap } from 'utils/localstore';
import copy from 'copy-to-clipboard';
import { IRefferalInfo } from 'types/referral';
import dayjs from 'utils/dayjs';
import useSubscriptionPlan from 'hooks/useSubscriptionPlan';

interface IProps {
    referralInfo?: IRefferalInfo;
}

const InviteBanner = ({
    referralInfo,
}: IProps) => {
    const [, loadingSubscriptionPlan,,originPlansData] = useSubscriptionPlan();

    const [generateLoading, setGenerateLoading] = React.useState<boolean>(false);
    const [providerReferralLink, setProviderReferralLink] = React.useState<string>();
    const emailMsgContent = referralInfo?.emailMsgContent || '';
    // const landingPageTitle = referralInfo?.landingPageTitle || '';
    const startDateTime = referralInfo?.startDateTime || '';
    const endDateTime = referralInfo?.endDateTime || '';
    const [shareUrl, setShareUrl] = React.useState<string>('');
    const [showReferalLinl, setShowReferalLinl] = React.useState<boolean>(false);

    const discountValue = useMemo(() => {
        if (referralInfo?.referralReward.referrerRewardDiscountType === 'PERCENT_OFF') {
            return `${referralInfo.referralReward.referrerRewardDiscountValue}%`;
        } else if (referralInfo?.referralReward.referrerRewardDiscountType === 'AMOUNT_OFF') {
            return `$${referralInfo.referralReward.referrerRewardDiscountValue}`;
        }

        return '$0';
    }, [referralInfo]);

    const shareTitle = 'Sharing a great opportunity for providers to attract more patients and grow their practice – Kiwi Health! It helps boost visibility, manage reputation, and streamline patient communications. Use my referral link to sign up and get a discounted price.';
    // const shareTitle = 'Sharing a great opportunity for providers to attract more patients and grow their practice – Kiwi Health! It helps boost visibility, manage reputation, and streamline patient communications. Use my referral link to sign up and get a discounted price.';

    useEffect(() => {
        if (providerReferralLink) {
            setShareUrl(providerReferralLink);
        }
    }, [providerReferralLink]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const providerId = getUserId();
            const referralLinkMapString = getReferralLinkMap();

            if (referralLinkMapString && providerId) {
                try {
                    const referralMap = JSON.parse(referralLinkMapString);
                    const link = referralMap[providerId];
                    setShowReferalLinl(!!link);
                    // setProviderReferralLink(link);
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }, []);

    const setReferralLinkToLocalStorage = useCallback((referralLink: string) => {
        if (typeof window !== 'undefined') {
            const providerId = getUserId();
            if (providerId) {
                const referralLinkMapString = getReferralLinkMap();
                let referralMap = {};

                if (referralLinkMapString) {
                    try {
                        referralMap = JSON.parse(referralLinkMapString);
                    } catch (e) {
                        console.error(e);
                    }
                }

                referralMap = {
                    ...referralMap,
                    [providerId]: referralLink,
                };
                setReferralLinkMap(referralMap);
            }
        }
    }, []);

    const handleCopyButtonClick = useCallback(() => {
        if (providerReferralLink) {
            copy(providerReferralLink, {
                format: 'text/plain',
            });
            message.success('Copied to the clipboard');
        }
    }, [providerReferralLink]);

    const handleReferralMessageCopy = useCallback(() => {
        if (emailMsgContent) {
            copy(emailMsgContent, {
                format: 'text/plain',
            });
            message.success('Copied to the clipboard');
        }
    }, [emailMsgContent]);

    const handleGenerateReferralLink = useCallback(async () => {
        setGenerateLoading(true);

        try {
            const res = await generateProviderReferralLink();

            if (res && !res.error) {
                const link = res.data.data;
                if (link) {
                    setProviderReferralLink(link);
                }
            } else {
                message.error(res.error);
            }
        } catch (error) {
            console.log('error', error);
        }
        setGenerateLoading(false);
    }, []);

    useEffect(() => {
        handleGenerateReferralLink();
    }, [handleGenerateReferralLink]);

    const handleGenerateButtonClick = useCallback(() => {
        setShowReferalLinl(true);
        setReferralLinkToLocalStorage('true');
    }, []);

    const handleEmailClick = useCallback(() => {
        const subject = 'Check out Kiwi Health – A game changer for growing your practice!'; // 邮件主题
        const emailBody = `Hey there,\nI wanted to share something that’s been a total game changer for me – Kiwi Health, a fantastic tool that’s helping me grow my practice.\nKiwi Health helps boost online visibility, manage reputation, and streamline communications with patients. It has been so easy to use and has really made a difference in attracting more patients and managing my practice more efficiently.\nI thought you might find it useful too, so I’m sharing my referral link with you. If you sign up through it, you’ll get a discount on your plan!\nHere’s the link: ${shareUrl}\nLet me know if you have any questions or want to chat more about it!`;
        shareToEmail(subject, emailBody);
    }, [shareUrl]);

    const contentRender = useMemo(() => {
        if (!showReferalLinl) {
            return (
                <div className={s.getReferLinkButtonBox}>
                    <Button type="primary" loading={generateLoading} className={s.getReferLinkButton} onClick={handleGenerateButtonClick}>Get your referral link</Button>
                    <div className={s.termsText}>By getting the code you’re agreeing to <a className={s.link} href="https://www.kiwihealth.com/terms-conditions" target="_blank" rel="noreferrer">Kiwi Health’s Terms and Conditions</a>.</div>
                </div>
            );
        }

        return (
            <div className={s.shareBox}>
                <div className={s.shareTitle}>Share your unique referral link</div>
                <div className={s.shareLinkBox}>
                    <div className={s.shareText}>{providerReferralLink}</div>
                    <Button onClick={handleCopyButtonClick}>Copy</Button>
                </div>
                <div className={s.ways}>
                    <div className={s.waysTitle}>Or share your referral link via</div>
                    <div className={s.shareButtons}>
                        <div className={`${s.shareBtn} ${s.email}`} onClick={() => handleEmailClick()} />
                        <div className={`${s.shareBtn} ${s.facebook}`} onClick={() => shareToFacebook(shareUrl)} />
                        <div className={`${s.shareBtn} ${s.linkedin}`} onClick={() => shareToLinkedin(shareUrl, shareTitle)} />
                        <div className={`${s.shareBtn} ${s.twitter}`} onClick={() => shareToTwitter(shareUrl, shareTitle)} />
                        <div className={`${s.shareBtn} ${s.doximity}`} onClick={() => shareToDoximity(shareUrl, shareTitle)} />
                    </div>
                </div>
                <div className={s.copyTemplate}>
                    <div className={s.templateTitle}>Copy an example referral message</div>
                    <div className={s.templateContentBox}>
                        <div className={s.templateContent} dangerouslySetInnerHTML={{ __html: emailMsgContent }} />
                        <Button onClick={handleReferralMessageCopy}>Copy</Button>
                    </div>
                </div>
            </div>
        );
    }, [providerReferralLink, handleCopyButtonClick, emailMsgContent, handleReferralMessageCopy, generateLoading, handleEmailClick, shareUrl, shareTitle, showReferalLinl, handleGenerateButtonClick]);

    const planTypesText = useMemo(() => {
        const order = ['Free', 'Essential', 'Growth', 'Group Practice'];
        if (!referralInfo?.referralReward?.referrerRewardApplyAllProducts && referralInfo?.referralReward?.referrerRewardPrice) {
            const priceIds = referralInfo?.referralReward?.referrerRewardPrice.split(',');

            const plans = Array.from(new Set(originPlansData.filter((item) => priceIds.includes(item.priceId)).map((item) => item.type))).sort((a, b) => {
                return order.indexOf(a) - order.indexOf(b);
            });

            if (plans.length === 1) {
                return `${plans[0]} plan`;
            }
            if (plans.length > 2) {
                const allButLast = plans.slice(0, -1).join(' plan, ');
                return `${allButLast} pan, or ${plans[plans.length - 1]} plan`;
            }

            return `the ${plans.join(' plan or ')} plan`;
        }

        return 'any plan';
    }, [originPlansData, referralInfo]);

    return (
        <div className={s.wrap}>
            <Spin spinning={!!loadingSubscriptionPlan}>
                <div className={s.title}>Refer a provider and earn rewards</div>
                <div className={s.desc}>{`From ${dayjs(startDateTime).format('MM/DD/YYYY')} to ${dayjs(endDateTime).format('MM/DD/YYYY')}, refer a provider to Kiwi Health, and both of you will save ${discountValue} on your next payment! The referee needs to sign up and upgrade to ${planTypesText}.`}</div>
                {
                    contentRender
                }
            </Spin>
        </div>
    );
};

export default InviteBanner;
