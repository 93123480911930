import React, { useEffect } from 'react';
import s from './s.module.less';
import { Button, message, Modal, Spin, Upload } from 'antd';
import { uploadFile } from 'api/common';
import type { UploadProps } from 'antd/es/upload/interface';

interface IProps {
    title?: string;
    descTitle?: string;
    descContent?: string;
    value?: string;
    maxSize?: number;
    onChange?: (value: string) => void;
}

const BannerUploader = ({
    title,
    descTitle,
    descContent,
    value,
    maxSize = 10,
    onChange,
}: IProps) => {
    const [val, setVal] = React.useState<string>('');
    const [penddingVal, setPenddingVal] = React.useState<string>('');
    const [uploadloading, setUploadLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (value) {
            setVal(value);
        }
    }, [value]);

    const props: UploadProps = {
        name: 'file',
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        headers: {
            authorization: 'authorization-text',
        },
        showUploadList: false,
        multiple: false,
        accept: '.png,.jpg,.jpeg',
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                const isLtSize = file.size / 1024 / 1024 <= maxSize;
                if (!isLtSize) {
                    message.error(`File must smaller than ${maxSize}MB!`);
                    reject(false);
                } else {
                    resolve(true);
                }
            });
        },
        customRequest: async (data) => {
            setUploadLoading(true);
            try {
                const formData = new FormData();
                formData.append('file', data.file);
                formData.append('uploadType', 'REVIEW_DISPUTE');
                const res = await uploadFile(formData);
                if (!res.error) {
                    const imageUrl = res.data?.data;
                    setPenddingVal(imageUrl);
                    // onChange?.(imageUrl);
                    // const documentId = res.data?.data;
                    // onUploadSuccess(res.data?.data, data.file.name);
                    // setDocList([...docList, res.data?.data]);
                    // setUploadedFile(data.file as RcFile);
                    // setAvaDocumentId(documentId);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setUploadLoading(false);
            }
        },
    };
    return (
        <div className={s.wrap}>
            <Modal
                width={610}
                open={open}
                destroyOnClose
                title={title ?? 'Banner image'}
                okText="Upload"
                afterClose={() => {
                    setPenddingVal('');
                }}
                onOk={() => {
                    if (penddingVal) {
                        setOpen(false);
                        setVal(penddingVal);
                        onChange?.(penddingVal);
                    } else {
                        message.error('Please upload image');
                    }
                }}
                onCancel={() => {
                    setOpen(false);
                    setVal('');
                    setPenddingVal('');
                }}
            >
                <div className={s.wrap}>
                    {penddingVal ? (
                        <img src={penddingVal} alt="banner" />
                    ) : (
                        <Spin spinning={uploadloading}>
                            <div className={s.upload}>
                                <Upload.Dragger {...props}>
                                    <div className={s.uploadBox}>
                                        <div className={s.uploadIcon} />
                                        <p>
                                            <span>Choose a file </span>or drag and drop image
                                        </p>
                                        <div className={s.desc}>PNG or JPG up to {maxSize}MB</div>
                                    </div>
                                </Upload.Dragger>
                            </div>
                        </Spin>
                    )}
                    <div className={s.tip}>
                        {descTitle && <div className={s.title}>{descTitle}</div>}
                        {descContent && (
                            <div
                                className={s.desc}
                                dangerouslySetInnerHTML={{ __html: descContent }}
                            />
                        )}
                    </div>
                </div>
            </Modal>
            {val && <img className={s.banner} src={val} />}

            <Button onClick={() => setOpen(true)}>Upload</Button>
        </div>
    );
};

export default BannerUploader;
