import React from 'react';
import type { UploadChangeParam } from 'antd/es/upload';
import { message, Spin, Upload, Button } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { uploadFilePrivate } from 'api/common';
import UploadIcon from './upload.svg';
import PdfIcon from './pdf.png';
import s from './s.module.less';
import { getUserId } from 'utils/localstore';
import { getPrivateFilePreview } from 'api/ehr';
import { getPrivateFilePreview as getPrivateFilePreviewOperation } from 'api/operation';

const { Dragger } = Upload;

type FileUploadProps = {
    isOps?: boolean;
    value?: string;
    tips?: string;
    accept?: string;
    onChange?: (val: string) => void;
    onChangeFullUrl?: (fullUrl: string) => void;
};

const FileUpload = (Props:FileUploadProps) => {
    const { isOps, accept, value, tips, onChange, onChangeFullUrl } = Props;
    const [loading, setLoading] = React.useState(false);
    const [imageUrl, setImageUrl] = React.useState<string>(UploadIcon);

    const fileInfoRef = React.useRef<UploadChangeParam<UploadFile>>();
    const [showUploadList, setShowUploadList] = React.useState(false);
    const [previewUrl, setPreviewUrl] = React.useState<string | undefined>(undefined);

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        fileInfoRef.current = info;
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
    };

    const getPreview = async (v?: string) => {
        if (v) {
            try {
                const fn = isOps ? getPrivateFilePreviewOperation : getPrivateFilePreview;
                const res = await fn(v);
                setPreviewUrl(res.data?.data?.singedUrl);
                return res.data?.data?.singedUrl;
            } catch (e) {
                console.error(e);
            }
        }
    };

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        onChange: handleChange,
        accept: accept || '*',
        showUploadList,
        onDrop() {
            //console.log('Dropped files', e.dataTransfer.files);
        },
        customRequest: async (data) => {
            //https://github.com/react-component/upload#customrequest
            try {
                const formData = new FormData();
                formData.append('file', data.file);
                console.log('formData: ', formData);
                const res = await uploadFilePrivate({
                    file: data.file,
                    uploadType: 'IMAGE',
                    providerId: getUserId(),
                });
                if (!res.error) {
                    if (fileInfoRef.current) {
                        fileInfoRef.current.file.status = 'done';
                    }
                    setShowUploadList(true);
                    const url = res.data?.data;
                    const isPdf = fileInfoRef.current?.file?.name?.toLowerCase().endsWith('pdf');
                    if (isPdf) {
                        setImageUrl(PdfIcon);
                    } else {
                        getBase64(data.file as RcFile, (base64Url) => {
                            setImageUrl(base64Url);
                        });
                    }
                    onChange?.(url);
                    const fullUrl = await getPreview(url);
                    onChangeFullUrl?.(fullUrl);
                }
            } catch (e) {
                setShowUploadList(false);
                console.error(e);
            } finally {
                setLoading(false);
            }
        },
    };

    React.useEffect(() => {
        if (value) {
            getPreview(value);
        }
    }, [value]);

    return (
        <div className={s.wrap}>
            <Spin spinning={loading}>
                {!value && (
                    <Dragger {...props}>
                        <p className={s.iconWrap}>
                            <img src={UploadIcon} className={s.icon} />
                        </p>
                        <p className={s.uploadText}>
                            <span>Upload a file</span> or drag and drop
                        </p>
                        <p className={s.uploadHint}>
                            {tips ?? 'PNG, JPG, PDF up to 10MB'}
                        </p>
                    </Dragger>
                )}
            </Spin>
            {previewUrl && (
                <div className={s.preview}>
                    <div className={s.previewImgWrap}>
                        <img src={previewUrl} alt="preview" className={s.previewImg} />
                    </div>
                    <Upload {...props} showUploadList={false}>
                        <Button className={s.changeBtn}>Change</Button>
                    </Upload>
                </div>
            )}
        </div>
    );
};

export default FileUpload;
