import React from 'react';
import s from './s.module.less';
import CampaignStore from 'store/Campaign';
import CampaignConfig from 'data/CampaignConfig';
import dayjs from 'utils/dayjs';

const TermsNConditions = () => {
    const [getSelectedCampaign] = CampaignStore.useStore();
    const selectedCampaign = getSelectedCampaign('selectedCampaign');
    const providerStartDateTime = selectedCampaign ? selectedCampaign.providerStartDateTime : '';
    const providerEndDateTime = selectedCampaign ? selectedCampaign.providerEndDateTime : '';
    const html = `
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Effective Date:</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"> November 21, 2024</span>
            </p>
            <p>
                <br/>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">1. Introduction</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">These terms and conditions govern the participation of healthcare providers (“Providers”) in Klarity’s Holiday Season Sale promotion (“Promotion”). By opting into the Promotion, Providers agree to comply with these terms and conditions.</span>
            </p>
            <p>
                <br/>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">2. Eligibility</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Eligible Participants:</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"> All Providers listed on the Klarity platform are eligible unless expressly excluded in writing by Klarity.</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Geographic Scope:</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"> Providers in all states are eligible; no states are excluded from participation.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">3. Campaign Details</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">3.1 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Promotion Period</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">The Promotion runs from </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">${dayjs(providerStartDateTime).format('MMMM DD, YYYY')}, to ${dayjs(providerEndDateTime).format('MMMM DD, YYYY')}</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">3.2 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Discount Details</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Providers agree to offer </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">discounts on their first visit</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"> for new patients.</span>
            </p>
            <ul style="padding-inline-start: 48px;" class=" list-paddingleft-2">
                <li>
                    <p style="line-height:1.38;margin-top:9pt;margin-bottom:0pt;">
                        <span style="font-size: 10.5pt; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; text-wrap-mode: wrap;">The providers select the discount amount.&nbsp;</span>
                    </p>
                </li>
            </ul>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">The discount applies exclusively to first visits with new patients who book during the Promotion Period.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">3.3 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Future Appointment Scheduling</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Patients may schedule appointments for dates outside the Promotion Period as long as the booking occurs during the Promotion Period.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">4. Provider Obligations</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">4.1 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Participation</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Providers opting into the Promotion will receive enhanced visibility on the platform, including additional badges on their profiles, enhancement in provider listing, and inclusion in marketing materials.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">4.2 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Shared-Savings Contribution</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Providers agree to honor the discounted pricing during the Promotion and understand the discount is applied directly to their payout to the appointments gained during the Promotiono period.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">4.3 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Service Quality</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Providers are required to maintain their usual high standards of care for all patients, including those utilizing the Promotion.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">4.4 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Compliance</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Providers must ensure operational readiness, including appointment availability, during the Promotion Period.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">5. Klarity’s Responsibilities</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">5.1 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Marketing and Promotion</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Klarity will manage all promotional activities, including email campaigns, social media outreach, and other advertising efforts.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">5.2 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Platform Management</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Klarity will clearly mark participating Providers on the platform and ensure promotional pricing is displayed transparently.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">5.3 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Payment Processing</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Klarity will handle payment collection from patients and disburse payments to Providers in accordance with Klarity’s standard payout schedule.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">6. Patient Engagement</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">6.1 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Discount Eligibility</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">The discount is valid for </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">first visits only</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">. Follow-up visits or additional services are not included in the Promotion.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">6.2 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Rescheduling</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Patients who reschedule appointments booked during the Promotion Period will retain the discount as long as the original booking occurred within the specified dates.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">6.3 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Cancellations</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">If a patient cancels their appointment, the discount becomes void. To reapply the discount, the patient must rebook during the Promotion Period.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">7. Limitations</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">7.1 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Booking Limits</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">There is no limit to the number of discounted first visits a Provider can accept during the Promotion.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">7.2 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Promotion Scope</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">The Promotion applies only to the services and visits specified by Klarity. Providers are not permitted to extend the discount to other services or appointments.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">8. Opt-Out and Termination</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">8.1 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Provider Opt-Out</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Providers may opt out of the Promotion or pause their Promotion directly in the Provider WebApp.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">8.2 </span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Termination by Klarity</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Klarity reserves the right to remove a Provider from the Promotion at any time for non-compliance with these terms.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">9. Modification and Termination</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Klarity reserves the right to modify, suspend, or terminate the Promotion at its discretion with prior notice to participating Providers.</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">10. Disputes</span>
            </p>
            <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Any disputes arising from participation in the Promotion will be resolved in accordance with Klarity’s standard dispute resolution procedures.</span>
            </p>
            <p>
                <br/>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">11. Contact Information</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">For questions or concerns regarding the Promotion, please contact:</span>
            </p>
            <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;">Email:</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"> providersupport@helloklarity.com</span>
            </p>
            <p>
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; vertical-align: baseline; white-space: pre-wrap;"><br/></span>
            </p>
            <p>
                <br/>
            </p>
        `;
    return (
        <div className={s.wrap}>
            <div className={s.title}>Terms & Conditions</div>
            <div className={s.content} dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    );
};

export default TermsNConditions;
