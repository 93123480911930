import { getTelehealthVisitAndPrescriptionRefill } from 'api/campaign';
import { useCallback, useEffect, useState } from 'react';
import { ICampaign } from 'types/campaign';

const useGetTelehealthVisitAndPrescriptionRefill = ():[ICampaign | undefined, boolean, string, () => void] => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ICampaign>();
    const [error, setError] = useState('');
    const [fetched, setFetched] = useState(false);

    const fetch = useCallback(async () => {
        setLoading(true);

        try {
            const res = await getTelehealthVisitAndPrescriptionRefill();

            if (res && !res.error) {
                const refillData = res.data?.data;
                const mockCampaign: ICampaign = {
                    campaignName: '📣 Join the LA wildfire telehealth program today!',
                    description: 'Provide critical care to patients in Los Angeles affected by wildfires. Join now to offer your services and help more people.',
                    providerEndDateTime: '',
                    providerStartDateTime: '',
                    joined: !!(refillData?.joined),
                    joinedCount: refillData?.joinedCount || 108,
                    campaignCode: 'la-wildfire-2025',
                    canJoin: !!(refillData?.canJoin),
                    campaignId: -1,
                    discountType: '',
                    patientStartDateTime: '',
                    patientEndDateTime: '',
                };
                setData(mockCampaign);
            } else {
                setError(res?.error);
            }
        } catch (e) {
            console.error(e);
        }

        setFetched(true);
        setLoading(false);
    }, []);

    useEffect(() => {
        if (!fetched) {
            fetch();
        }
    }, [fetched]);

    return [data, loading, error, fetch];
};

export default useGetTelehealthVisitAndPrescriptionRefill;
