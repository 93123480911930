import React from 'react';
import { PATH } from 'constants/path';
import Home from 'pages/Home';
import Template from 'pages/TemplatePage';
import SignUpPage from 'pages/SignUpPage';
import ProviderOnboardingPage from 'pages/ProviderOnboardingPage';
import MobileNotSupportPage from 'pages/MobileNotSupportPage';
import SavedDraftPage from 'pages/SavedDraftPage';
import RedirectPage from 'pages/RedirectPage';
import QuestionsPage from 'pages/QuestionsPage';

import AppointmentGooglePage from 'pages/dashboard/AppointmentManagePage/indexGoogle';
import CreateUniProfilePage from 'pages/CreateUniProfilePage';
import ChannelAppointmentSuccess from 'pages/ChannelAppointmentSuccess';
import LoginPage from 'pages/LoginPage';
import ChannelDetailListSuccessPage from 'pages/ChannelDetailListSuccessPage';

/* dashboard */
import AppointmentPage from 'pages/dashboard/AppointmentPage';
import AppointmentManagePage from 'pages/dashboard/AppointmentManagePage';
import AppointmentSettingPage from 'pages/dashboard/AppointmentSettingPage';
import RecommendChannelsPage from 'pages/dashboard/RecommendChannels';
import ChannelDetailPage from 'pages/dashboard/ChannelDetail';
import ChannelLandingPage from 'pages/dashboard/ChannelLandingPage';
import ChannelMainPage from 'pages/dashboard/ChannelMainPage';
import ChannelSurveyPage from 'pages/dashboard/ChannelSurveyPage';
import ProspectsPage from 'pages/dashboard/ProspectsPage';
import PracticeFrontPage from 'pages/dashboard/PracticeFrontPage';
import CustomizePracticeFrontPage from 'pages/dashboard/CustomizePracticeFrontPage';
import ProspectsManagePage from 'pages/dashboard/ProspectsManagePage';
import ProspectsDetailPage from 'pages/dashboard/ProspectsDetailPage';
/* end dashboard */

/* operation */
import OperationLayout from 'pages/OperationLayout';
import OperationInnerLayout from 'pages/OperationLayout/inner';
import OpProviderPage from 'pages/operation/ProviderPage';
import ProviderOnboardDetailPage from 'pages/operation/ProviderOnboardDetailPage';
import ListToChannelDetailPage from 'pages/operation/ListToChannelDetailPage';
import ChannelItemAddAndEditPage from 'pages/operation/ChannelItemAddAndEditPage';
import OpChannelPage from 'pages/operation/ChannelPage';
// import ServicesTemplatePage from 'pages/operation/ServicesTemplatePage';
import ServicesTemplatePage from 'pages/operation/ServiceTemplateManagement';
import HomeConfigPage from 'pages/operation/HomeConfigPage';
import HelloKlarityPage from 'pages/operation/HelloKlarityPage';
import KiwiHealthPage from 'pages/operation/KiwiHealthPage';
import ChannelWithdrawDetailPage from 'pages/operation/ChannelWithdrawDetailPage';
/* end operation */

/* preview */
import ZocDocPage from 'pages/preview/ZocDocPage';
import GooglePage from 'pages/preview/GooglePage';
import YelpPage from 'pages/preview/YelpPage';

import Layout from 'pages/Layout';

import { RouteObject } from 'react-router-dom';
import PracticeFrontVotePage from 'pages/dashboard/PracticeFrontVotePage';
import PracticefrontCreateSuccessPage from 'pages/dashboard/PracticeFrontCreateSuccessPage';
import OpsMember from 'pages/AccessManagement/OpsMember';
import DevWhitelist from 'pages/AccessManagement/DevWhitelist';
import Roles from 'pages/AccessManagement/Roles';
import { PermissionModuleKeys } from 'constants/access';
import AccessDeniedPage from 'pages/AccessManagement/AccessDenied';
import { isSuperAdmin } from 'utils/access';
import { getUserToken } from 'utils/localstore';
import SomethingWentWrongPage from 'pages/SomethingWentWrongPage';
import UnderMaintenancePage from 'pages/UnderMaintenancePage';
import NotFoundPage from 'pages/NotFoundPage';
import ProviderLoginPage from 'pages/ProviderLoginPage';
import ProviderHomePage from 'pages/dashboard/ProviderHomePage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import VerificationFailPage from 'pages/VerificationFailPage';
import AiChatPage from 'pages/AiChatPage';
import InitUniProfilePage from 'pages/InitUniProfilePage';
import PaySuccessPage from 'pages/PaySuccessPage';
import ReputationManagementPage from 'pages/dashboard/ReputationManagementPage';
import { getElementWithTargetRole, isProductionEnv } from 'utils/common';
import ReputationConfigPage from 'pages/dashboard/ReputationConfigPage';
import ReputationDashboardPage from 'pages/dashboard/ReputationDashboradPage';
import ReputationConfigSuccessfullyPage from 'pages/dashboard/ReputationConfigSuccessfully';
import UniprofileResume from 'pages/UniprofileResume';

import AiConfigPage from 'pages/operation/AiConfigPage';
import Configurations from 'pages/operation/Configurations';
import HelloKlarityProviders from 'pages/operation/HelloKlarityProviders';
import CustomizeHelloKlarityPPP from 'pages/operation/CustomizeHelloKlarityPPP';
import HelloKlarityMappingGoLiveSuccess from 'pages/operation/HelloKlarityMappingGoLiveSuccess';
import HelloKlarityMappingPauseSuccess from 'pages/operation/HelloKlarityMappingPauseSuccess';
import HelloKlarityPageManagement from 'pages/operation/HelloKlarityPageManagement';
import HelloKlarityReviews from 'pages/operation/HelloKlarityReviews';
import HelloKlarityAssessment from 'pages/operation/HelloKlarityAssessment';
import KlarityReviewsDetailPage from 'pages/operation/HelloKlarityReviews/detail';
import KlarityAssessmentDetailPage from 'pages/operation/HelloKlarityAssessment/detail';
import HelloKlarityPageDetail from 'pages/operation/HelloKlarityPageManagement/detail';
// import HelloKlarityQA from 'pages/operation/HelloKlarityQA';
import ChannelNotFound from 'pages/dashboard/ChannelNotFound';
import SalesProviderExp from 'pages/SalesProviderExp';
import ContractLink from 'pages/operation/ContractLinkPage';
import EHROnboardingPage from 'pages/dashboard/EHROnboardingPage';
// import AppointmentTime from 'components/AppointmentTime';
import EHRCart from 'pages/dashboard/EHRCart';
import ProviderSearchPage from 'pages/operation/ProviderSearchPage';
import EhrAddOnLinkPage from 'pages/operation/EhrAddOnLinkPage';
import EhrProviderHomePage from 'pages/dashboard/EhrProviderHomePage';
import EhrProviderAddOnPage from 'pages/dashboard/EhrProviderAddOnPage';
import EHRAddonDetail from 'pages/operation/EHRAddonDetail';
import KlarityPlanProcess from 'pages/dashboard/KlarityProcessPages/KlarityPlanProcess';
import KiwiKnowledge from 'pages/dashboard/KlarityProcessPages/KiwiKnowledgePage';
import KlarityPlanSubscribePage from 'pages/dashboard/KlarityProcessPages/KlarityPlanSubscribePage';
import KlarityAgreementPage from 'pages/dashboard/KlarityProcessPages/KlarityAgreementPage';
import KlarityReviewsPage from 'pages/dashboard/KlarityReviewsPage';
import AvaSettingProspectPage from 'pages/dashboard/AvaSettingProspectPage';
import KlarityProgramDetailPage from 'pages/operation/KlarityProgramDetailPage';
import HelpCenter from 'pages/dashboard/HelpCenter';
import StripeCheckoutIdPage from 'pages/TEST_GetStripeCheckoutId';

// testing
import ReviewTest from 'pages/testing/Review';

const ROUTERS = (access: Record<PermissionModuleKeys, boolean> | null, roles: string[]): RouteObject[] => {
    return [
        {
            path: PATH.TEMPLATE,
            element: <Template />,
        },
        {
            path: PATH.SIGNUP,
            element: <SignUpPage />,
        },
        {
            path: PATH.ONBOARDING,
            element: <ProviderOnboardingPage />,
        },
        {
            path: PATH.MOBILE_NOT_SUPPORT,
            element: <MobileNotSupportPage />,
        },
        {
            path: PATH.SAVED_DRAFT,
            element: <SavedDraftPage />,
        },
        {
            path: PATH.REDIRECT,
            element: <RedirectPage />,
        },
        {
            path: PATH.QUESTIONS,
            element: <QuestionsPage />,
        },
        {
            path: PATH.CHANNEL_SURVEY,
            element: <ChannelSurveyPage />,
        },
        {
            path: PATH.CUSTOMIZE_PRACTICE_FRONT,
            element: <CustomizePracticeFrontPage />,
        },
        {
            path: PATH.PRACTICE_FRONT_VOTE_PAGE,
            element: <PracticeFrontVotePage />,
        },
        {
            path: PATH.UNIPROFILE_RESUME,
            element: <UniprofileResume />,
        },
        {
            path: PATH.EHR_ONBOARDING,
            element: <EHROnboardingPage />,
        },
        {
            path: PATH.DASHBOARD,
            element: <Layout />,
            children: [
                {
                    index: true,
                    element: <ProviderHomePage />,
                },
                {
                    path: PATH.PROVIDER_HOME,
                    element: <ProviderHomePage />,
                },
                {
                    path: PATH.EHR_HOME_PROVIDER,
                    element: <EhrProviderHomePage />,
                },
                {
                    path: PATH.EHR_ADD_ON_PROVIDER,
                    element: <EhrProviderAddOnPage />,
                },
                {
                    path: PATH.APPOINTMENT,
                    element: <AppointmentPage />,
                },
                {
                    path: PATH.APPOINTMENT_MANAGEMENT,
                    element: <AppointmentManagePage />,
                },
                {
                    path: PATH.APPOINTMENT_SETTING,
                    element: <AppointmentSettingPage />,
                },
                {
                    path: PATH.APPOINTMENT_GOOGLE,
                    element: <AppointmentGooglePage />,
                },
                {
                    path: PATH.CHANNEL_DETAIL,
                    element: <ChannelDetailPage />,
                },
                {
                    path: PATH.CHANNEL_LANDING,
                    element: <ChannelLandingPage />,
                },
                {
                    path: PATH.CHANNEL,
                    element: <ChannelMainPage />,
                },
                {
                    path: PATH.PROSPECTS,
                    element: <ProspectsPage />,
                },
                {
                    path: PATH.PRACTICE_FRONT,
                    element: <PracticeFrontPage />,
                },
                {
                    path: PATH.PROSPECTS_MANAGE,
                    element: <ProspectsManagePage />,
                },
                {
                    path: `${PATH.PROSPECTS_DETAIL}/:id`,
                    element: <ProspectsDetailPage />,
                },
                {
                    path: PATH.REPUTATION_MANAGEMENT,
                    element: <ReputationManagementPage />,
                },
                {
                    path: PATH.KLARITY_REVIEWS,
                    element: <KlarityReviewsPage />,
                },
                {
                    path: PATH.REPUTATION_DASHBORAD,
                    element: <ReputationDashboardPage />,
                },
                {
                    path: PATH.CHANNEL_NOT_FOUND,
                    element: <ChannelNotFound />,
                },
                {
                    path: PATH.EHR_CART,
                    element: <EHRCart />,
                },
                {
                    path: PATH.AVA_SETTING_PROSPECTS,
                    element: <AvaSettingProspectPage />,
                },
                {
                    path: PATH.HELP_CENTER,
                    element: <HelpCenter />,
                },
            ],
        },
        {
            path: PATH.OPERATION,
            element: <OperationLayout />,
            children: [
                {
                    index: true,
                    element: <OpProviderPage />,
                },
                {
                    path: PATH.PROVIDER_SEARCH,
                    element: <ProviderSearchPage />,
                },
                {
                    path: PATH.PROVIDER,
                    element: <OpProviderPage />,
                },
                {
                    path: PATH.CHANNEL,
                    element: <OpChannelPage />,
                },
                {
                    path: `${PATH.PROVIDER_ONBOARD_DETAIL}/:id`,
                    element: <ProviderOnboardDetailPage />,
                },
                {
                    path: `${PATH.LIST_TO_CHANNEL_DETAIL}/:id/:pid`,
                    element: <ListToChannelDetailPage />,
                },
                {
                    path: `${PATH.CHANNEL_WITHDRRAW_DETAIL}/:id/:channelId/:providerId`,
                    element: <ChannelWithdrawDetailPage />,
                },
                {
                    //this id means provider id
                    path: `${PATH.UNIPROFILE_DETAIL}/:id/:pid`,
                    element: <ListToChannelDetailPage />,
                },
                {
                    path: `${PATH.CHANNEL_UPDATE}/:id`,
                    element: <ChannelItemAddAndEditPage />,
                },
                {
                    path: `${PATH.CHANNEL_UPDATE}`,
                    element: <ChannelItemAddAndEditPage />,
                },
                {
                    path: `${PATH.SERVICES_TEMPLATE}`,
                    element: <ServicesTemplatePage />,
                    // element: getElementWithTargetRole({
                    //     access: access || undefined,
                    //     element: <ServicesTemplatePage />,
                    //     targetRoles: [PermissionModuleKeys.SERVICE_TEMPLATE],
                    // }),
                },
                {
                    path: PATH.OPS_MEMBER,
                    element: <OpsMember />,
                },
                {
                    path: PATH.DEV_WIHTELIST,
                    element: <DevWhitelist />,
                },
                {
                    path: PATH.HOME_CONFIG,
                    element: getElementWithTargetRole({
                        access: access || undefined,
                        element: <HomeConfigPage />,
                        targetRoles: [PermissionModuleKeys.HOME_CONFIG],
                    }),
                },
                {
                    path: PATH.AI_CONFIG,
                    element: <AiConfigPage />,
                },
                {
                    path: PATH.CONFIGURATIONS,
                    element: getElementWithTargetRole({
                        access: access || undefined,
                        element: <Configurations />,
                        targetRoles: [PermissionModuleKeys.CONFIGURATIONS],
                    }),
                },
                {
                    path: PATH.ROLES,
                    element: isSuperAdmin(roles) ? <Roles /> : <AccessDeniedPage />,
                },
                {
                    path: PATH.HELLO_KLARITY,
                    element: <HelloKlarityPage />,
                },
                {
                    path: PATH.KIWI_HEALTH,
                    element: getElementWithTargetRole({
                        access: access || undefined,
                        element: <KiwiHealthPage />,
                        targetRoles: [
                            PermissionModuleKeys.KIWI_HEALTH_PAGE,
                            PermissionModuleKeys.KIWI_HEALTH_PROVIDER,
                        ],
                    }),
                },
                {
                    path: PATH.HELLO_KLARITY_PROVIDERS,
                    element: <HelloKlarityProviders />,
                },
                {
                    path: PATH.HELLO_KLARITY_PAGE_MANAGEMENT,
                    element: <HelloKlarityPageManagement />,
                },
                {
                    path: PATH.KLARITY_REVIEWS,
                    element: <HelloKlarityReviews />,
                },
                {
                    path: `${PATH.KLARITY_REVIEWS_DETAIL}/:providerId/:providerSource`,
                    element: <KlarityReviewsDetailPage />,
                },
                {
                    path: PATH.KLARITY_ASSESSMENT,
                    element: <HelloKlarityAssessment />,
                },
                {
                    path: PATH.CONTRACT_LINK_PAGE,
                    element: <ContractLink />,
                },
                {
                    path: PATH.ACCESS_DENIED,
                    element: <AccessDeniedPage />,
                },
                {
                    path: PATH.EHR_ADD_ON_OPS,
                    element: <EhrAddOnLinkPage />,
                },
                {
                    path: `${PATH.EHR_ADD_ON_DETAIL}/:providerId`,
                    element: <EHRAddonDetail />,
                },
                {
                    path: `${PATH.KLARITY_SELECT_PROGRAMS_DETAIL}/:id`,
                    element: <KlarityProgramDetailPage />,
                },
            ],
        },
        {
            path: PATH.OPERATION,
            element: <OperationInnerLayout />,
            children: [
                {
                    path: `${PATH.HELLO_KLARITY_PAGE_DETAIL}/:type/:id`,
                    element: <HelloKlarityPageDetail />,
                },
            ],
        },
        {
            path: PATH.OPERATION,
            element: <OperationInnerLayout />,
            children: [
                {
                    path: `${PATH.KLARITY_ASSESSMENT_DETAIL}`,
                    element: <KlarityAssessmentDetailPage />,
                },
            ],
        },
        {
            path: PATH.PREVIEW,
            element: <Layout />,
            children: [
                {
                    path: `${PATH.ZOCDOC}/:id`,
                    element: <ZocDocPage />,
                },
                {
                    path: `${PATH.GOOGLE}/:id`,
                    element: <GooglePage />,
                },
                {
                    path: `${PATH.YELP}/:id`,
                    element: <YelpPage />,
                },
            ],
        },
        {
            path: PATH.CREATE_UNI_PROFILE,
            element: <CreateUniProfilePage />,
        },
        {
            path: PATH.LOGIN,
            element: <LoginPage />,
        },
        {
            path: PATH.HOME,
            element: <Home />,
        },
        {
            path: PATH.RECOMMEND_CHANNELS,
            element: <RecommendChannelsPage />,
        },
        {
            path: PATH.CHANNEL_APPOINTMENT_SUCCESS,
            element: <ChannelAppointmentSuccess />,
        },
        {
            path: PATH.PRACTICE_FRONT_CREATE_SUCCESS,
            element: <PracticefrontCreateSuccessPage />,
        },
        {
            path: PATH.CHANNEL_DETAIL_LIST_SUCCESS,
            element: <ChannelDetailListSuccessPage />,
        },
        {
            path: PATH.DEV_GET_TOKEN,
            element: (
                <div style={{ fontSize: 12, padding: 48, wordWrap: 'break-word' }}>
                    {getUserToken()}
                </div>
            ),
        },
        {
            path: PATH.AI_CHAT,
            element: <AiChatPage />,
        },
        {
            path: PATH.SOMETHING_WENT_WRONG,
            element: <SomethingWentWrongPage />,
        },
        {
            path: PATH.UNDER_MAINTENANCE,
            element: <UnderMaintenancePage />,
        },
        {
            path: PATH.PROVIDER_LOGIN,
            element: <ProviderLoginPage />,
        },
        {
            path: PATH.RESET_PASSWORD,
            element: <ResetPasswordPage />,
        },
        {
            path: PATH.VERIFICATION_FAIL,
            element: <VerificationFailPage />,
        },
        {
            path: PATH.INIT_UNIPROFILE,
            element: <InitUniProfilePage />,
        },
        {
            path: PATH.PAY_SUCCESS,
            element: <PaySuccessPage />,
        },
        {
            path: PATH.REPUTATION_CONFIG_PAGE,
            element: <ReputationConfigPage />,
        },
        {
            path: PATH.REPUTATION_CONFIG_SUCCESS,
            element: <ReputationConfigSuccessfullyPage />,
        },
        {
            path: PATH.CUSTOMIZE_PROVIDER_PROFILE_PAGE,
            element: <CustomizeHelloKlarityPPP />,
        },
        {
            path: PATH.HELLO_KLARITY_MAPPING_GOLIVE_SUCCESS,
            element: <HelloKlarityMappingGoLiveSuccess />,
        },
        {
            path: PATH.HELLO_KLARITY_MAPPING_PAUSE_SUCCESS,
            element: <HelloKlarityMappingPauseSuccess />,
        },
        {
            path: PATH.SALES_PROVIDER_EXP,
            element: <SalesProviderExp />,
        },
        {
            path: PATH.KLARITY_PLAN_PROCESS,
            element: <KlarityPlanProcess />,
        },
        {
            path: PATH.KIWI_KNOWLEDEG,
            element: <KiwiKnowledge />,
        },
        {
            path: PATH.KLARITY_SUBSCRIPTION,
            element: <KlarityPlanSubscribePage />,
        },
        {
            path: PATH.KLARITY_AGREEMENT,
            element: <KlarityAgreementPage />,
        },
        {
            path: PATH.DASHBOARD,
            element: <Layout />,
            children: [
                {
                    path: 'testing/review',
                    element: <ReviewTest />,
                },
                {
                    path: 'stripe-checkoutid',
                    element: isProductionEnv() ? <NotFoundPage /> : <StripeCheckoutIdPage />,
                },
            ],
        },

        {
            path: '*',
            element: <NotFoundPage />,
        },
    ];
};

// const ROUTERS: RouteObject[] =

export {
    ROUTERS,
};
