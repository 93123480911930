import React from 'react';
import s from './s.module.less';
import type { TEngagementQuestion, TPreviewQuestion } from 'types/operation';

type IProps = {
    isMobileMode: boolean;
    previewItem: TPreviewQuestion;
};

const PreviewQuestion: React.FC<IProps> = (props) => {
    const { isMobileMode, previewItem } = props;
    console.log('previewItem: ', previewItem);
    return (
        <div className={isMobileMode ? s.previewMobile : s.previewPc}>
            {previewItem.type === 'questionFrom' &&
          previewItem.questionsPreviewLink && (
          <iframe
                    src={previewItem.questionsPreviewLink}
                    title="google form"
                    width="100%"
                    height="440px"
                />
            )}
            {previewItem.type === 'engagementQuestion' && (
                <div className={s.content}>
                    <div className={s.decoration}>
                        <i />
                        <i />
                        <i />
                        <i />
                    </div>
                    <div className={s.contentInner}>
                        {!!previewItem.engagementQuestionCount &&
                previewItem.engagementQuestionCount > 0 && (
                <div className={s.engagementQuestion}>
                                <div className={s.count}>
                        Question {previewItem.seq + 1} of {previewItem.count}
                    </div>

                                <p
                        className={s.title}
                        dangerouslySetInnerHTML={{
                                        __html: previewItem.title
                                            ? previewItem.title
                                                ?.replace(/<b>(.*?)<\/b>/g, '<b>$1</b>')
                                                .replace(
                                                    /\[color\](.*?)\[\/color\]/g,
                                                    '<b>$1</b>',
                                                )
                                            : '',
                                    }}
                    />
                                {previewItem.imgUrl && (
                    <div className={s.imgWrapper}>
                                        <img
                            src={previewItem.imgUrl}
                            alt={previewItem.title}
                            className={s.img}
                        />
                                    </div>
                                )}
                                <div className={s.cta}>Next</div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PreviewQuestion;
