import React, { useState } from 'react';
import s from './s.module.less';
import { Modal, message, Switch, Select } from 'antd';
import GlobalLayerStore from 'store/GlobalLayer';
import { EType } from 'types/helloKlarity';
import { IBPMappingItem } from 'types/operation';
import { updateHelloKlarityPatientRating, updateHelloKlaritySfuSetting } from 'api/operation';
import commonS from 'styles/common.module.less';
import useGetSpecialtyList from 'hooks/klarityServiceTemplateOps/useGetSpecialtyList';
import useGetSFUSetting from 'hooks/klarityServiceTemplateOps/useGetSFUSetting';
import { set } from 'lodash';
interface IProps {
    row?: IBPMappingItem | null;
    type: EType;
    onOk: () => void;
    onCancel: () => void;
}

type TConfigData = {
    specialtyId: number;
    specialtyName: string,
    sfuServiceId: number | null,
    enabled: boolean,
}

const SFUUpdateModal = ({
    row,
    onOk,
    onCancel,
}: IProps) => {
    const [getGlobalLayer] = GlobalLayerStore.useStore();
    const [value, setValue] = useState(2);
    const [loading, setLoading] = useState(false);
    const [specialities, specialtiesLoading] = useGetSpecialtyList();
    const [configData, loadingConfig, fetchConfig] = useGetSFUSetting(row?.bpProviderId);
    const [values, setValues] = useState<TConfigData[]>([]);

    React.useEffect(() => {
        if (configData) {
            const newValues: TConfigData[] = [];
            configData?.forEach((item) => {
                const serviceIsExist = item.followUpServiceList?.find((service) => service.serviceId === item.mappedServiceId);
                const onlyOneService = item.followUpServiceList && item.followUpServiceList.length === 1;
                let sfuServiceId = serviceIsExist ? (item.mappedServiceId || null) : null;
                if (!serviceIsExist && onlyOneService) {
                    sfuServiceId = item.followUpServiceList?.[0]?.serviceId || null;
                }
                newValues.push({
                    specialtyId: item.specialtyId,
                    specialtyName: item.specialtyName,
                    sfuServiceId: sfuServiceId,
                    enabled: item.enable,
                });
            })
            setValues(newValues);
        }
    }, [configData])

    const update = async () => {
        if (row && row.bpProviderId) {
            setLoading(true);
            try {
                const data: {
                    specialtyId: number;
                    specialtyName: string,
                    sfuServiceId: number | null,
                }[] = [];
                values?.forEach((value) => {
                    if (value.enabled) {
                        data.push({
                            specialtyId: value.specialtyId,
                            specialtyName: value.specialtyName,
                            sfuServiceId: value.sfuServiceId,
                        });
                    }
                });
                const res = await updateHelloKlaritySfuSetting(
                    row?.bpProviderId,
                    data
                );

                if (res && !res.error) {
                    message.success('Updated successfully');
                    fetchConfig();
                    onOk();
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }

            setLoading(false);
        }
    };

    const handleOk = () => {
        if (row) {
            update();
        }
    };

    return (
        <Modal
            title="Edit Smart follow-up Settings"
            width={550}
            open={getGlobalLayer('showSFUModal')}
            okText="Save"
            onOk={handleOk}
            onCancel={onCancel}
            confirmLoading={loading}
            className={commonS.modalFixHeightWrap}
        >
            <div className={s.wrap}>
                <div className={s.title}>
                    <div className={s.left}>
                        Specialty
                    </div>
                    <div className={s.right}>
                        SFU Status
                    </div>
                </div>
                <div className={s.content}>
                    {configData?.map((item) => {
                        const currentChecked = !!(values.find((value) => value.specialtyName === item.specialtyName)?.enabled);
                        return (
                            <div key={item.specialtyId} className={s.item}>
                                <div className={s.checkWrap}>
                                    <div className={s.left}>
                                        {item.specialtyName}
                                    </div>
                                    <div className={s.right}>
                                        <Switch
                                            checked={currentChecked}
                                            onChange={(checked) => {
                                                let sfuServiceId: number | null = null;
                                                const isExist = values.find((value) => value.specialtyName === item.specialtyName);
                                                if (isExist && isExist.sfuServiceId) {
                                                    sfuServiceId = isExist.sfuServiceId;
                                                } else if (item.followUpServiceList && item.followUpServiceList.length === 1) {
                                                    sfuServiceId = item.followUpServiceList[0].serviceId;
                                                }
                                                const target = values.find((value) => value.specialtyName === item.specialtyName);
                                                if (target) {
                                                    target.enabled = checked;
                                                    target.sfuServiceId = sfuServiceId;
                                                    setValues([...values]);
                                                }
                                            }}
                                        />
                                        <span style={{ marginLeft: '8px' }}>{currentChecked ? "On" : "Off"}</span>
                                    </div>
                                </div>
                                <div className={s.selectWrap}>
                                    <Select
                                        disabled={!currentChecked || !item.followUpServiceList || item.followUpServiceList.length <= 1}
                                        className={s.select}
                                        value={values.find((value) => value.specialtyName === item.specialtyName)?.sfuServiceId || undefined}
                                        onChange={(value) => {
                                            const target = values.find((value) => value.specialtyName === item.specialtyName);
                                            if (target) {
                                                target.sfuServiceId = value;
                                                setValues([...values]);
                                            }
                                        }}
                                    >
                                        {
                                            item.followUpServiceList?.map((service) => {
                                                return (
                                                    <Select.Option key={service.serviceId} value={service.serviceId}>
                                                        {service.serviceType} {service.customizedServiceName ? `(${service.customizedServiceName})` : ''}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Modal>
    );
};

export default SFUUpdateModal;
