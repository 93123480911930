import React, { useCallback } from 'react';
import s from './s.module.less';
import { message, Modal } from 'antd';
import GlobalLayerStore from 'store/GlobalLayer';
import { closeLeaveCampaignModal } from 'utils/globalLayerControl';
import CampaignStore from 'store/Campaign';
import { leaveCampaign } from 'api/campaign';
import CampaignConfig from 'data/CampaignConfig';

const LeavCampaignModalTemplate = () => {
    const [getGlobalLayer] = GlobalLayerStore.useStore();
    const [getSelectedCampaign, setCampaignStore] = CampaignStore.useStore();
    const [loading, setLoading] = React.useState<boolean>(false);
    const selectedCampaign = getSelectedCampaign('selectedCampaign');
    const campaignCode = selectedCampaign?.campaignCode;

    const configs = CampaignConfig[campaignCode as keyof typeof CampaignConfig];

    const handleLeaveCampaign = useCallback(async () => {
        if (selectedCampaign) {
            setLoading(true);

            try {
                const res = await leaveCampaign(selectedCampaign.campaignId);

                if (res && !res.error) {
                    closeLeaveCampaignModal();
                    setCampaignStore('forceRefetchCampaignList', {});
                    setCampaignStore('selectedCampaign', null);
                } else {
                    message.error(res?.message || 'Failed to leave the campaign');
                }
            } catch (e) {
                console.error(e);
            }

            setLoading(false);
        }
    }, [selectedCampaign]);

    if (!configs) {
        return null;
    }

    return (
        <Modal
            open={getGlobalLayer('showLeaveCampaignModal')}
            title={configs?.leaveCampaign?.title}
            destroyOnClose
            okButtonProps={{
                danger: true,
            }}
            footer={configs?.leaveCampaign?.footer}
            okText={configs?.leaveCampaign?.okText}
            cancelText={configs?.leaveCampaign?.cancelText}
            onCancel={() => closeLeaveCampaignModal()}
            confirmLoading={loading}
            onOk={handleLeaveCampaign}
        >
            <p className={s.leaveConfirmContent} dangerouslySetInnerHTML={{ __html: configs?.leaveCampaign?.tipText }} />
        </Modal>
    );
};

export default LeavCampaignModalTemplate;
