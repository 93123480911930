import React, { useEffect, useMemo } from 'react';
import { Form, Button, Input, message, Select, Tooltip, Modal } from 'antd';
import { FormIdentify } from 'types/form';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import FileUploadPrivate from 'components/form/FileUploadPrivate';
import LicenseImg from 'components/LicenseImg';
import {
    EMPTY_PLACEHOLDER,
    REQUIRED_FIELD,
    PRIVACY_LINK } from 'constants/common';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import { saveIdentification } from 'api/identification';
import { saveIdentificationOps, downloadImage } from 'api/operation/indentification';
import cx from 'classnames';
import dayjs from 'dayjs';
import { downloadZipWithBlob } from 'utils/file';

type Props = {
    isKlarityUser?: boolean;
    inited?: boolean;
    initValue?: FormIdentify;
    state?: string;
    providerId?: number;
    onChange?: (value: Partial<FormIdentify>) => void;
    viewOnly?: boolean;
    onNullState?: () => void;
    isOperation?: boolean;
    isEditing?: boolean;
    onEditableChange?: (editing: boolean) => void;
    refetch?: () => void;
};

const PersonalIdentification = (props: Props) => {
    const {
        inited = false,
        initValue,
        providerId,
        onChange,
        viewOnly,
        onNullState,
        isOperation,
        isKlarityUser,
        isEditing,
        onEditableChange,
        refetch,
    } = props;
    const [submiting, setSubmiting] = React.useState(false);
    const [idTypeOptions] = useGetSettingData(EnumFields.IDENTITY_TYPE);

    //enable and show modal is little function cover..

    const [enable, setEnable] = React.useState(!!inited);

    //mean already filled and display modal
    const [showModal, setShowModal] = React.useState(false);
    const [form] = Form.useForm<FormIdentify>();
    const [currentValue, setCurrentValue] = React.useState<FormIdentify>();
    const [, forceUpdate] = React.useState({});
    const [urls, setUrls] = React.useState<{
        fullFrontUrl?: string;
        fullBackUrl?: string;
    }>({});
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [currentImage, setCurrentImage] = React.useState<string | null>(null);

    const handleViewIdClick = (imgUrl: string) => {
        if (!imgUrl) {
            return;
        }
        setCurrentImage(imgUrl);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setCurrentImage(null);
    };

    useEffect(() => {
        setEnable(!!isEditing);
    }, [isEditing]);

    const onSubmit = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            const data = formValues;
            const params: FormIdentify = {
                type: data.type,
                frontUrl: data.frontUrl,
                backUrl: data.backUrl,
            };
            if (isOperation) {
                params.providerId = providerId!;
            }
            const fn = isOperation ? saveIdentificationOps : saveIdentification;
            // eslint-disable-next-line no-await-in-loop
            const result = await fn(params);
            if (!result.error) {
                message.success('Personal identification has updated');
                setEnable(false);
                setShowModal(false);
                onEditableChange?.(false);
                if (onChange) {
                    onChange({
                        ...params,
                        fullFrontUrl: urls.fullFrontUrl ?? initValue?.fullFrontUrl,
                        fullBackUrl: urls.fullBackUrl ?? initValue?.fullBackUrl,
                    });
                }
                refetch?.();
            }
        } catch (e: any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    const handleFullUrlChange = (name: string) => (fullUrl: string) => {
        setUrls((prev) => ({ ...prev, [name]: fullUrl }));
    };
    const handleDownloadAll = async () => {
        if (!isOperation) {
            return;
        }

        try {
            const response = await downloadImage({ providerId: providerId! });
            console.log(response.data instanceof Blob);
            if (response && !response.error && response.data) {
                const zipData = response.data;
                console.log('zipData: ', zipData);
                const fileName = `PersonalIdentification_${dayjs().format('MM/DD/YYYY')}.zip`;
                downloadZipWithBlob(zipData, fileName);
            } else {
                message.error(response?.error);
            }
        } catch (e) {
            console.error(e);
        }
    };

    React.useEffect(() => {
        if (initValue) {
            form.setFieldsValue(initValue);
            setCurrentValue(initValue);
            forceUpdate({});
        }
    }, [initValue]);
    const hasValue = !!currentValue;

    const renderOperation = () => {
        return (
            <>
                <div className={s.row}>
                    <div className={s.displayItem}>
                        <div className={s.idImageWrap}>
                            {currentValue?.fullFrontUrl && <div className={s.idImage}><LicenseImg src={currentValue?.fullFrontUrl} /></div>}
                            {currentValue?.fullBackUrl && currentValue?.type !== 'passport' && <div className={s.idImage}><LicenseImg src={currentValue?.fullBackUrl} /></div>}
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <Button className={s.downloadPhotos} type="link" onClick={handleDownloadAll}>{currentValue?.type === 'passport' ? 'Download ID' : 'Download all'}</Button>
                </div>
            </>
        );
    };

    const renderKlarity = () => {
        return (
            <>
                <div className={s.row}>
                    <div className={s.displayItem}>
                        <div className={cx(s.label, isKlarityUser && s.required)}>
                            Uploaded the front of government-issued ID
                        </div>
                        <div className={s.value}>
                            {currentValue?.fullFrontUrl ? (
                                <>
                                    <LicenseImg src={currentValue?.fullFrontUrl} />
                                    <Button
                                        className={s.viewId}
                                        type="link"
                                        onClick={() => {
                                            handleViewIdClick(currentValue?.fullFrontUrl || '');
                                        }}
                                    >View ID
                                    </Button>
                                </>
                            ) : (
                                <div>{EMPTY_PLACEHOLDER}</div>
                            )}
                        </div>
                    </div>
                </div>
                {currentValue?.type !== 'passport' && (
                    <div className={s.row}>
                        <div className={s.displayItem}>
                            <div className={cx(s.label, isKlarityUser && s.required)}>
                                Uploaded the back of government-issued ID
                            </div>
                            <div className={s.value}>
                                {currentValue?.fullBackUrl ? (
                                    <>
                                        <LicenseImg src={currentValue?.fullBackUrl} />
                                        <Button
                                            className={s.viewId}
                                            type="link"
                                            onClick={() => {
                                                handleViewIdClick(currentValue?.fullBackUrl || '');
                                            }}
                                        >View ID
                                        </Button>
                                    </>
                                ) : (
                                    <div>{EMPTY_PLACEHOLDER}</div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const bodyRender = useMemo(() => {
        if (enable) {
            return (
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item noStyle>
                        <div className={s.modalWrap}>
                            <Form.Item name="id" style={{ position: 'absolute' }}>
                                <Input type="hidden" />
                            </Form.Item>
                            <div className={s.row}>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '360px' }}
                                        label="ID type"
                                        name={['type']}
                                        rules={[
                                            {
                                                required: !!isKlarityUser,
                                                message: 'ID type is a required field',
                                            },
                                        ]}
                                    >
                                        <Select
                                            fieldNames={{ label: 'content', value: 'dictKey' }}
                                            options={idTypeOptions}
                                            placeholder="Choose a government-issued ID type"
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) => {
                                    const type = getFieldValue('type');
                                    if (type === 'passport') {
                                        return (
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '500px' }}
                                                    label={
                                                        <>
                                                            Upload a copy of your
                                                            government-issued ID
                                                        </>
                                                    }
                                                    name={['frontUrl']}
                                                    rules={[
                                                        {
                                                            required: !!isKlarityUser,
                                                            message: REQUIRED_FIELD,
                                                        },
                                                    ]}
                                                >
                                                    <FileUploadPrivate
                                                        isOps={isOperation}
                                                        onChangeFullUrl={handleFullUrlChange(
                                                            'fullFrontUrl',
                                                        )}
                                                    />
                                                </Form.Item>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <>
                                                <div className={s.rowItem}>
                                                    <Form.Item
                                                        style={{ width: '500px' }}
                                                        label={
                                                            <>
                                                                Upload a copy of the{' '}
                                                                <b>front</b> of your
                                                                government-issued ID
                                                            </>
                                                        }
                                                        name={['frontUrl']}
                                                        rules={[
                                                            {
                                                                required: !!isKlarityUser,
                                                                message: REQUIRED_FIELD,
                                                            },
                                                        ]}
                                                    >
                                                        <FileUploadPrivate
                                                            isOps={isOperation}
                                                            onChangeFullUrl={handleFullUrlChange(
                                                                'fullFrontUrl',
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className={s.rowItem}>
                                                    <Form.Item
                                                        style={{ width: '500px' }}
                                                        label={
                                                            <>
                                                                Upload a copy of the{' '}
                                                                <b>back</b> of your
                                                                government-issued ID
                                                            </>
                                                        }
                                                        name={['backUrl']}
                                                        rules={[
                                                            {
                                                                required: !!isKlarityUser,
                                                                message: REQUIRED_FIELD,
                                                            },
                                                        ]}
                                                    >
                                                        <FileUploadPrivate
                                                            isOps={isOperation}
                                                            onChangeFullUrl={handleFullUrlChange(
                                                                'fullBackUrl',
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </>
                                        );
                                    }
                                }}
                            </Form.Item>

                        </div>
                    </Form.Item>
                </Form>
            );
        } else if (hasValue) {
            return (
                <div className={s.subModule} key={currentValue?.id}>
                    <div className={s.row}>
                        <div
                            style={{
                                width: '300px',
                            }}
                            className={s.displayItem}
                        >
                            <div className={cx(s.label, isKlarityUser && s.required)}>ID type</div>
                            <div className={s.value}>
                                {
                                    idTypeOptions?.find(
                                        (item) => item.dictKey === currentValue?.type,
                                    )?.content
                                }
                            </div>
                        </div>
                    </div>

                    {
                        isOperation ? renderOperation() : renderKlarity()
                    }

                </div>
            );
        }

        return <div>{EMPTY_PLACEHOLDER}</div>;
    }, [enable, hasValue, currentValue]);

    return (
        <div className={s.wrap}>
            <h3>
                <div className={s.left}>
                    <span className={s.title}>
                        Personal identification
                        <Tooltip
                            overlayClassName={s.tipCard}
                            overlayInnerStyle={{ padding: '24px', color: '#15191E' }}
                            color="#FFFFFF"
                            title={
                                <div>
                                    Your government ID is securely handled for identity
                                    verification in compliance with our{' '}
                                    <a
                                        style={{ textDecoration: 'underline', color: '#15191E' }}
                                        target="_blank"
                                        href={PRIVACY_LINK}
                                        rel="noreferrer"
                                    >
                                        Privacy Policy
                                    </a>{' '}
                                    and will not be shared with patients or other providers.
                                </div>
                            }
                        >
                            <div className={s.questionMarkIcon} />
                        </Tooltip>
                    </span>
                </div>
                {!viewOnly && !enable && (
                    <Button
                        onClick={() => {
                            onEditableChange?.(true);
                            setEnable(true);
                        }}
                    >
                        Edit
                    </Button>
                )}
                {enable && !viewOnly && (
                    <div className={s.btnWrap}>
                        <Button
                            onClick={() => {
                                form.setFieldsValue(initValue);
                                onEditableChange?.(false);
                                setEnable(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={onSubmit}
                            style={{ marginLeft: 16 }}
                        >
                            Save changes
                        </Button>
                    </div>
                )}
            </h3>
            {bodyRender}
            <Modal
                open={isModalVisible}
                footer={null}
                onCancel={handleModalClose}
                width={800}
                centered
            >
                <div className={s.modalContent}>
                    {currentImage && (
                        <img
                            src={currentImage}
                            alt="ID"
                            className={s.modalImage}
                        />
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default PersonalIdentification;
