import TinyStore from 'lib/tinyStore';
import { ICampaign } from 'types/campaign';
import { Channel } from 'types/common';

type GlobalModalModal = {
    showSubscriptionModal?: boolean;
    showManageSubscriptionModal: { show: boolean, tabKey?: string };
    showSubscriptionSuccess: boolean;
    showSubscriptionTalk: boolean;
    showChannelSubscriptionModal: boolean;
    pendingSubscriptionChannel?: Channel;
    showProviderMappingModal: boolean;
    showProviderMappingConfirmModal: boolean;
    showProviderMappingUpdateTitleModal: boolean;
    showProviderMappingPatientRatingModal: boolean;
    showProviderMappingUpdateSlugModal: boolean;
    showAutoScheduleModal: boolean;
    showSFUModal: boolean;
    showUniprofileAgreementSignModal: {
        show: boolean,
        title: string,
        loopFetch?: boolean,
    };
    showKlaritySubscriptionModal?: boolean;
    showKlarityPlanAdvertising: {
        show: boolean,
        callback?: (v?: any) => void,
        paySuccessPath?: string,
    };
    showKiwiPlanAdvertisingModal: boolean,
    showKiwiPlanAdvertisingTimeIntervalFlag?: boolean,
    showBlackFriday2024EnhancementModal: boolean;
    showBlackFriday2024JoinCampaignModal?: boolean;
    showBlackFriday2024LeaveCampaignModal?: boolean;
    showTermsNConditionsModal?: boolean;
    showCCCTermsNConditionsModal?: boolean;
    showCampaignModal?: boolean;
    showLeaveCampaignModal?: boolean;
    showCampaignTermsNConditionsModal?: boolean;
};

const Store = TinyStore.createStore<GlobalModalModal>({
    nameSpace: 'klarity',
    key: 'global_layer',
    crossBundle: false,
    runTime: true,
    initValue: {
        showSubscriptionModal: false,
        showManageSubscriptionModal: { show: false, tabKey: '2' },
        showSubscriptionSuccess: false,
        showSubscriptionTalk: false,
        showChannelSubscriptionModal: false,
        showProviderMappingModal: false,
        showProviderMappingConfirmModal: false,
        showProviderMappingUpdateTitleModal: false,
        showProviderMappingPatientRatingModal: false,
        showAutoScheduleModal: false,
        showSFUModal: false,
        showProviderMappingUpdateSlugModal: false,
        showUniprofileAgreementSignModal: {
            show: false,
            title: '',
            loopFetch: false,
        },
        showKlaritySubscriptionModal: false,
        showKlarityPlanAdvertising: {
            show: false,
        },
        showKiwiPlanAdvertisingModal: false,
        showKiwiPlanAdvertisingTimeIntervalFlag: undefined,
        showBlackFriday2024EnhancementModal: false,
        showBlackFriday2024JoinCampaignModal: false,
        showBlackFriday2024LeaveCampaignModal: false,
        showTermsNConditionsModal: false,
        showCCCTermsNConditionsModal: false,
        showCampaignModal: false,
        showLeaveCampaignModal: false,
        showCampaignTermsNConditionsModal: false,
    },
});
export default Store;
