enum PathKey {
    TEMPLATE = 'TEMPLATE',
    HOME = 'HOME',
    SIGNUP = 'SIGNUP',
    ONBOARDING = 'ONBOARDING',
    MOBILE_NOT_SUPPORT = 'MOBILE_NOT_SUPPORT',
    SAVED_DRAFT = 'SAVED_DRAFT',
    REDIRECT = 'REDIRECT',
    DASHBOARD = 'DASHBOARD',
    APPOINTMENT = 'APPOINTMENT',
    APPOINTMENT_MANAGEMENT = 'APPOINTMENT_MANAGEMENT',
    APPOINTMENT_GOOGLE = 'APPOINTMENT_GOOGLE',
    APPOINTMENT_SETTING = 'APPOINTMENT_SETTING',
    QUESTIONS = 'QUESTIONS',
    CHANNEL_DETAIL = 'CHANNEL_DETAIL',
    CREATE_UNI_PROFILE = 'CREATE_UNI_PROFILE',
    OPERATION = 'OPERATION',
    PROVIDER_ONBOARD = 'PROVIDER_ONBOARD',
    PROVIDER_ONBOARD_DETAIL = 'PROVIDER_ONBOARD_DETAIL',
    LIST_TO_CHANNEL_DETAIL = 'LIST_TO_CHANNEL_DETAIL',
    LOGIN = 'LOGIN',
    CHANNEL_APPOINTMENT_SUCCESS = 'CHANNEL_APPOINTMENT_SUCCESS',
    CHANNEL_LANDING = 'CHANNEL_LANDING',
    CHANNEL_SURVEY = 'CHANNEL_SURVEY',
    PROVIDER = 'PROVIDER',
    PROVIDER_SEARCH = 'PROVIDER_SEARCH',
    CHANNEL = 'CHANNEL',
    CHANNEL_UPDATE = 'CHANNEL_UPDATE',
    SERVICES_TEMPLATE = 'SERVICES_TEMPLATE',
    RECOMMEND_CHANNELS = 'RECOMMEND_CHANNELS',
    PREVIEW = 'PREVIEW',
    ZOCDOC = 'ZOCDOC',
    AI_CHAT = 'AI_CHAT',
    UNIPROFILE_DETAIL = 'UNIPROFILE_DETAIL',
    PRACTICE_FRONT = 'PRACTICE_FRONT',
    PROSPECTS = 'PROSPECTS',
    CUSTOMIZE_PRACTICE_FRONT = 'CUSTOMIZE_PRACTICE_FRONT',
    GOOGLE = 'GOOGLE',
    YELP = 'YELP',
    PROSPECTS_MANAGE = 'PROSPECTS_MANAGE',
    PRACTICE_FRONT_VOTE_PAGE = 'PRACTICE_FRONT_VOTE_PAGE',
    PRACTICE_FRONT_CREATE_SUCCESS = 'PRACTICE_FRONT_CREATE_SUCCESS',
    PROSPECTS_DETAIL = 'PROSPECTS_DETAIL',
    OPS_MEMBER = 'OPS_MEMBER',
    DEV_WIHTELIST = 'DEV_WIHTELIST',
    ROLES = 'ROLES',
    ACCESS_DENIED = 'ACCESS_DENIED',
    PROVIDER_LOGIN = 'PROVIDER_LOGIN',
    RESET_PASSWORD = 'RESET_PASSWORD',
    PROVIDER_HOME = 'PROVIDER_HOME',
    INIT_UNIPROFILE = 'INIT_UNIPROFILE',
    NOT_FOUND = 'NOT_FOUND',
    VERIFICATION_FAIL = 'VERIFICATION_FAIL',
    SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
    UNDER_MAINTENANCE = 'UNDER_MAINTENANCE',
    DEV_GET_TOKEN = 'DEV_GET_TOKEN',
    HOME_CONFIG = 'HOME_CONFIG',
    HELLO_KLARITY = 'HELLO_KLARITY',
    KIWI_HEALTH = 'KIWI_HEALTH',
    PAY_SUCCESS = 'PAY_SUCCESS',
    CHANNEL_WITHDRRAW_DETAIL = 'CHANNEL_WITHDRRAW_DETAIL',
    CHANNEL_DETAIL_LIST_SUCCESS = 'CHANNEL_DETAIL_LIST_SUCCESS',
    REPUTATION_DASHBORAD = 'REPUTATION_DASHBORAD',
    REPUTATION_MANAGEMENT = 'REPUTATION_MANAGEMENT',
    REPUTATION_CONFIG_PAGE = 'REPUTATION_CONFIG_PAGE',
    REPUTATION_CONFIG_SUCCESS = 'REPUTATION_CONFIG_SUCCESS',
    AI_CONFIG = 'AI_CONFIG',
    UNIPROFILE_RESUME = 'UNIPROFILE_RESUME',
    HELLO_KLARITY_PROVIDERS = 'HELLO_KLARITY_PROVIDERS',
    CUSTOMIZE_PROVIDER_PROFILE_PAGE = 'CUSTOMIZE_PROVIDER_PROFILE_PAGE',
    HELLO_KLARITY_MAPPING_GOLIVE_SUCCESS = 'HELLO_KLARITY_MAPPING_GOLIVE_SUCCESS',
    HELLO_KLARITY_MAPPING_PAUSE_SUCCESS = 'HELLO_KLARITY_MAPPING_PAUSE_SUCCESS',
    HELLO_KLARITY_PAGE_MANAGEMENT = 'HELLO_KLARITY_PAGE_MANAGEMENT',
    HELLO_KLARITY_PAGE_DETAIL = 'HELLO_KLARITY_PAGE_DETAIL',
    CHANNEL_NOT_FOUND = 'CHANNEL_NOT_FOUND',
    SALES_PROVIDER_EXP = 'SALES_PROVIDER_EXP',
    CONTRACT_LINK_PAGE = 'CONTRACT_LINK_PAGE',
    CONFIGURATIONS = 'CONFIGURATIONS',
    EHR_ONBOARDING = 'EHR_ONBOARDING',
    EHR_CART = 'EHR_CART',
    EHR_ADD_ON_OPS = 'EHR_ADD_ON_OPS',
    EHR_ADD_ON_PROVIDER = 'EHR_ADD_ON_PROVIDER',
    EHR_HOME_PROVIDER = 'EHR_HOME_PROVIDER',
    EHR_ADD_ON_DETAIL = 'EHR_ADD_ON_DETAIL',
    KLARITY_PLAN_PROCESS = 'KLARITY_PLAN_PROCESS',
    KIWI_KNOWLEDEG = 'KIWI_KNOWLEDEG',
    KLARITY_SUBSCRIPTION = 'KLARITY_SUBSCRIPTION',
    KLARITY_AGREEMENT = 'KLARITY_AGREEMENT',
    KLARITY_REVIEWS = 'KLARITY_REVIEWS',
    KLARITY_REVIEWS_DETAIL = 'KLARITY_REVIEWS_DETAIL',
    AVA_SETTING_PROSPECTS = 'AVA_SETTING_PROSPECTS',
    KLARITY_ASSESSMENT = 'KLARITY_ASSESSMENT',
    KLARITY_ASSESSMENT_DETAIL = 'KLARITY_ASSESSMENT_DETAIL',
    KLARITY_SELECT_PROGRAMS_DETAIL = 'KLARITY_SELECT_PROGRAMS_DETAIL',
    TESTING = 'TESTING',
    HELP_CENTER = 'HELP_CENTER',
}

export const PATH: Record<PathKey, string> = {
    [PathKey.TEMPLATE]: '/template',
    [PathKey.HOME]: '/',
    [PathKey.SIGNUP]: '/signup',
    [PathKey.ONBOARDING]: '/onboarding',
    [PathKey.MOBILE_NOT_SUPPORT]: '/mobile-not-support',
    [PathKey.SAVED_DRAFT]: '/saved-draft',
    [PathKey.REDIRECT]: '/redirect',
    [PathKey.DASHBOARD]: '/dashboard',
    [PathKey.APPOINTMENT]: 'appointment',
    [PathKey.APPOINTMENT_MANAGEMENT]: 'appointment-management',
    [PathKey.APPOINTMENT_GOOGLE]: 'appointment-google',
    [PathKey.APPOINTMENT_SETTING]: 'appointment-setting',
    [PathKey.QUESTIONS]: 'questions',
    [PathKey.CHANNEL_DETAIL]: 'channel-detail',
    [PathKey.CREATE_UNI_PROFILE]: '/create-uni-profile',
    [PathKey.OPERATION]: '/operation',
    [PathKey.PROVIDER_ONBOARD]: 'provider-onboard',
    [PathKey.PROVIDER_ONBOARD_DETAIL]: 'provider-onboard-detail',
    [PathKey.LIST_TO_CHANNEL_DETAIL]: 'list-to-channel-detail',
    [PathKey.LOGIN]: 'login',
    [PathKey.CHANNEL_APPOINTMENT_SUCCESS]: 'channel-success',
    [PathKey.CHANNEL_LANDING]: 'channel-landing',
    [PathKey.CHANNEL]: 'channel',
    [PathKey.CHANNEL_SURVEY]: 'channel-survey',
    [PathKey.PROVIDER]: 'provider',
    [PathKey.PROVIDER_SEARCH]: 'provider-search',
    [PathKey.CHANNEL_UPDATE]: 'channel-update',
    [PathKey.SERVICES_TEMPLATE]: 'services-template',
    [PathKey.RECOMMEND_CHANNELS]: 'recomend-channels',
    [PathKey.PREVIEW]: 'preview',
    [PathKey.ZOCDOC]: 'zocdoc',
    [PathKey.GOOGLE]: 'google',
    [PathKey.YELP]: 'yelp',
    [PathKey.AI_CHAT]: '/aichat',
    [PathKey.UNIPROFILE_DETAIL]: 'uniprofile-detail',
    [PathKey.PRACTICE_FRONT]: 'practice-front',
    [PathKey.PROSPECTS]: 'prospects',
    [PathKey.CUSTOMIZE_PRACTICE_FRONT]: 'customize-practice-front',
    [PathKey.PROSPECTS_MANAGE]: 'prospects-manage',
    [PathKey.AVA_SETTING_PROSPECTS]: 'ava-setting-prospects',
    [PathKey.PRACTICE_FRONT_VOTE_PAGE]: 'practice-front-vote',
    [PathKey.PRACTICE_FRONT_CREATE_SUCCESS]: 'practice-front-success',
    [PathKey.PROSPECTS_DETAIL]: 'prospects-detail',
    [PathKey.OPS_MEMBER]: 'ops-member',
    [PathKey.DEV_WIHTELIST]: 'dev-whitelist',
    [PathKey.ROLES]: 'roles',
    [PathKey.ACCESS_DENIED]: 'access-denied',
    [PathKey.PROVIDER_LOGIN]: 'provider-login',
    [PathKey.PROVIDER_HOME]: 'home',
    [PathKey.RESET_PASSWORD]: '/reset-password',
    [PathKey.INIT_UNIPROFILE]: '/init-uniprofile',
    [PathKey.NOT_FOUND]: '/not-found',
    [PathKey.VERIFICATION_FAIL]: '/verification-fail',
    [PathKey.SOMETHING_WENT_WRONG]: '/something-went-wrong',
    [PathKey.UNDER_MAINTENANCE]: '/under-maintenance',
    [PathKey.DEV_GET_TOKEN]: '/__DEV__/view-token',
    [PathKey.HOME_CONFIG]: 'home-config',
    [PathKey.HELLO_KLARITY]: 'hello-klarity',
    [PathKey.KIWI_HEALTH]: 'kiwi-health',
    [PathKey.PAY_SUCCESS]: '/paysuccess',
    [PathKey.CHANNEL_WITHDRRAW_DETAIL]: 'channel-withdraw-detail',
    [PathKey.CHANNEL_DETAIL_LIST_SUCCESS]: 'channel-detail-success',
    [PathKey.REPUTATION_MANAGEMENT]: 'reputation-management',
    [PathKey.REPUTATION_DASHBORAD]: 'reputation-dashboard',
    [PathKey.REPUTATION_CONFIG_PAGE]: '/reputation-config',
    [PathKey.REPUTATION_CONFIG_SUCCESS]: 'reputation-config-success',
    [PathKey.AI_CONFIG]: 'ai-config',
    [PathKey.CONFIGURATIONS]: 'configurations',
    [PathKey.UNIPROFILE_RESUME]: 'uniprofile-resume',
    [PathKey.HELLO_KLARITY_PROVIDERS]: 'hello-klarity-providers',
    [PathKey.CUSTOMIZE_PROVIDER_PROFILE_PAGE]: 'customize-provider-profile-page',
    [PathKey.HELLO_KLARITY_MAPPING_GOLIVE_SUCCESS]: 'mapping-golive-success',
    [PathKey.HELLO_KLARITY_MAPPING_PAUSE_SUCCESS]: 'mapping-pause-success',
    [PathKey.HELLO_KLARITY_PAGE_MANAGEMENT]: 'hello-klarity-page-management',
    [PathKey.HELLO_KLARITY_PAGE_DETAIL]: 'hello-klarity-page-detail',
    [PathKey.CHANNEL_NOT_FOUND]: 'channel-not-found',
    [PathKey.SALES_PROVIDER_EXP]: 'new-client',
    [PathKey.CONTRACT_LINK_PAGE]: 'contract-link',
    [PathKey.EHR_ONBOARDING]: 'ehr-onboarding',
    [PathKey.EHR_CART]: 'ehr-cart',
    [PathKey.EHR_ADD_ON_OPS]: 'ehr-add-on',
    [PathKey.EHR_HOME_PROVIDER]: 'ehr-home',
    [PathKey.EHR_ADD_ON_PROVIDER]: 'ehr-add-on',
    [PathKey.EHR_ADD_ON_DETAIL]: 'ehr-add-on-detail',
    [PathKey.KLARITY_PLAN_PROCESS]: 'klarity-plan-process',
    [PathKey.KIWI_KNOWLEDEG]: 'kiwi-knowledge',
    [PathKey.KLARITY_SUBSCRIPTION]: 'klarity-subscription',
    [PathKey.KLARITY_AGREEMENT]: 'klarity-agreement',
    [PathKey.KLARITY_REVIEWS]: 'klarity-reviews',
    [PathKey.KLARITY_REVIEWS_DETAIL]: 'klarity-reviews-detail',
    [PathKey.KLARITY_ASSESSMENT]: 'klarity-assessment',
    [PathKey.KLARITY_ASSESSMENT_DETAIL]: 'klarity-assessment-detail',
    [PathKey.KLARITY_SELECT_PROGRAMS_DETAIL]: 'klarity-programs-detail',
    [PathKey.TESTING]: 'testing',
    [PathKey.HELP_CENTER]: 'help-center',
};

export const withoutBasicAPICallingPath = [
    PATH.HOME,
    `/${PATH.LOGIN}`,
    PATH.SIGNUP,
    `/${PATH.PROVIDER_LOGIN}`,
    PATH.RESET_PASSWORD,
    PATH.REDIRECT,
    PATH.VERIFICATION_FAIL,
    PATH.MOBILE_NOT_SUPPORT,
    `/${PATH.ACCESS_DENIED}`,
    PATH.NOT_FOUND,
    PATH.SOMETHING_WENT_WRONG,
    `/${PATH.SALES_PROVIDER_EXP}`,
];

export const loginPages = [
    `/${PATH.LOGIN}`,
    PATH.SIGNUP,
    `/${PATH.PROVIDER_LOGIN}`,
    PATH.RESET_PASSWORD,
    PATH.REDIRECT,
    PATH.VERIFICATION_FAIL,
    PATH.MOBILE_NOT_SUPPORT,
    `/${PATH.ACCESS_DENIED}`,
    PATH.NOT_FOUND,
    PATH.SOMETHING_WENT_WRONG,
    `/${PATH.SALES_PROVIDER_EXP}`,
];

export const supportsMobile = [
    `/${PATH.SALES_PROVIDER_EXP}`,
];

export const dontShowAdvertisingPaht = [
    `/${PATH.SALES_PROVIDER_EXP}`,
    ...loginPages,
];
