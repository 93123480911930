import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
// import campainIcon from 'assets/campain/blackfriday2024/campainIcon.svg';
import { ICampaign } from 'types/campaign';
import dayjs from 'utils/dayjs';
import CampaignStore from 'store/Campaign';
import { showCampaignModal, showLeaveCampaignModal } from 'utils/globalLayerControl';
import CampaignConfig from 'data/CampaignConfig';
import { useNavigate } from 'react-router-dom';

interface IProps {
    campain: ICampaign;
}

const CampaignTemplate = ({
    campain,
}: IProps) => {
    const navigate = useNavigate();
    const [joinedBtnHoverd, setJoinedBtnHoverd] = React.useState<boolean>(false);
    const [, setSelectedCampaign] = CampaignStore.useStore();
    const { campaignName, description, providerEndDateTime, providerStartDateTime, joined, joinedCount = 108, campaignCode } = campain;

    const configs = CampaignConfig[campaignCode as keyof typeof CampaignConfig];

    const handleJoin = useCallback(() => {
        if (configs?.homepageBanner?.JoinButton?.isLink) {
            navigate(configs?.homepageBanner?.JoinButton?.link);
        } else if (configs?.isCampaign) {
            setSelectedCampaign('selectedCampaign', campain);
            showCampaignModal();
        }
    }, [campain, configs]);

    const handleLeaveGroup = useCallback(() => {
        if (configs?.leaveCampaign) {
            setSelectedCampaign('selectedCampaign', campain);
            showLeaveCampaignModal();
        }
    }, [campain, configs]);

    const joinBtnRender = useMemo(() => {
        if (joinedBtnHoverd) {
            return (
                <div
                    className={`${s.button}`}
                    style={{
                        background: configs.homepageBanner.leaveButton.background,
                        color: configs.homepageBanner.leaveButton.color,
                    }}
                    onClick={handleLeaveGroup}
                >
                    {configs.homepageBanner.leaveButton.text}
                </div>
            );
        }

        return (
            <div
                className={`${s.button}`}
                style={{
                    background: configs.homepageBanner.joinedButton.background,
                    color: configs.homepageBanner.joinedButton.color,
                }}
            >
                {configs.homepageBanner.joinedButton.text}
            </div>
        );
    }, [configs, joinedBtnHoverd, handleLeaveGroup]);

    if (!configs) {
        return null;
    }

    return (
        <div
            className={s.wrap}
            style={{
                background: configs.homepageBanner.background,
            }}
        >
            <div
                className={s.leftWrap}
                style={{
                    paddingLeft: configs.homepageBanner.leftPart.paddingLeft,
                }}
            >
                <img
                    className={s.campaignIcon}
                    src={configs?.homepageBanner?.icon.url}
                    style={{
                        width: configs.homepageBanner.icon.width,
                        height: configs.homepageBanner.icon.height,
                        left: configs.homepageBanner.icon.left,
                    }}
                />
                <div className={s.info}>
                    {
                        !providerStartDateTime && !providerEndDateTime ? '' : (
                            <div
                                className={s.timeLimit}
                                style={{
                                    background: configs?.homepageBanner?.limitationBackground,
                                    color: configs?.homepageBanner?.limitationColor,
                                }}
                            >Limited-time: {providerStartDateTime ? dayjs(providerStartDateTime || '').format('MMM DD') : ''} - {providerEndDateTime ? dayjs(providerEndDateTime || '').format('MMM DD') : ''}
                            </div>
                        )
                    }
                    <div
                        className={s.name}
                        style={{
                            color: configs.homepageBanner.titleColor,
                        }}
                    >{campaignName}
                    </div>
                    <div
                        className={s.desc}
                        style={{
                            color: configs.homepageBanner.descriptionColor,
                        }}
                    >{description}
                    </div>
                </div>
            </div>
            <div className={s.rightWrap}>
                {
                    configs.homepageBanner.showJoinedCount ? (
                        <>
                            <div className={s.providerAvatars}>
                                <div className={s.avatarGroup} />
                            </div>
                            <div
                                className={s.text}
                                onClick={handleJoin}
                            >{joinedCount} providers joined
                            </div>
                        </>
                    ) : ''
                }
                {
                    joined ?
                        <div
                            className={`${s.buttonWrap}`}
                            onMouseEnter={() => setJoinedBtnHoverd(true)}
                            onMouseLeave={() => setJoinedBtnHoverd(false)}
                        >
                            {joinBtnRender}
                        </div> :
                        <div
                            className={`${s.button} ${s.joinBtn}`}
                            onClick={handleJoin}
                            style={{
                                color: configs.homepageBanner.JoinButton.color,
                                background: configs.homepageBanner.JoinButton.background,
                            }}
                        >Join now
                        </div>
                }
            </div>
        </div>
    );
};

export default CampaignTemplate;
