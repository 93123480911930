// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--CG23m .s-module__header--mRE2O {
  color: var(--gray-800, #1F2937);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.s-module__wrap--CG23m .s-module__header--mRE2O .s-module__desc--AhWaS {
  color: var(--gray-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}
.s-module__wrap--CG23m .s-module__link--wkwUO {
  color: #00816B;
  margin-right: 16px;
  width: 80px;
}
.s-module__modalWrap--t8pYo .ant-modal-footer {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/Configurations/components/ReferralConfig/s.module.less"],"names":[],"mappings":"AAAA;EAEI,+BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;AAZA;EAeM,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAAJ,aAAa;AACf;AApBA;EAwBI,cAAA;EACA,kBAAA;EACA,WAAA;AADJ;AAKA;EAGM,aAAA;AALN","sourcesContent":[".wrap {\n  .header {\n    color: var(--gray-800, #1F2937);\n    font-family: Inter;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 28px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 24px;\n\n    .desc {\n      color: var(--gray-700);\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: 20px; /* 142.857% */\n    }\n  }\n\n  .link {\n    color: #00816B;\n    margin-right: 16px;\n    width: 80px;\n  }\n}\n\n.modalWrap {\n  :global {\n    .ant-modal-footer {\n      display: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--CG23m`,
	"header": `s-module__header--mRE2O`,
	"desc": `s-module__desc--AhWaS`,
	"link": `s-module__link--wkwUO`,
	"modalWrap": `s-module__modalWrap--t8pYo`
};
export default ___CSS_LOADER_EXPORT___;
