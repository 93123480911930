export enum EnumFields {
    LIST_CHANNEL_STATUS = 'LIST_CHANNEL_STATUS',
    DEGREE = 'DEGREE',
    COMPANY_TYPE = 'COMPANY_TYPE',
    EMPLOYMENT_TYPE = 'EMPLOYMENT_TYPE',
    TREATMENT_APPROACH = 'TREATMENT_APPROACH',
    DURATION = 'DURATION',
    CHANNEL = 'CHANNEL',
    ORIENTATION = 'ORIENTATION',
    CHANNEL_AUDIT_STATUS = 'CHANNEL_AUDIT_STATUS',
    INSURANCE = 'INSURANCE',
    COLOR = 'COLOR',
    PROFILE_STATUS = 'PROFILE_STATUS',
    PROFILE_AUDIT_STATUS = 'PROFILE_AUDIT_STATUS',
    PROFILE_COMPLETE = 'PROFILE_COMPLETE',
    CREDENTIAL_TYPE = 'CREDENTIAL_TYPE',
    GENDER = 'GENDER',
    ETHNICITY = 'ETHNICITY',
    RELIGION = 'RELIGION',
    CHANNEL_PAY_PLAN_TYPE = 'CHANNEL_PAY_PLAN_TYPE',
    BILLING_CYCLE = 'BILLING_CYCLE',
    WITHDRAW_STATUS = 'WITHDRAW_STATUS',
    WITHDRAW_REASON = 'WITHDRAW_REASON',
    CHANNEL_TAG = 'CHANNEL_TAG',
    CHANNEL_FOCUS = 'CHANNEL_FOCUS',
    CHANNEL_SORT_TYPE = 'CHANNEL_SORT_TYPE',
    STATE = 'STATE',
    PATIENT_SURVEY_SEND_STATUS = 'PATIENT_SURVEY_SEND_STATUS',
    PROVIDER_MAPPING_LIST_STATUS = 'PROVIDER_MAPPING_LIST_STATUS',
    PROVIDER_PATIENT_RATING = 'PROVIDER_PATIENT_RATING',
    PROVIDER_MAPPING_STATUS = 'PROVIDER_MAPPING_STATUS',
    HELLO_KLARITY_CONDITION = 'HELLO_KLARITY_CONDITION',
    LICEN = 'LICEN',
    HEATH = 'HEATH',
    SCHED = 'SCHED',
    STLIC = 'STLIC',
    SIFAP = 'SIFAP',
    SIPMH = 'SIPMH',
    TIMEZONE = 'TIMEZONE',
    LIST_CARE = 'LIST_CARE',
    ADDRESS_PUBLIC_TYPE = 'ADDRESS_PUBLIC_TYPE',
    PAYMENT_STATUS = 'PAYMENT_STATUS',
    PROVIDER_PAY_PLAN_TYPE = 'PROVIDER_PAY_PLAN_TYPE',
    TAX_ID_TYPE = 'TAX_ID_TYPE',
    SERVICE_TYPE = 'SERVICE_TYPE',
    HELLO_KLARITY_SPECIALTY = 'HELLO_KLARITY_SPECIALTY',
    HELLO_KLARITY_SERVICE_PAGE = 'HELLO_KLARITY_SERVICE_PAGE',
    PROVIDER_SOURCE = 'PROVIDER_SOURCE',
    SPECIALTY_TYPE = 'SPECIALTY_TYPE',
    PROVIDER_NEGATIVE_REVIEW_TAG = 'PROVIDER_NEGATIVE_REVIEW_TAG',
    PROVIDER_POSITIVE_REVIEW_TAG = 'PROVIDER_POSITIVE_REVIEW_TAG',
    REVIEW_DISPUTE_REASON = 'REVIEW_DISPUTE_REASON',
    IDENTITY_TYPE = 'IDENTITY_TYPE',
}

export type SettingEnum = {
    id: number,
    type: EnumFields,
    content: string,
    dictKey: string,
    description?: string | null,
};

export type SettingDataType = Record<EnumFields, Array<SettingEnum>>;
