import React, { } from 'react';
import { Modal } from 'antd';
import Subscription from 'components/Subscription';
import KlairtySubscription from 'components/KlaritySubscription';
import ManageSubscription from 'components/ManageSubscription';
import SubscriptionSuccess from 'components/SubscriptionSuccess';
import commonS from 'styles/common.module.less';
import { closeChannelSubscriptionModal, closeKiwiPlanAdvertisingModal, closeKlaritySubscriptionModal, closeManageSubscriptionModal, closeSubscriptionModal, closeSubscriptionSuccessModal, closeSubscriptionTalkModal, openKiwiPlanAdvertisingModal, openUniprofileAgreementSignModal, closeBlackFriday2024EnhancementModal, closeBlackFriday2024JoinCampaignModal, closeTermsNConditionsModal, closeCCCTermsNConditionsModal, closeCampaignModal, closeCampaignTermsNConditionsModal } from 'utils/globalLayerControl';
import GlobalLayerStore from 'store/GlobalLayer';
import { isOpPortal } from 'utils/common';
import SubscriptionTalk from 'components/SubscriptionTalk';
import ChannelSubscription from 'components/ChannelSubscription';
import { IS_BACK_FROM_STRIPE } from 'constants/common';
import KlarityPlanModal from 'components/KlarityPlanModal';
import SubscriptionStore from 'store/Subscription';
import s from './s.module.less';
import KiwiPlanAdvertise from 'components/KiwiPlanAdvertise';
import { setShowKiwiPlanAdvertise, getShowKiwiPlanAdvertise } from 'utils/sessionStorage';
import { setShowKiwiPlanAdvertiseTimes, getShowKiwiPlanAdvertiseTimes } from 'utils/localstore';
import { isFreeUserByInfo } from 'utils/provider';
import EnhancementModal from './CampainModals/blackfriday2024/EnhancementModal';
import JoinCampaignModal from './CampainModals/blackfriday2024/JoinCampaignModal';
import LeavCampaignModal from './CampainModals/blackfriday2024/LeavCampaignModal';
import TermsNConditions from 'components/TermsNConditions';
import CCCTermsNConditions from 'components/TermsNConditions/CCC';
import CampaignTermsNConditions from 'components/TermsNConditions/template';
import JoinCampaignModalTemplate from './CampainModals/CampaignModalTemplate/JoinCampaignModalTemplate';
import LeavCampaignModalTemplate from './CampainModals/CampaignModalTemplate/LeavCampaignModalTemplate';

const GlobalLayer = () => {
    const [getGlobalLayer, setGlobalLayer] = GlobalLayerStore.useStore();
    const [getSubscription] = SubscriptionStore.useStore();
    const showKiwiPlanAdvertisingTimeIntervalFlag = getGlobalLayer('showKiwiPlanAdvertisingTimeIntervalFlag');
    const currentPlan = getSubscription('currentPlan');
    const showKiwiAdvertiseIntervalRef = React.useRef<number>();

    React.useEffect(() => {
        if (currentPlan && isFreeUserByInfo(currentPlan)) {
            if (getShowKiwiPlanAdvertise() === 'idel') {
                setShowKiwiPlanAdvertise(true);
            }
            setGlobalLayer('showKiwiPlanAdvertisingTimeIntervalFlag', true);
        } else {
            //setShowKiwiPlanAdvertise(undefined);
            setGlobalLayer('showKiwiPlanAdvertisingTimeIntervalFlag', false);
        }
    }, [currentPlan]);

    React.useEffect(() => {
        const callback = () => {
            if (getShowKiwiPlanAdvertise() !== true) {
                //cleared
                setGlobalLayer('showKiwiPlanAdvertisingTimeIntervalFlag', false);
                if (showKiwiAdvertiseIntervalRef.current) {
                    window.clearInterval(showKiwiAdvertiseIntervalRef.current);
                }
            } else {
                const isShowingModal = !!(document.getElementsByClassName('ant-modal-wrap') && document.getElementsByClassName('ant-modal-wrap').length > 0);
                if (isShowingModal) {
                    return;
                }
                const times = getShowKiwiPlanAdvertiseTimes();
                if (times === '2') {
                    setGlobalLayer('showKiwiPlanAdvertisingTimeIntervalFlag', false);
                    if (showKiwiAdvertiseIntervalRef.current) {
                        window.clearInterval(showKiwiAdvertiseIntervalRef.current);
                    }
                    return;
                }
                if (!times) {
                    setShowKiwiPlanAdvertiseTimes('1');
                }
                if (times === '1') {
                    setShowKiwiPlanAdvertiseTimes('2');
                }
                setGlobalLayer('showKiwiPlanAdvertisingTimeIntervalFlag', false);
                openKiwiPlanAdvertisingModal();
            }
        };
        if (showKiwiPlanAdvertisingTimeIntervalFlag) {
            if (showKiwiAdvertiseIntervalRef.current) {
                window.clearInterval(showKiwiAdvertiseIntervalRef.current);
            } else {
                showKiwiAdvertiseIntervalRef.current = window.setInterval(callback, 5500);
            }
        } else if (showKiwiAdvertiseIntervalRef.current) {
            window.clearInterval(showKiwiAdvertiseIntervalRef.current);
        }
        return () => {
            if (showKiwiAdvertiseIntervalRef.current) {
                window.clearInterval(showKiwiAdvertiseIntervalRef.current);
            }
        };
    }, [showKiwiPlanAdvertisingTimeIntervalFlag]);

    if (isOpPortal()) {
        return null;
    }
    let width: string = '88%';
    if (window.screen.availWidth * 0.88 > 824) {
        width = '824px';
    }
    return (
        <div>
            <Modal
                width="86%"
                style={{ maxWidth: '1200px' }}
                open={getGlobalLayer('showSubscriptionModal')}
                //open
                title={null}
                footer={null}
                destroyOnClose
                onCancel={() => {
                    closeSubscriptionModal();
                }}
                // className={`${commonS.modalGlobalLayerWrap} ${commonS.modalGlobalLayerContentWithScrollWrap}`}
                className={`${commonS.modalGlobalLayerWrap} ${commonS.modalGlobalLayerContentWithTopPadding}`}
            >
                <Subscription />
            </Modal>
            <Modal
                width="90%"
                open={getGlobalLayer('showManageSubscriptionModal')?.show}
                //open
                title={null}
                footer={null}
                destroyOnClose
                onCancel={() => {
                    closeManageSubscriptionModal();
                }}
                className={commonS.modalGlobalLayerWrap}
            >
                <ManageSubscription tabKey={getGlobalLayer('showManageSubscriptionModal')?.tabKey} />
            </Modal>
            <Modal
                width={width}
                open={getGlobalLayer('showSubscriptionSuccess')}
                title={null}
                footer={null}
                onCancel={() => {
                    sessionStorage.removeItem(IS_BACK_FROM_STRIPE);
                    closeSubscriptionSuccessModal();
                }}
                className={commonS.modalGlobalLayerWrap}
            >
                <SubscriptionSuccess
                    onDone={() => {
                        sessionStorage.removeItem(IS_BACK_FROM_STRIPE);
                        closeSubscriptionSuccessModal();
                        openUniprofileAgreementSignModal('Confirm agreement of service', true);
                    }}
                />
            </Modal>
            <Modal
                width="80%"
                open={getGlobalLayer('showSubscriptionTalk')}
                title={null}
                footer={null}
                onCancel={() => {
                    closeSubscriptionTalkModal();
                }}
                className={commonS.modalGlobalLayerWrap}
            >
                <SubscriptionTalk
                    onDone={() => {
                        closeSubscriptionTalkModal();
                    }}
                />
            </Modal>

            <Modal
                width="560px"
                open={getGlobalLayer('showChannelSubscriptionModal')}
                title={null}
                footer={null}
                onCancel={() => {
                    closeChannelSubscriptionModal();
                }}
                className={commonS.modalWithoutRadius}
            >
                <ChannelSubscription />
            </Modal>
            <Modal
                width="86%"
                open={getGlobalLayer('showKlaritySubscriptionModal')}
                title={null}
                footer={null}
                onCancel={() => {
                    closeKlaritySubscriptionModal();
                }}
                className={`${commonS.modalGlobalLayerWrap} ${commonS.modalGlobalLayerContentWithScrollWrap}`}
            >
                <KlairtySubscription />
            </Modal>

            {getGlobalLayer('showKlarityPlanAdvertising')?.show && <KlarityPlanModal />}

            <Modal
                width={992}
                className={s.avModal}
                title={null}
                open={getGlobalLayer('showKiwiPlanAdvertisingModal')}
                footer={null}
                onCancel={() => {
                    closeKiwiPlanAdvertisingModal();
                }}
                maskClosable={false}
            >
                <KiwiPlanAdvertise />
            </Modal>
            <Modal
                width={992}
                destroyOnClose
                open={getGlobalLayer('showBlackFriday2024EnhancementModal')}
                footer={null}
                maskClosable={false}
                onCancel={() => {
                    closeBlackFriday2024EnhancementModal();
                }}
            >
                <EnhancementModal />
            </Modal>
            <Modal
                width={992}
                open={getGlobalLayer('showBlackFriday2024JoinCampaignModal')}
                footer={null}
                destroyOnClose
                maskClosable={false}
                onCancel={() => {
                    closeBlackFriday2024JoinCampaignModal();
                }}
            >
                <JoinCampaignModal />
            </Modal>
            {
                getGlobalLayer('showBlackFriday2024LeaveCampaignModal') && <LeavCampaignModal />
            }
            <Modal
                width="80vw"
                destroyOnClose
                title="Terms & Conditions"
                open={getGlobalLayer('showTermsNConditionsModal')}
                className={commonS.modalFixHeightFullScreenWrap}
                footer={null}
                onCancel={() => {
                    closeTermsNConditionsModal();
                }}
            >
                <TermsNConditions />
            </Modal>
            <Modal
                width="80vw"
                destroyOnClose
                title="Care Commitment Card Terms and Conditions"
                open={getGlobalLayer('showCCCTermsNConditionsModal')}
                className={commonS.modalFixHeightFullScreenWrap}
                footer={null}
                onCancel={() => {
                    closeCCCTermsNConditionsModal();
                }}
            >
                <CCCTermsNConditions />
            </Modal>

            {/* Campaign Modal template start*/}
            <Modal
                width={992}
                destroyOnClose
                open={getGlobalLayer('showCampaignModal')}
                footer={null}
                maskClosable={false}
                onCancel={() => {
                    closeCampaignModal();
                }}
            >
                <JoinCampaignModalTemplate />
            </Modal>
            {
                getGlobalLayer('showLeaveCampaignModal') && <LeavCampaignModalTemplate />
            }
            <Modal
                width="80vw"
                destroyOnClose
                title="Terms & Conditions"
                open={getGlobalLayer('showCampaignTermsNConditionsModal')}
                className={commonS.modalFixHeightFullScreenWrap}
                footer={null}
                onCancel={() => {
                    closeCampaignTermsNConditionsModal();
                }}
            >
                <CampaignTermsNConditions />
            </Modal>
            {/* Campaign Modal template end*/}
        </div>
    );
};

export default GlobalLayer;
