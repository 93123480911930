import { EStep, StepValue } from './types';

export const StepData = [
    {
        title: 'PROFILE',
        desc: 'Contact info, credentials, title, about me, photos, NPI',
        step: EStep.PROFILE,
    },
    {
        title: 'HEALTHCARE EXPERIENCE',
        desc: 'Organization, title, and description',
        step: EStep.HEALTHCARE_EXPERIENCE,
    },
    {
        title: 'EDUCATION',
        desc: 'School, degree, major',
        step: EStep.EDUCATION,
    },
    {
        title: 'RECOGNITION',
        desc: 'Associations, awards, and publications',
        step: EStep.ADDITIONAL_INFORMATION,
    },
    {
        title: 'PRACTICE',
        desc: 'Practice info, specialties, treatment philosophy, approaches',
        step: EStep.PRACTICE,
    },
    {
        title: 'LICENSE',
        desc: 'Personal identification, Practice licences, additional licences and certificates',
        step: EStep.LICENSE,
    },
    {
        title: 'SERVICES',
        desc: 'Services, length, price, and patient type',
        step: EStep.SERVICES,
    },
    {
        title: 'KLARITY SERVICE AND PRICE',
        desc: 'Specialties, service type, price, duration, patient accepted, and limitation',
        step: EStep.KLARITY_SERVICES,
    },
    {
        title: 'INSURANCE',
        desc: 'Accepted insurance',
        step: EStep.INSURANCE,
    },
    {
        title: 'BIOGRAPHIC INFORMATION',
        desc: 'Ethnicity, gender, birth date, religion, other used names',
        step: EStep.BIOGRAPHIC,
    },
    {
        title: 'REVIEW',
        desc: 'Review and confirm all information',
        step: EStep.PREVIEW,
    },
];

// index和/src/pages/CreateUniProfilePage/types.ts 里的EStep 索引对齐
export const STEPS = [
    StepValue.PROFILE,
    StepValue.HEALTHCARE_EXPERIENCE,
    StepValue.EDUCATION,
    StepValue.ADDITIONAL_INFORMATION,
    StepValue.PRACTICE,
    StepValue.LICENSE,
    StepValue.SERVICES,
    StepValue.KLARITY_SERVICES,
    StepValue.INSURANCE,
    StepValue.BIOGRAPHIC,
    StepValue.PREVIEW,
];

export const StepKeys = [
    'profile',
    'experienceList',
    'educationList',
    'addtional',
    'practice',
    'licenses',
    'services',
];
