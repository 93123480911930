import { Request } from '../request';
import { FormIdentify } from 'types/form';

export const saveIdentificationOps = (params: FormIdentify) => Request.post({
    url: '/admin-server/provider/profile/personal/identification/save', params,
});

export const downloadImage = (params: {
    providerId: number;
}) => Request.get({
    url: '/admin-server/provider/profile/identification/image/download',
    params,
    config: {
        headers: {
            'Content-Type': 'application/zip',
        },
        responseType: 'blob',
    },
});
