import React, { useCallback, useEffect } from 'react';
import { ConfigProvider, Spin, notification } from 'antd';
import { getUrlParam, isMobile, isOpPortal, shouldShowAdvertisingIcon } from 'utils/common';
import {
    Outlet, useLocation,
    BrowserRouter,
    useNavigate,
    useRoutes,
} from 'react-router-dom';
import { PATH, dontShowAdvertisingPaht, supportsMobile, withoutBasicAPICallingPath } from 'constants/path';
import { ROUTERS } from 'router/config';
import { GOOGLE_CLIENT_ID } from 'constants/google';
import 'styles/variable.css';
import 'styles/reset.css';
import { init } from 'utils/googleClient';
import GoogleStore from 'store/Google';
import { GoogleToken } from 'types/common';
import { setGoogleToken, setGoogleTokenExpiredAt, setSubscriptionCallbackPath } from 'utils/localstore';
import { getOpsUserPermissions } from 'api/operation';
import AccessStore from 'store/Access/permission';
import { PermissionModuleKeys } from 'constants/access';
import useSyncAccess from 'hooks/useSyncAccess';
import AccessRoleStore from 'store/Access/role';
import { NotificationPlacement } from 'antd/es/notification/interface';
import GlobalNotificationStatusStore from 'store/GlobalNotification';
import s from './app.module.less';
import { redirecToLogout } from 'utils/redirecToLogout';
import GlobalLayer from 'components/GlobalLayer';
import useTryToLoad from 'hooks/useTryToLoad';
// import KlarityPlanModal from 'components/KlarityPlanModal';
import { openKlarityPlanAdvertisingModal, openKlaritySubscriptionModal } from 'utils/globalLayerControl';
import { SHOW_KLARITY_ADVERTISING_ICON } from 'constants/common';
import CommonStore from 'store/Common';
import SubscriptionStore from 'store/Subscription';

const RouteListenerLayout = ({ children }: { children?: React.ReactElement }) => {
    const { pathname } = useLocation();
    const urlMode = getUrlParam('mode')
    const localMode = localStorage.getItem('mode');
    const navigate = useNavigate();
    React.useEffect(() => {
        if ((isMobile() && localMode !== 'dev') && !supportsMobile.includes(pathname)) {
            navigate(PATH.MOBILE_NOT_SUPPORT);
        }
    }, [pathname]);

    React.useEffect(() => {
        window.navigate = navigate;
    }, []);

    React.useEffect(() => {
        if (urlMode === 'dev') {
            localStorage.setItem('mode', 'dev');
        } else if (urlMode === 'clear') {
            localStorage.removeItem('mode');
        }
    }, [urlMode]);

    return children ?? <Outlet />;
};

const Router = ({ access, roles }: { access: Record<PermissionModuleKeys, boolean>, roles: string[] }) => {
    const routers = useRoutes([
        {
            element: <RouteListenerLayout />,
            children: ROUTERS(access, roles),
        },
    ]);

    return routers;
};

const App = () => {
    const [getCommonStore, setCommonStore] = CommonStore.useStore();
    const [api, contextHolder] = notification.useNotification();
    const [, setGoogle] = GoogleStore.useStore();
    const [getAccessStore] = AccessStore.useStore();
    const access = getAccessStore('data') as Record<PermissionModuleKeys, boolean>;
    const [saveAccess, saveRole] = useSyncAccess();
    const [getRoleStore] = AccessRoleStore.useStore();
    const [getNotificationStore] = GlobalNotificationStatusStore.useStore();
    const showDeleteNotification = getNotificationStore('data');
    const roles = getRoleStore('data') as string[];
    const [getSubscription] = SubscriptionStore.useStore();
    const currentPlan = getSubscription('currentPlan');

    const handleDirectToLoginPage = useCallback((response?: any) => {
        // 一些请求写在了最外层的app.tsx里，导致login 页面也会发请求，这就意味着如果不加 !== '/', 这里会进入401死循环
        redirecToLogout();
        if (response) {
            const errorText = response.data?.message ?? response.data?.error;
            throw new Error(errorText ?? `${response.data.code} ${response.statusText}`);
        }
    }, []);

    const openNotification = useCallback((placement: NotificationPlacement) => {
        api.info({
            message: 'Authorization expired',
            duration: 4,
            description: (
                <>
                    <p className={s.loginDes}>Your login authorization has expired. Please log in again</p>
                    <div className={s.loginBtn} onClick={() => handleDirectToLoginPage()}>Go to login</div>
                </>
            ),
            placement,
        });
    }, [api, handleDirectToLoginPage]);

    const [loading] = useTryToLoad();

    useEffect(() => {
        if (showDeleteNotification) {
            openNotification('topRight');
        }
    }, [openNotification, showDeleteNotification]);

    const loadGsiScript = () => {
        const gsiSrc = 'https://accounts.google.com/gsi/client';
        const loadScript = (src: string) =>
            new Promise((resolve, reject) => {
                if (document.querySelector(`script[src="${src}"]`)) {
                    // eslint-disable-next-line no-promise-executor-return
                    return resolve(null);
                }
                const script = document.createElement('script');
                script.src = src;
                script.onload = () => resolve(null);
                script.onerror = (err) => reject(err);
                document.body.appendChild(script);
            });
        loadScript(gsiSrc)
            .then(() => {
                //google gsi issue
                const check = setInterval(() => {
                    const { google } = window as any;
                    if (google?.accounts) {
                        clearInterval(check);
                        init({
                            client_id: GOOGLE_CLIENT_ID,
                            scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/cloud-platform',
                            callback: (res: GoogleToken) => {
                                setGoogle('token', res.access_token);
                                setGoogleToken(res.access_token);
                                const expiredAt = (new Date()).getTime() + res.expires_in * 1000;
                                setGoogle('expiredAt', expiredAt);
                                setGoogleTokenExpiredAt(expiredAt);
                            },
                        });
                        setGoogle('clientReady', true);
                        setGoogle('calendarReady', true);
                    }
                }, 300);
            })
            .catch(console.error);
    };

    const loadOpsUserAccess = useCallback(async () => {
        if (isOpPortal()) {
            const res = await getOpsUserPermissions();

            if (!res?.error) {
                const privList = res?.data?.data?.privList || [];
                const role = res?.data?.data?.roles || [];

                saveAccess(privList);
                saveRole(role);
            } else if (res?.errorCode >= 4000 && res?.errorCode < 5000) {
                handleDirectToLoginPage(res);
            }
        }
    }, [handleDirectToLoginPage]);

    const basicAPICalling = useCallback(() => {
        const { pathname } = window.location;

        if (!withoutBasicAPICallingPath.includes(pathname)) {
            loadOpsUserAccess();
        }
    }, [loadOpsUserAccess]);

    React.useEffect(() => {
        if (loading === false) {
            loadGsiScript();
            basicAPICalling();
        }
    }, [basicAPICalling, loading]);

    const handleShowAdvertising = useCallback(() => {
        setSubscriptionCallbackPath('');
        openKlarityPlanAdvertisingModal('', () => openKlaritySubscriptionModal());
    }, []);

    const handleRemoveAdvertisingIcon = useCallback((e) => {
        e.stopPropagation();
        setSubscriptionCallbackPath('');
        localStorage.setItem(SHOW_KLARITY_ADVERTISING_ICON, '2');
        setCommonStore('showAdvertisingIcon', '2');
    }, [setCommonStore]);

    const showAdvertisingIconLS = localStorage.getItem(SHOW_KLARITY_ADVERTISING_ICON);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#006957',
                },
            }}
        >
            {contextHolder}
            <BrowserRouter>
                <Router access={access} roles={roles} />
                <GlobalLayer />
            </BrowserRouter>
            {
                (typeof loading === 'undefined' || loading) &&
                <div className={s.loadingWrap}>
                    <Spin spinning />
                </div>
            }
            {
                shouldShowAdvertisingIcon(currentPlan) &&
                <div className={s.advertisingIcon} onClick={handleShowAdvertising}>
                    <div className={s.closeIcon} onClick={handleRemoveAdvertisingIcon} />
                </div>
            }
        </ConfigProvider>
    );
};

export default App;
