/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import s from './s.module.less';
import { TKlarityProgramItemDetail, TProgramPlan } from 'types/common';
import { mockProgramPlans } from 'utils/mockData';

type IProps = {
    programData?: TKlarityProgramItemDetail;
    data?: TProgramPlan[];
};

const ProgramReview = (props: IProps) => {
    const { data = mockProgramPlans } = props;

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                Program overview
            </div>
            <div className={s.content}>
                {
                    data?.map((item, index) => {
                        const totalPrice = item.earnInit + item.earnFollowUp;
                        return (
                            <div className={s.item} key={item.title}>
                                <div className={s.itemTitle}>
                                    {item.title}
                                </div>
                                <div className={s.itemDes}>
                                    {item.des}
                                </div>
                                <div className={s.earn}>
                                    <div className={s.eTitle}>
                                        Your earnings
                                        <span className={s.label}>
                                            Limited time offer
                                        </span>
                                    </div>
                                    <div className={s.eDetail}>
                                        <div className={s.price}>${item.earnAll}</div>
                                        <div className={s.detail}>
                                            ${item.price} / month for the 3-month program
                                            {/* ${item.earnInit} (Initial visit) + ${item.earnFollowUp}  (3-month follow up) */}
                                        </div>
                                    </div>
                                </div>
                                <div className={s.resWrap}>
                                    <div className={s.resTitle}>{item.des2}:</div>
                                    <div className={s.resContent}>
                                        {
                                            item.res?.map((resItem, resIndex) => {
                                                const hasPoints = resItem.points && resItem.points.length > 0;
                                                return (
                                                    <div className={s.resItem} key={resIndex}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                            <path d="M5 13.4824L9 17.4824L19 7.48242" stroke="#10B981" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        {
                                                            hasPoints && (
                                                                <div className={s.resText}>
                                                                    <b>{resItem.label}:</b>
                                                                    <ul>
                                                                        {
                                                                            resItem.points?.map((point, pointIndex) => (
                                                                                <li key={pointIndex}>{point}</li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            !hasPoints && (
                                                                <div className={s.resText}>
                                                                    <b>{resItem.label}:</b>
                                                                    {resItem?.content}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default ProgramReview;
