import React, { useCallback, useEffect, useRef, useState } from 'react';
import s from './s.module.less';
import BookingLink from './components/BookingLink';
import UpdateUniprofile from './components/UpdateUniprofile';
import Previwe from './components/Preview';
import useGetBookingLinks from 'hooks/helloklarityOps/useGetBookingLinks';
import { generateUUID, getUrlParam } from 'utils/common';
import { Button, Spin, message } from 'antd';
import { EBookingListAction, IEditBookingLink } from './type';
import useFetchAuditDetailData from '../ListToChannelDetailPage/hooks/useFetchAuditDetailData';
import { EProviderListMappingStatusValue, EProviderMappingStatusValue, IHelloKlarityBookingLink, IHelloKlarityViewPostMessageData } from 'types/operation';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { helloklarityMappingGolive, updateHelloKlarityListingStatus, saveSpecialtyHighlight, getAppointmentsByBookingLinks, batchUpdateBookinglinks, pauseHelloKlarityListing } from 'api/operation';
import { getProviderNameDisplay, hasEffectiveMic } from 'utils/provider';
import { Provider } from 'types/provider';
import GlobalLayerStore from 'store/GlobalLayer';
import { getDraftData, removeDraftStorage, setDraftData } from 'utils/helloklarity';
import { EType, IDraftStorage } from 'types/helloKlarity';
import MappingModalConfirm from 'components/PorivderMappingModals/MappingModalConfirm';
import useLoadBasicData from 'hooks/useLoadBasicData';
import useGetSpecialtyNServiceType from 'hooks/helloklarityOps/useGetSpecialtyNServiceType';
import useDeleteOldPPPPreviewDraftData from 'hooks/useDeleteOldPPPPreviewDraftData';
import Program from './components/Program';
import useGetProviderPrograms from 'hooks/ProgramsOps/useGetProviderPrograms';
import { IKlarityProviderProgramEnroll } from 'types/programs';

const CustomizeHelloKlarityPPP = () => {
    const [, setGlobalLayer] = GlobalLayerStore.useStore();
    const navigate = useNavigate();
    const id = Number(getUrlParam('id'));
    const mappingStatus = getUrlParam('mappingStatus');
    const listStatus = getUrlParam('profileStatus');
    const conflict = getUrlParam('conflict');
    const providerId = Number(getUrlParam('providerId'));
    const slug = decodeURIComponent(getUrlParam('slug') || '');
    const [statusChangeLoading, setStatusChangeLoading] = useState(false);
    const [hightlights, setHighlights] = useState<string[]>([]);

    const [programs, programsLoading] = useGetProviderPrograms(providerId);

    const [pendingSubmitPrograms, setPendingSubmitPrograms] = useState<{ programId: number, activeStatus: "A" | "D", displayStatus: "A" | "D", isDeleted: boolean }[]>([]);

    const {
        data: providerData,
        refetch,
        loading: fetchLoading,
    } = useFetchAuditDetailData({
        channelId: -1,
        providerId,
    });

    useDeleteOldPPPPreviewDraftData(providerId);
    const [data, bookingListLoading, , bookingListrefetch] = useGetBookingLinks(providerId);

    const [bookingListData, setBookingListData] = useState<IEditBookingLink[]>([]);

    const [specialtiesIsEdting, setSpecialtiesIsEdting] = useState(false);
    const [edtingModules, setEditingModules] = useState({
        bookingLink: false,
        specialties: false,
    });
    const iframeEl = useRef<HTMLIFrameElement | null>(null);
    const pppTabIndexOffset = useRef(0);

    const { profile } = providerData;
    const providerName = getProviderNameDisplay(profile as Partial<Provider>);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const postMessageData = useRef<IHelloKlarityViewPostMessageData>({
        hightlights: [],
        appointmentList: [],
        hasStorage: false,
    });

    const [loadBaiscData] = useLoadBasicData();

    const [specialtyNServiceTypeData] = useGetSpecialtyNServiceType();

    useEffect(() => {
        loadBaiscData();
    }, [loadBaiscData]);

    const checkHasStoragePostMessage = useCallback(() => {
        const draftData: IDraftStorage = getDraftData(providerId);
        const hasStorage = !!(draftData?.[providerId]);
        postMessageData.current.hasStorage = hasStorage;

        return hasStorage;
    }, [providerId]);

    const handlePostMessage = useCallback(() => {
        if (iframeEl.current && iframeEl.current.contentWindow) {
            const { hightlights: hightlightsData, appointmentList } = postMessageData.current;

            iframeEl.current.contentWindow?.postMessage({
                hightlights: hightlightsData.join(','),
                appointmentList,
                hasStorage: checkHasStoragePostMessage(),
            }, '*');
        }
    }, [checkHasStoragePostMessage]);

    // const handleHighlightChangePostMessage = useCallback((v: string[]) => {
    //     if (iframeEl.current && iframeEl.current.contentWindow) {
    //         iframeEl.current.contentWindow?.postMessage({
    //             hightlights: v.join(','),
    //             hasStorage: postMessageData.current.hasStorage,
    //         }, '*');
    //     }
    // }, []);

    // const handleBookingLinkPostMessage = useCallback((v: TAppointment[]) => {
    //     if (iframeEl.current && iframeEl.current.contentWindow) {
    //         console.info(v, postMessageData.current.hasStorage);
    //         iframeEl.current.contentWindow?.postMessage({
    //             appointmentList: v,
    //             hasStorage: postMessageData.current.hasStorage,
    //         }, '*');
    //     }
    // }, []);

    const handleGetAppointmentsByBookingLinks = useCallback(async (listData: IEditBookingLink[]) => {
        const pramas = listData.filter((e) => !e.isEditing && e.status !== EBookingListAction.DELETE).map((e) => e.directBookingLink);
        if (pramas.length > 0) {
            try {
                const res = await getAppointmentsByBookingLinks(pramas);

                if (res && !res.error) {
                    const appointmentList = res.data?.data;
                    postMessageData.current.appointmentList = appointmentList;

                    handlePostMessage();
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }, [handlePostMessage]);

    useEffect(() => {
        // 因为booking link的请求异步，iframe 的html加载完毕也是异步。
        // 所以避免因为2者异步导致数据没有同步，所以以iframe为主，等html加载完，再进行booking link的解析
        if (iframeLoaded) {
            const draftData: IDraftStorage = getDraftData(providerId);
            const { bookingListData: bookingList, specialtiesHighlights } = draftData?.[providerId] || {};

            if (bookingList && bookingList.length > 0) {
                const draftBookingLinks = bookingList.map((e: IHelloKlarityBookingLink) => {
                    return {
                        ...e,
                        isEditing: false,
                        uuid: e.uuid || generateUUID(),
                        conditions: typeof e.conditions === 'string' ? e.conditions.split(',') : (e.conditions || []),
                    };
                });
                setBookingListData(draftBookingLinks);
                handleGetAppointmentsByBookingLinks(draftBookingLinks);
            } else if (data && data.length > 0) {
                const listData = data.map((e: IHelloKlarityBookingLink) => {
                    return {
                        ...e,
                        isEditing: false,
                        uuid: e.uuid || generateUUID(),
                        conditions: typeof e.conditions === 'string' ? e.conditions.split(',').filter((e) => !!e) : (e.conditions || []),
                    };
                });

                setBookingListData(listData);
            }

            if (specialtiesHighlights) {
                const draftSpecialtiesHighlights = specialtiesHighlights.split(',');

                postMessageData.current.hightlights = draftSpecialtiesHighlights;
                handlePostMessage();
            }
        }
    }, [data, handleGetAppointmentsByBookingLinks, handlePostMessage, iframeLoaded, providerId]);

    useEffect(() => {
        const bookingLinkIsEditing = bookingListData.find((e) => !!e.isEditing);
        setEditingModules({
            bookingLink: !!bookingLinkIsEditing,
            specialties: !!specialtiesIsEdting,
        });
    }, [bookingListData, specialtiesIsEdting]);

    // useEffect(() => {
    //     const draftData: IDraftStorage = {
    //         [providerId]: {
    //             bookingLink: bookingListData.filter((e) => e.directBookingLink),
    //             specialtiesHighlights: hightlights.join(','),
    //         },
    //     };

    //     setDraftData(draftData);
    // }, [bookingListData, hightlights, providerId]);

    const handleTabChange = useCallback((key: string) => {
        if (iframeEl.current && iframeEl.current.contentWindow) {
            const { hightlights: hightlightsData, appointmentList } = postMessageData.current;

            iframeEl.current.contentWindow?.postMessage({
                tabKey: key,
                hightlights: hightlightsData.join(','),
                appointmentList,
                hasStorage: checkHasStoragePostMessage(),
            }, '*');
        }
    }, [checkHasStoragePostMessage]);

    const setSavingDataToLocalstorage = useCallback((key: string, v: IEditBookingLink[] | string) => {
        let draftData = getDraftData(providerId);
        const defaultData = {
            bookingListData: [],
            specialtiesHighlights: '',
        };
        if (!draftData) {
            draftData = {
                [providerId]: defaultData,
            };
        }

        if (!draftData[providerId]) {
            draftData[providerId] = defaultData;
        }
        draftData[providerId][key] = v;

        setDraftData(draftData, providerId);
    }, [providerId]);

    const updateBookingListData = useCallback((newData: IEditBookingLink[], syncToHK = true) => {
        setBookingListData([
            ...newData,
        ]);
        setErrorMsg('');

        if (syncToHK) {
            setSavingDataToLocalstorage('bookingListData', newData);
            handleGetAppointmentsByBookingLinks(newData);
        }
    }, [handleGetAppointmentsByBookingLinks, setSavingDataToLocalstorage]);

    const handleSaveExit = useCallback(() => {
        if (Object.values(edtingModules).every((e) => !e)) {
            navigate(`${PATH.OPERATION}/${PATH.HELLO_KLARITY_PROVIDERS}?type=${EType.HELLOW_KLARITY}`);
        } else {
            message.error('Please cancel or save your changes before leaving');
        }
    }, [edtingModules, navigate]);

    const handleChangeListStatus = useCallback(async (status: EProviderListMappingStatusValue, callback: () => void) => {
        if (id) {
            setStatusChangeLoading(true);
            try {
                const res = await updateHelloKlarityListingStatus({
                    id,
                    listingStatus: status,
                });

                if (res && !res.error) {
                    callback?.();
                } else {
                    message.error(res.error);
                }
            } catch (e) {
                console.error(e);
            }
            setStatusChangeLoading(false);
        }
    }, [id]);

    const handleReject = useCallback(() => {
        handleChangeListStatus(EProviderListMappingStatusValue.DISQUALIFIED, () => {
            navigate(`${PATH.OPERATION}/${PATH.HELLO_KLARITY_PROVIDERS}`);
        });
    }, [handleChangeListStatus, navigate]);

    const handlePause = async () => {
        if (id) {
            setStatusChangeLoading(true);
            try {
                const res = await pauseHelloKlarityListing(id, pendingSubmitPrograms);

                if (res && !res.error) {
                    navigate(`/${PATH.HELLO_KLARITY_MAPPING_PAUSE_SUCCESS}?pn=${encodeURIComponent(providerName)}&id=${id}`);
                }
            } catch (e) {
                console.error(e);
            }
            setStatusChangeLoading(false);
        }
    };

    const showMappingModal = useCallback(() => {
        setGlobalLayer('showProviderMappingModal', true);
    }, []);

    const hideMappingModal = useCallback(() => {
        setGlobalLayer('showProviderMappingModal', false);
    }, []);

    // mapping Confirm modal
    const hideMappingConfirmModal = useCallback(() => {
        setGlobalLayer('showProviderMappingConfirmModal', false);
    }, []);

    const showMappingConfirmModal = useCallback(() => {
        setGlobalLayer('showProviderMappingConfirmModal', true);
    }, []);

    const handleBookingDataSave = useCallback(async () => {
        if (bookingListData && bookingListData.length > 0) {
            const pramas = bookingListData.map((item: IEditBookingLink) => {
                const { directBookingLink, serviceType, state, specialty, conditions, id: bookingLinkId, bookingLinkName, allCondition } = item;
                return {
                    bookingLinkName,
                    id: bookingLinkId,
                    bpProviderId: providerId,
                    directBookingLink,
                    serviceType,
                    state,
                    specialty,
                    conditions: conditions instanceof Array ? conditions.join(',') : '',
                    allCondition: !!allCondition,
                };
            }, []);
            const res = await batchUpdateBookinglinks(pramas);

            if (res?.error) {
                message.error(res?.error);
            }
        }
    }, [bookingListData, providerId]);

    const handleSaveHighlight = useCallback(() => {
        return saveSpecialtyHighlight({
            providerId,
            specialtyHighlight: hightlights.join(','),
        });
    }, [hightlights, providerId]);

    const submitGolive = useCallback(async () => {
        if (id) {
            setStatusChangeLoading(true);
            try {
                const draftData = getDraftData(providerId);
                if (draftData) {
                    const saveRes = await Promise.all([
                        handleBookingDataSave(),
                        handleSaveHighlight(),
                    ]);

                    if (saveRes.find((e) => e && e.error)) {
                        saveRes.forEach((e) => {
                            if (e.error) {
                                message.error(e.error);
                            }
                        });

                        return;
                    }
                }

                const goliveRes = await helloklarityMappingGolive({
                    id,
                    programList: pendingSubmitPrograms
                });

                if (goliveRes && !goliveRes.error) {
                    removeDraftStorage(providerId);
                    hideMappingConfirmModal();
                    navigate(`/${PATH.HELLO_KLARITY_MAPPING_GOLIVE_SUCCESS}?pn=${encodeURIComponent(providerName)}&id=${id}`);
                } else {
                    message.error(goliveRes.error);
                }

                // if (saveRes.every((e) => e && !e.error)) {
                //     const goliveRes = await helloklarityMappingGolive(id);

                //     if (goliveRes && !goliveRes.error) {
                //         removeDraftStorage();
                //         hideMappingModal();
                //         navigate(`/${PATH.HELLO_KLARITY_MAPPING_GOLIVE_SUCCESS}?pn=${encodeURIComponent(providerName)}&id=${id}`);
                //     } else {
                //         message.error(goliveRes.error);
                //     }
                // } else {
                //     saveRes.forEach((e) => {
                //         if (e.error) {
                //             message.error(e.error);
                //         }
                //     });
                // }
            } catch (e) {
                console.error(e);
            }
            setStatusChangeLoading(false);
        }
    }, [handleBookingDataSave, handleSaveHighlight, hideMappingConfirmModal, id, navigate, providerId, providerName, pendingSubmitPrograms]);

    const handleGolive = useCallback(() => {
        if (!bookingListData || bookingListData.length === 0) {
            setErrorMsg('Please enter at least one direct booking link');
            return;
        } else {
            setErrorMsg('');
        }
        if (Object.values(edtingModules).every((e) => !e)) {
            if (conflict === 'true' && mappingStatus !== EProviderMappingStatusValue.EHR_MAPPED) {
                showMappingConfirmModal();
            } else {
                submitGolive();
            }
        } else {
            message.error('Please cancel or save your changes before leaving');
        }
    }, [bookingListData, edtingModules, conflict, mappingStatus, showMappingConfirmModal, submitGolive]);

    React.useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event?.data?.mounted) {
                setIframeLoaded(true);
            }
        });
    }, []);
    // const handleIFrameLoaded = useCallback(() => {
    //     setIframeLoaded(true);
    //     // if (postMessageData.current.appointmentList && postMessageData.current.appointmentList.length > 0) {
    //     //     handleBookingLinkPostMessage(postMessageData.current.appointmentList);
    //     // }

    //     // if (postMessageData.current.hightlights) {
    //     //     handleHighlightChangePostMessage(postMessageData.current.hightlights);
    //     // }
    // }, []);

    const setIframeEl = useCallback((el: HTMLIFrameElement) => {
        iframeEl.current = el;
    }, []);

    const handlePlatformChange = useCallback((platform: string) => {
        pppTabIndexOffset.current = platform === 'mobile' ? 1 : 0;
    }, []);

    // const handleIframeReload = useCallback(() => {
    //     if (iframeEl.current) {
    //         iframeEl.current.src += '';
    //     }
    // }, []);

    const handleSaveSuccess = useCallback((v: string[]) => {
        setHighlights(v);
        setSavingDataToLocalstorage('specialtiesHighlights', v ? v.join(',') : '');
        postMessageData.current.hightlights = v;
        handlePostMessage();
        // handleIframeReload();
    }, [handlePostMessage, setSavingDataToLocalstorage]);

    const providerHasEffectiveMic = hasEffectiveMic(providerData);

    return (
        <Spin spinning={!iframeLoaded || fetchLoading || bookingListLoading || statusChangeLoading || programsLoading}>
            <div className={s.wrap}>
                <MappingModalConfirm
                    type={EType.HELLOW_KLARITY}
                    onOk={submitGolive}
                    onCancel={hideMappingConfirmModal}
                />
                {/* <PorivderMappingModal
                    row={mappingModalData}
                    onOk={submitGolive}
                    onCancel={hideMappingModal}
                /> */}
                <div className={s.left}>
                    <div className={s.providerName}>{providerName}</div>
                    <BookingLink
                        specialtyNServiceTypeData={specialtyNServiceTypeData}
                        providerId={providerId}
                        data={bookingListData}
                        updateBookingListData={updateBookingListData}
                        errorMsg={errorMsg}
                    // refetch={bookingListrefetch}
                    />
                    <Program programs={programs} setPendingSubmitPrograms={setPendingSubmitPrograms} />
                    {
                        !providerHasEffectiveMic && providerData &&
                        <div className={s.micTips}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.25803 3.09956C9.02264 1.74025 10.9797 1.74025 11.7443 3.09956L17.3246 13.0201C18.0746 14.3533 17.1111 16.0006 15.5815 16.0006H4.42088C2.89123 16.0006 1.9278 14.3533 2.67773 13.0201L8.25803 3.09956ZM11.0011 13.0007C11.0011 13.553 10.5534 14.0007 10.0011 14.0007C9.44881 14.0007 9.0011 13.553 9.0011 13.0007C9.0011 12.4484 9.44881 12.0007 10.0011 12.0007C10.5534 12.0007 11.0011 12.4484 11.0011 13.0007ZM10.0011 5.00073C9.44881 5.00073 9.0011 5.44845 9.0011 6.00073V9.00073C9.0011 9.55302 9.44881 10.0007 10.0011 10.0007C10.5534 10.0007 11.0011 9.55302 11.0011 9.00073V6.00073C11.0011 5.44845 10.5534 5.00073 10.0011 5.00073Z" fill="#FBBF24" />
                                </svg>
                            </span>
                            <div className={s.right}>
                                <div className={s.title}>Booking widget disabled due to missing malpractice insurance</div>
                                <div className={s.subTitle}>To enable the booking functionality for this provider, please work with the provider to add the malpractice insurance,</div>
                            </div>
                        </div>
                    }
                    <UpdateUniprofile
                        onTabChange={handleTabChange}
                        id={id}
                        setSpecialtiesIsEdting={setSpecialtiesIsEdting}
                        specialtiesIsEdting={specialtiesIsEdting}
                        providerData={providerData}
                        refetch={refetch}
                        onSaveSuccess={handleSaveSuccess}
                    />
                </div>
                <div className={s.right}>
                    <Previwe
                        // onLoad={handleIFrameLoaded}
                        providerId={providerId}
                        handlePlatformChange={handlePlatformChange}
                        setIframeEl={setIframeEl}
                        slug={slug}
                        postMessageData={postMessageData.current}
                    />
                </div>
                <div className={s.footer}>
                    <div className={s.exit} onClick={handleSaveExit}>Save & exit</div>
                    <div className={s.rightBtns}>
                        {listStatus !== EProviderListMappingStatusValue.LISTED && <div className={s.reject} onClick={handleReject}>Disqualify</div>}
                        {
                            (listStatus === EProviderListMappingStatusValue.LISTED || listStatus === EProviderListMappingStatusValue.PENDING_REVIEW) &&
                            <div
                                onClick={handlePause}
                                className={s.pause}>Pause Listing (Preferred)</div>
                        }
                        {listStatus === EProviderListMappingStatusValue.LISTED ? <Button type="primary" onClick={handleGolive}>Publish changes</Button> : <Button type="primary" onClick={handleGolive}>Go live</Button>}
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default CustomizeHelloKlarityPPP;
