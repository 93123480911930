import React, { useEffect, useMemo } from 'react';
import s from './s.module.less';
import { Spin, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import MyAccount from './components/MyAccount';
import SubscriptionInfo from './components/SubscriptionInfo';
//import SubscriptionInfo from 'components/Subscription'
import KlarityPlanSubscriptionInfo from './components/KlarityPlanSubscriptionInfo';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import { shouldShowKlarityPlanUI } from 'utils/provider';
import ReferNRewards from 'components/ReferNRewards';
import useGetProviderReferralInfo from 'hooks/referral/useGetProviderReferralInfo';

interface IProps {
    tabKey?: string;
}

const ManageSubscription = ({ tabKey }: IProps) => {
    const [homeInfo, loadingHome] = useProviderHomeInfo();
    const showKlarityPlanUI = shouldShowKlarityPlanUI(homeInfo);
    const [activeKey, setActiveKey] = React.useState<string>(tabKey || '2');

    const [providerReferralInfo, referalLoading] = useGetProviderReferralInfo();

    useEffect(() => {
        if (tabKey) {
            setActiveKey(tabKey);
        }
    }, [tabKey]);

    const items = useMemo<TabsProps['items']>(() => {
        const res = [
            {
                key: '1',
                label: 'My account',
                children: <MyAccount />,
            },
            {
                key: '2',
                label: 'Subscription',
                children: showKlarityPlanUI ? <KlarityPlanSubscriptionInfo /> : <SubscriptionInfo />,
            },
        ];

        if (!showKlarityPlanUI) {
            res.push({
                key: '3',
                label: 'Refer and rewards',
                children: <ReferNRewards />,
            });
        }

        return res;
    }, [showKlarityPlanUI]);

    return (
        <div className={s.wrap}>
            <Spin spinning={loadingHome || referalLoading}>
                <div className={s.content}>
                    <div className={s.contentInner}>
                        <Tabs activeKey={activeKey} items={items} onChange={(e) => setActiveKey(e)} />
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default ManageSubscription;
