import Logo from 'assets/campain/2024xmax/mainLogo.svg';

const HomeBannerBackground = require('assets/campain/2024xmax/homeBanner.png');
const ModalBackground = require('assets/campain/2024xmax/modalBanner.png');
const homeIcon = require('assets/campain/2024xmax/homeIcon.svg');
const modalIcon = require('assets/campain/2024xmax/modalIcon.svg');

const caFireHomeIcon = require('assets/campain/caFireWild/homeIcon.png');

const CampaignConfig = {
    'campaign-xmas-2024': {
        isCampaign: true,
        mainLogo: {
            logo: Logo,
        },
        upgradeTextInMainMenu: {
            text: '🎅🏻 Unlock your best Christmas Deal: Save up to [35%] on Kiwi Health Plans!',
        },
        homepageBanner: {
            wrap: {},
            leftPart: {
                paddingLeft: '184px',
            },
            icon: {
                url: homeIcon,
                width: '153px',
                height: '92px',
                left: '20px',
            },
            limitationBackground: 'linear-gradient(90deg, #F1CDA5 0%, #D09D64 100%)',
            limitationColor: '#04291E',
            titleColor: '#FFFFFF',
            descriptionColor: '#FFFFFF',
            background: `url(${HomeBannerBackground}) no-repeat center center`,
            JoinButton: {
                background: 'linear-gradient(90deg, #F1CDA5 0%, #D09D64 100%)',
                color: '#15191E',
                isLink: false,
                link: '',
            },
            joinedButton: {
                background: '#FFFFFF',
                color: '#15191E',
                text: 'Joined',
                isLink: false,
                link: '',
            },
            leaveButton: {
                background: '#F1CDA5',
                color: '#15191E',
                text: 'Leave Campaign',
            },
            showJoinedCount: false,
        },
        SelectionPopup: {
            icon: {
                url: modalIcon,
                width: '192px',
                height: '192px',
            },
            limitationBackground: 'linear-gradient(90deg, #E94C20 0%, #FDB160 109.71%)',
            selectionAreaBackground: `url(${ModalBackground}) no-repeat center center`,
        },
        joinedSuccessMessage: {
            text: '🎉 Congratulations! You’ve successfully joined the Christmas campaign, offering {} off on all your initial visits.',
        },
        leaveCampaign: {
            title: 'Leave the campaign?',
            okText: 'Leave',
            cancelText: 'Cancel',
            footer: undefined,
            tipText: 'Are you sure you want to leave the Holiday Season Sale campaign? By opting out, your discounts will no longer be available to patients, and you may miss out on increased bookings during this limited-time event.',
        },
    },
    'la-wildfire-2025': {
        isCampaign: false,
        mainLogo: {
            logo: Logo,
        },
        homepageBanner: {
            wrap: {},
            leftPart: {
                paddingLeft: '184px',
            },
            icon: {
                url: caFireHomeIcon,
                width: '144px',
                height: '109px',
                left: '20px',
            },
            limitationBackground: 'linear-gradient(90deg, #2B9EF6 0%, #1684D8 100%)',
            limitationColor: '#FFFFFF',
            titleColor: '#141736',
            descriptionColor: '#374151',
            background: 'linear-gradient(277.91deg, #87C9FF 11.82%, #D9FCFF 58.22%, #C7EEFF 96.35%)',
            JoinButton: {
                background: '#FFFFFF',
                color: '#15191E',
                isLink: true,
                link: '/create-uni-profile?type=uniprofile&step=7&specialty=telehealth%20visit%20%26%20prescription%20refill',
            },
            joinedButton: {
                background: '#FFFFFF',
                color: '#15191E',
                text: 'Joined',
                isLink: false,
                link: '',
            },
            leaveButton: {
                background: '#FFFFFF',
                color: '#DC2626',
                text: 'Leave the program',
            },
            showJoinedCount: true,
        },
        SelectionPopup: {
            icon: {
                url: modalIcon,
                width: '192px',
                height: '192px',
            },
            limitationBackground: 'linear-gradient(90deg, #E94C20 0%, #FDB160 109.71%)',
            selectionAreaBackground: `url(${ModalBackground}) no-repeat center center`,
        },
        joinedSuccessMessage: {
            text: '🎉 Congratulations! You’ve successfully joined the Christmas campaign, offering {} off on all your initial visits.',
        },
        leaveCampaign: {
            title: 'Leave the program?',
            okText: null,
            cancelText: null,
            footer: null,
            tipText: 'If you’d like to leave the LA wildfire telehealth program, please contact our support team at <a style="color: #006957" target="_blank" href="mailto:providersupport@helloklarity.com">providersupport@helloklarity.com</a>. By opting out, your $28 telehealth visit discount will no longer be available to patients, and your profile will be removed from the program’s search results. You may miss out on increased bookings and enhanced visibility.',
        },
    },
};

export default CampaignConfig;
