import React from 'react';
import s from './s.module.less';

const NonReferralData = () => {
    return (
        <div className={s.wrap}>
            <div className={s.icon} />
            <div className={s.content}>
                <div className={s.title}>Referral program unavailable</div>
                <div className={s.desc}>At this time, we do not have an active referral program available. We appreciate your interest in referring new providers to join Kiwi Health and encourage you to check back periodically for any updates or new opportunities.</div>
            </div>
        </div>
    );
};

export default NonReferralData;
