import { ServerKlarityService } from 'types/common';

export enum EModalMode {
    SELECT_SPECIALTY = 'SELECT_SPECIALTY',
    CONFIG_PROGRAM_NOTE = 'CONFIG_PROGRAM_NOTE',
    CONFIG_PROGRAM = 'CONFIG_PROGRAM',
    SIGN_PROGRAM = 'SIGN_PROGRAM',
    CONFIG_SERVICE = 'CONFIG_SERVICE',
}

export type ServerData = {
    providerKlarityService: ServerKlarityService[],
    disableIds: number[],
    allSeted: boolean,
};
