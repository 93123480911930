import React, {
    useState,
    useEffect,
} from 'react';
import s from './s.module.less';
import { message } from 'antd';
import type { TAssessmentItem, TResult, TPreviewResult } from 'types/operation';
import {
    getAssessmentResultLevel,
} from 'api/operation/assessment';
import cx from 'classnames';
import ResultsReviewItem from '../ResultsReviewItem';
import { getUrlParam } from 'utils/common';

type IProps = {
    item: TAssessmentItem;
    onPreview: (previewItem: TPreviewResult) => void;
    onSuccess: (obj: Partial<TAssessmentItem>) => void;
};

const cardTitle = [
    'Scenario 1',
    'Scenario 2',
    'Scenario 3',
];

const ResultReviews: React.FC<IProps> = (props) => {
    const { item, onPreview, onSuccess } = props;
    const [list, setList] = useState<TResult[]>(
        [] as TResult[],
    );
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const getList = async () => {
        const { data } = await getAssessmentResultLevel({
            assessmentId: item.id || '',
        });
        setList(data.data);
        if (data.data.length > 0) {
            onPreview({
                ...data?.data?.[activeIndex],
                type: 'ResultsReview',
            });
        }
    };

    const handlePreviewItemClick = (index: number) => {
        setActiveIndex(index);
        onPreview({
            ...list[index],
            type: 'ResultsReview',
        });
    };

    const handleUpdateSuccess = () => {
        getList();
        message.success('updated successfully');
    };

    useEffect(() => {
        if (item.id) {
            getList();
        }
    }, [item]);

    return (
        <div className={s.wrap}>
            <div className={s.headerBar}>
                <h4 className={s.subtitle}>Results widget review</h4>
            </div>
            <div className={s.previewList}>
                {(list || []).map((listItem: TResult, index) => {
                    return (
                        <div
                            className={cx(
                                s.previewItem,
                                index === activeIndex ? s.active : '',
                            )}
                            key={listItem.id}
                            onClick={() => handlePreviewItemClick(index)}
                        >
                            {cardTitle[index]}
                        </div>
                    );
                })}
            </div>
            <div className={s.contentWrap}>
                {(list || []).map((listItem, index) => {
                    return (
                        <ResultsReviewItem
                            item={listItem}
                            index={index}
                            onSuccess={() => handleUpdateSuccess()}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ResultReviews;
