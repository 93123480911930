import React, { useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { EProviderListMappingStatusValue } from 'types/operation';
import { Meta } from 'types/common';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';

interface IProps {
    status: string;
}

const OpsProviderMappingListStatusTag = ({
    status = '',
}: IProps) => {
    const [settingData] = useGetSettingData(EnumFields.PROVIDER_MAPPING_LIST_STATUS, true) as (Meta[])[];
    const [listStatus, setListStatus] = useState<Meta>();
    const [providerMappingListStatus, setProviderMappingListStatus] = useState<Meta[]>([]);
    const [done, setDone] = useState(false);

    useEffect(() => {
        if (!done) {
            setProviderMappingListStatus(settingData);
        }
    }, [done, settingData]);

    useEffect(() => {
        const res = providerMappingListStatus?.find((e) => e.value === status);
        setListStatus(res);
        setDone(true);
    }, [providerMappingListStatus, status]);

    const statusClass = useMemo(() => {
        let cls = '';
        switch (listStatus?.value) {
            case EProviderListMappingStatusValue.LISTED:
                cls = s.approved;
                break;
            case EProviderListMappingStatusValue.DISQUALIFIED:
                cls = s.rejected;
                break;
            case EProviderListMappingStatusValue.UNIPROFILE_NEEDED:
                cls = s.followup;
                break;
            case EProviderListMappingStatusValue.PENDING_REVIEW:
                cls = s.pending;
                break;
            case EProviderListMappingStatusValue.PAUSE_LISTING:
                cls = s.pause;
                break;
            case EProviderListMappingStatusValue.UNLISTED:
                cls = '';
                break;
            default: cls = s.rejected;
        }

        return `${s.status} ${cls}`;
    }, [listStatus]);

    return <div className={statusClass}>{listStatus?.label}</div>;
};

export default OpsProviderMappingListStatusTag;
