import { Button } from 'antd';
import InfoNote from 'components/InfoNote';
import React, { useEffect, useState } from 'react';
import { ProviderVideo } from 'types/provider';
import s from './s.module.less';
import UploadVideoModal from './components/UploadVideoModal';
import DefaultCover from './imgs/default.png';

interface IProps {
    max: number;
    value?: Array<ProviderVideo>;
    enable: boolean;
    valid?: boolean;
    validFun?: (video: ProviderVideo, inx: number) => boolean
    errorMessage?: string;
    onChange?: (videos: Array<ProviderVideo>) => void,
    defaultCover?: string;
    isOps?: boolean;
    providerId?: number;
}

const VideoList = ({
    max = 3,
    value,
    enable = false,
    valid,
    validFun,
    errorMessage,
    defaultCover,
    onChange,
    isOps,
    providerId,
}: IProps) => {
    const [videos, setVideos] = useState<Array<ProviderVideo>>([]);
    const [showUploadModal, setShowUploadModal] = useState(false);

    const checkVideoList = (videosData: Array<ProviderVideo>) => {
        const existVideos = videosData.filter((video) => !!video.url);
        return existVideos;
    };

    const handleReturnResponse = (videosData: Array<ProviderVideo>) => {
        if (typeof onChange === 'function') {
            const submitData = videosData.filter((video) => !!video.url);
            onChange(submitData);
        }
    };

    const removeVideoByIndex = (index: number) => {
        videos.splice(index, 1);
        const newVideos = checkVideoList(videos);
        setVideos(newVideos);
        handleReturnResponse(newVideos);
    };

    const handleSetDefault = (index: number) => {
        const newVideos = checkVideoList(videos?.map((video, i) => {
            if (i === index) {
                return { ...video, isDefault: true };
            } else {
                return { ...video, isDefault: false };
            }
        }));

        setVideos(newVideos);
        handleReturnResponse(newVideos);
    };

    const handleDownload = (video: ProviderVideo) => {
        window.open(video.url, '_blank');
    };

    useEffect(() => {
        setVideos(checkVideoList(value || []));
    }, [value]);

    const showNote = videos && videos.length > 1;
    const isEmpty = videos.length === 0;
    const amount = videos?.length || 0;
    return (
        <div className={`${s.wrap} ${isEmpty ? s.wrapEmpty : ''} ${enable ? s.enable : s.wrapDisable}`}>
            {
                showNote && (
                    <div className={s.note}>
                        <InfoNote
                            content="You’ve uploaded multiple videos. Select one as your default introduction video, which will be the first one patients see on your profile."
                        />
                    </div>
                )
            }
            <div className={s.videoList}>
                {
                    videos.map((video, index) => {
                        const isDefault = videos?.length === 1 ? true : video.isDefault;
                        return (
                            <div key={video.url} className={s.videoItem}>
                                <div className={s.imgWrap}>
                                    <img className={`${s.placeholder}`} src={video.cover || DefaultCover} />
                                </div>
                                <div className={s.maskWrap}>
                                    <div className={s.btnWrap}>
                                        {
                                            isDefault &&
                                            <div className={s.default}>
                                                Default video
                                            </div>
                                        }
                                        {
                                            !isDefault &&
                                            <Button
                                                size="small"
                                                onClick={() => {
                                                    handleSetDefault(index);
                                                }}
                                                className={s.btn}
                                                type="primary"
                                            >
                                                Set as default
                                            </Button>
                                        }
                                        <span
                                            onClick={() => {
                                                removeVideoByIndex(index);
                                            }}
                                            className={s.remove}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M9 2.5C8.62123 2.5 8.27497 2.714 8.10557 3.05279L7.38197 4.5H4C3.44772 4.5 3 4.94772 3 5.5C3 6.05228 3.44772 6.5 4 6.5L4 16.5C4 17.6046 4.89543 18.5 6 18.5H14C15.1046 18.5 16 17.6046 16 16.5V6.5C16.5523 6.5 17 6.05228 17 5.5C17 4.94772 16.5523 4.5 16 4.5H12.618L11.8944 3.05279C11.725 2.714 11.3788 2.5 11 2.5H9ZM7 8.5C7 7.94772 7.44772 7.5 8 7.5C8.55228 7.5 9 7.94772 9 8.5V14.5C9 15.0523 8.55228 15.5 8 15.5C7.44772 15.5 7 15.0523 7 14.5V8.5ZM12 7.5C11.4477 7.5 11 7.94772 11 8.5V14.5C11 15.0523 11.4477 15.5 12 15.5C12.5523 15.5 13 15.0523 13 14.5V8.5C13 7.94772 12.5523 7.5 12 7.5Z" fill="#111827" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div
                                        onClick={() => {
                                            handleDownload(video);
                                        }}
                                        className={s.download}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <path d="M4 16.7734L4 17.7734C4 19.4303 5.34315 20.7734 7 20.7734L17 20.7734C18.6569 20.7734 20 19.4303 20 17.7734L20 16.7734M16 12.7734L12 16.7734M12 16.7734L8 12.7734M12 16.7734L12 4.77344" stroke="#00816B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        Download
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                {
                    amount < max && (
                        <div className={s.uploadBtnWrap}>
                            <Button
                                className={s.uploadBtn}
                                size="large"
                                onClick={() => {
                                    setShowUploadModal(true);
                                }}
                            >
                                Upload
                            </Button>
                        </div>
                    )
                }
            </div>
            <UploadVideoModal
                isOps={isOps}
                providerId={providerId}
                show={showUploadModal}
                onCancel={() => {
                    setShowUploadModal(false);
                }}
                onSuccess={(url: string, cover: string) => {
                    const isDefault = videos.length === 0;
                    const newVideos = videos.concat([{ url, cover, isDefault }]);
                    setVideos(newVideos);
                    handleReturnResponse(newVideos);
                    setShowUploadModal(false);
                }}
            />
        </div>
    );
};

export default VideoList;
