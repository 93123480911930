import GlobalLayerStore from 'store/GlobalLayer';
import { Channel } from 'types/common';
import { setPendingSubscriptionChannel, setPendingSubscriptionPlan, setSubscriptionCallbackPath, setSubscriptionCallbackShowSuccess } from './localstore';
import SubscriptionStore from 'store/Subscription';
import { CouponType } from 'types/provider';
import { setShowKiwiPlanAdvertise } from './sessionStorage';
import { ICampaign } from 'types/campaign';

const getPath = () => {
    let path = window.location.pathname;
    if (window.location.search) {
        path += window.location.search;
    }
    return path;
};

export const openSubscriptionModal = (showSuccessModal: boolean = true, path?: string) => {
    if (showSuccessModal) {
        setSubscriptionCallbackShowSuccess(true);
    }
    setShowKiwiPlanAdvertise(false);
    const callbackPath = path || getPath();
    setSubscriptionCallbackPath(callbackPath);
    //clear channel info
    setPendingSubscriptionChannel(undefined);
    GlobalLayerStore.set('pendingSubscriptionChannel', undefined);
    GlobalLayerStore.set('showSubscriptionModal', true);
};

export const closeSubscriptionModal = () => {
    setSubscriptionCallbackShowSuccess(false);
    GlobalLayerStore.set('showSubscriptionModal', false);
};

export const openManageSubscriptionModal = (tabKey?: string) => {
    setShowKiwiPlanAdvertise(false);
    setSubscriptionCallbackShowSuccess(true);
    const params = {
        show: true,
        tabKey,
    };
    GlobalLayerStore.set('showManageSubscriptionModal', params);
};

export const closeManageSubscriptionModal = () => {
    setSubscriptionCallbackShowSuccess(false);
    const params = {
        show: false,
    };
    GlobalLayerStore.set('showManageSubscriptionModal', params);
};

export const openSubscriptionSuccessModal = () => {
    GlobalLayerStore.set('showSubscriptionSuccess', true);
};

export const closeSubscriptionSuccessModal = () => {
    GlobalLayerStore.set('showSubscriptionSuccess', false);
};

export const openSubscriptionTalkModal = () => {
    GlobalLayerStore.set('showSubscriptionTalk', true);
};

export const closeSubscriptionTalkModal = () => {
    GlobalLayerStore.set('showSubscriptionTalk', false);
};

export const openChannelSubscriptionModal = ({ showSuccessModal = true, channel }: { showSuccessModal?: boolean, channel: Channel }) => {
    if (showSuccessModal) {
        setSubscriptionCallbackShowSuccess(true);
    }
    setSubscriptionCallbackPath(getPath());
    setPendingSubscriptionPlan(undefined);
    GlobalLayerStore.set('pendingSubscriptionChannel', channel);
    GlobalLayerStore.set('showChannelSubscriptionModal', true);
};

export const closeChannelSubscriptionModal = () => {
    setSubscriptionCallbackShowSuccess(false);
    GlobalLayerStore.set('showChannelSubscriptionModal', false);
};

export const openUniprofileAgreementSignModal = (title: string, loopFetch: boolean) => {
    const payPlan = SubscriptionStore.get('currentPlan');

    if (payPlan?.couponType !== CouponType.HEADWAY) {
        GlobalLayerStore.set('showUniprofileAgreementSignModal', {
            show: true,
            title,
            loopFetch,
        });
    }
};

export const closeUniprofileAgreementSignModal = () => {
    GlobalLayerStore.set('showUniprofileAgreementSignModal', {
        show: false,
        title: '',
        loopFetch: false,
    });
};

export const openKlaritySubscriptionModal = (showSuccessModal: boolean = true, path?: string) => {
    if (showSuccessModal) {
        setSubscriptionCallbackShowSuccess(true);
    }

    const callbackPath = path || getPath();
    setSubscriptionCallbackPath(callbackPath);
    GlobalLayerStore.set('showKlaritySubscriptionModal', true);
};

export const closeKlaritySubscriptionModal = () => {
    setSubscriptionCallbackShowSuccess(false);
    GlobalLayerStore.set('showKlaritySubscriptionModal', false);
};

export const openKlarityPlanAdvertisingModal = (paySuccessPath?: string, callback?: (v?: any) => void) => {
    GlobalLayerStore.set('showKlarityPlanAdvertising', {
        show: true,
        callback,
        paySuccessPath,
    });
};

export const closeKlarityPlanAdvertisingModal = () => {
    GlobalLayerStore.set('showKlarityPlanAdvertising', {
        show: false,
    });
};

export const closeKiwiPlanAdvertisingModal = () => {
    setSubscriptionCallbackShowSuccess(false);
    GlobalLayerStore.set('showKiwiPlanAdvertisingModal', false);
};

export const openKiwiPlanAdvertisingModal = (showSuccessModal: boolean = true, path?: string) => {
    if (showSuccessModal) {
        setSubscriptionCallbackShowSuccess(true);
    }
    setShowKiwiPlanAdvertise(false);
    const callbackPath = path || getPath();
    setSubscriptionCallbackPath(callbackPath);
    //clear channel info
    setPendingSubscriptionChannel(undefined);
    GlobalLayerStore.set('pendingSubscriptionChannel', undefined);
    GlobalLayerStore.set('showKiwiPlanAdvertisingModal', true);
};

export const showBlackFriday2024EnhancementModal = () => {
    GlobalLayerStore.set('showBlackFriday2024EnhancementModal', true);
};

export const closeBlackFriday2024EnhancementModal = () => {
    GlobalLayerStore.set('showBlackFriday2024EnhancementModal', false);
};

export const showBlackFriday2024JoinCampaignModal = () => {
    GlobalLayerStore.set('showBlackFriday2024JoinCampaignModal', true);
};

export const closeBlackFriday2024JoinCampaignModal = () => {
    GlobalLayerStore.set('showBlackFriday2024JoinCampaignModal', false);
};

export const showBlackFriday2024LeaveCampaignModal = () => {
    GlobalLayerStore.set('showBlackFriday2024LeaveCampaignModal', true);
};

export const closeBlackFriday2024LeaveCampaignModal = () => {
    GlobalLayerStore.set('showBlackFriday2024LeaveCampaignModal', false);
};

export const showTermsNConditionsModal = () => {
    GlobalLayerStore.set('showTermsNConditionsModal', true);
};

export const closeTermsNConditionsModal = () => {
    GlobalLayerStore.set('showTermsNConditionsModal', false);
};

export const showCCCTermsNConditionsModal = () => {
    GlobalLayerStore.set('showCCCTermsNConditionsModal', true);
};

export const closeCCCTermsNConditionsModal = () => {
    GlobalLayerStore.set('showCCCTermsNConditionsModal', false);
};

/* Campaign Modal Template start */

export const showCampaignModal = () => {
    GlobalLayerStore.set('showCampaignModal', true);
};

export const closeCampaignModal = () => {
    GlobalLayerStore.set('showCampaignModal', false);
};

export const showLeaveCampaignModal = () => {
    GlobalLayerStore.set('showLeaveCampaignModal', true);
};

export const closeLeaveCampaignModal = () => {
    GlobalLayerStore.set('showLeaveCampaignModal', false);
};

export const showCampaignTermsNConditionsModal = () => {
    GlobalLayerStore.set('showCampaignTermsNConditionsModal', true);
};

export const closeCampaignTermsNConditionsModal = () => {
    GlobalLayerStore.set('showCampaignTermsNConditionsModal', false);
};

/* Campaign Modal Template end */
