import React, { useCallback } from 'react';
import s from './s.module.less';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import SwiperCore, {
    Navigation,
    Pagination,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import KiwiReferralBanner from './campains/KiwiReferralBanner';
import Blackfriday2024 from './campains/blackfriday2024';
import { ICampaign } from 'types/campaign';
import CampaignTemplate from './campains/campaignTemplate';

SwiperCore.use([Navigation]);

interface IProps {
    campains?: ICampaign[]
}

const PreviousBtn = () => {
    const swiper = useSwiper();
    const handleSwiperPrevious = useCallback(() => {
        swiper.slidePrev();
    }, [swiper]);

    return <div className={s.previousBtn} onClick={handleSwiperPrevious} />;
};

const NextBtn = () => {
    const swiper = useSwiper();
    const handleSwiperNext = useCallback(() => {
        swiper.slideNext();
    }, [swiper]);

    return <div className={s.nextBtn} onClick={handleSwiperNext} />;
};

const ProviderCampainHeader = ({
    campains,
}: IProps) => {
    return (
        <div className={s.wrap}>
            <Swiper
                modules={[Navigation, Pagination]}
                pagination={{ clickable: true, horizontalClass: s.pagination }}
            >
                {
                    campains && campains.length > 1 && (
                        <>
                            <PreviousBtn />
                            <NextBtn />
                        </>
                    )
                }
                {
                    campains?.map((campain, index) => {
                        return (
                            <SwiperSlide key={index} className={s.leftWrap}>
                                <CampaignTemplate campain={campain} />
                            </SwiperSlide>
                        );
                    })
                }
            </Swiper>
        </div>
    );
};

export default ProviderCampainHeader;
