import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import TextArea from 'antd/es/input/TextArea';
import { Select, Collapse, Switch, Form, Checkbox, Space, message, InputNumber } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { KlarityServiceTypeTemplate, IKlarityServiceTypePatientGroup, IKlarityServiceTypeLimitation, EContactType } from 'types/common';
import { IFormKlarityServiceTypeValue, IKlaritySpecialtyForm, IUpdateKlaritySpecialtyInput } from 'types/serviceTemplate';
import { updateKlaritySpecial } from 'api/operation';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import CheckGroup from 'components/CheckGroup';
import remove from 'lodash/remove';

interface IProps {
    conditions: DefaultOptionType[];
    setSaveLoading: (v: boolean) => void;
    onSaveSuccessfully: () => void;
    row: IKlaritySpecialtyForm;
}

const Edit = ({
    conditions,
    setSaveLoading,
    onSaveSuccessfully,
    row,
}: IProps) => {
    const { serversTypeNames } = row;
    const [formInstance] = Form.useForm();
    const serversTypesFiledValues = Form.useWatch('serversTypes', formInstance);
    const [activeKey, setActiveKey] = useState<string[]>([]);

    useEffect(() => {
        if (row) {
            formInstance.setFieldsValue(row);
        }
    }, [formInstance, row]);

    const removeActiveKey = useCallback((k: string) => {
        remove(activeKey, (n: string) => n === k);
        setActiveKey([...activeKey]);
    }, [activeKey]);

    const handleSubmit = useCallback(async (value: IKlaritySpecialtyForm) => {
        if (!row) {
            console.error(`row is ${row}`);
            return;
        }

        setSaveLoading(true);

        const params: IUpdateKlaritySpecialtyInput = {
            ...row,
            ...value,
            commonCondition: value.commonCondition?.join(','),
            serversTypes: value.serversTypes?.map((typeValue: IFormKlarityServiceTypeValue) => ({
                ...typeValue,
                teleHealth: !!(typeValue.contactType?.includes(EContactType.TELE_HEALTH)),
                inPerson: !!(typeValue.contactType?.includes(EContactType.IN_PERSON)),
                patientGroupIds: typeValue.patientGroupIds.join(','),
                limitationIds: typeValue.limitationIds?.join(','),
            })),

        };
        try {
            const res = await updateKlaritySpecial(params);

            if (res && !res.error) {
                onSaveSuccessfully?.();
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }

        setSaveLoading(false);
    }, [onSaveSuccessfully, row, setSaveLoading]);

    const handleFinishFailed = useCallback((errors) => {
        if (errors?.errorFields.length > 0) {
            const keys = errors.errorFields?.map((error) => error?.name?.[1]);

            if (keys.length > 0) {
                setActiveKey(keys);
                message.error(errors.errorFields?.[0]?.errors);
            }
        }
    }, []);

    return (
        <div className={s.wrap}>
            <Form
                scrollToFirstError
                id="specialtyForm"
                form={formInstance}
                layout="vertical"
                onFinish={handleSubmit}
                onFinishFailed={handleFinishFailed}
            >
                <div className={s.info}>
                    <div className={s.title}>Specialty information</div>
                    <div className={s.tip}>*All fields are required</div>
                    <div className={s.formItem}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                { required: true, message: 'Patient accepted is a required field' },
                            ]}
                        >
                            <TextArea className={s.textarea} maxLength={800} showCount />
                        </Form.Item>
                    </div>
                    <div className={s.formItem}>
                        <Form.Item
                            label="Common conditions"
                            name="commonCondition"
                            rules={[
                                { required: true, message: 'Patient accepted is a required field' },
                            ]}
                        >
                            <Select
                                // mode="tags"
                                mode="multiple"
                                // className={s.tagSelect}
                                allowClear
                                options={conditions}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className={s.deviced} />
                <div className={s.servType}>
                    <div className={s.title}>Service type</div>
                    <div className={s.tip}>Add service types and configure default value</div>

                    <Form.List name="serversTypes">
                        {
                            (fields) => {
                                return (
                                    <Collapse
                                        expandIconPosition="end"
                                        activeKey={activeKey}
                                        onChange={(e) => {
                                            setActiveKey(e as string[]);
                                        }}
                                    >
                                        {
                                            fields.map(({ name }, index: number) => {
                                                const currentField = formInstance.getFieldValue('serversTypes')[name];
                                                const { serviceTypeId } = currentField;
                                                const serviceTypeTemplage = serversTypeNames?.find((serversTypeName:KlarityServiceTypeTemplate) => serversTypeName.id === serviceTypeId);

                                                if (!serviceTypeTemplage) {
                                                    return null;
                                                }

                                                const { displayName, klarityServiceTypePatientGroupList, klarityServiceTypeLimitationList, switchPatientGroupLimitation, switchTeleHealthInPerson, mandatory, inPerson, teleHealth } = serviceTypeTemplage;
                                                const contactTypeOptions = [];
                                                // const checkAll = plainOptions.length === checkedList.length;
                                                // const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;

                                                if (inPerson) {
                                                    contactTypeOptions.push(EContactType.IN_PERSON);
                                                }
                                                if (teleHealth) {
                                                    contactTypeOptions.push(EContactType.TELE_HEALTH);
                                                }

                                                let collapsible: 'header' | 'icon' | 'disabled' | undefined = 'disabled';

                                                if (mandatory) {
                                                    collapsible = undefined;
                                                } else {
                                                    collapsible = serversTypesFiledValues[name].switchType ? undefined : 'disabled';
                                                }

                                                return (
                                                    <Collapse.Panel
                                                        forceRender
                                                        key={index}
                                                        collapsible={collapsible}
                                                        header={
                                                            <div className={s.collapseHeader}>
                                                                <div className={s.headerTitle}>{displayName}</div>
                                                                {
                                                                    !mandatory &&
                                                                    <div className={s.switchBox} onClick={(e) => e.stopPropagation()}>
                                                                        <Form.Item
                                                                            name={[name, 'switchType']}
                                                                            // rules={[
                                                                            //     { required: true, message: 'Patient accepted is a required field' },
                                                                            // ]}
                                                                            valuePropName="checked"
                                                                        >
                                                                            <Switch
                                                                                onChange={() => removeActiveKey(index.toString())}
                                                                                checkedChildren={<CheckOutlined />}
                                                                                unCheckedChildren={<CloseOutlined />}
                                                                            />
                                                                        </Form.Item>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    >
                                                        {
                                                            !collapsible ?
                                                                <>
                                                                    {
                                                                        switchTeleHealthInPerson &&
                                                            (
                                                                <div className={s.formBox}>
                                                                    <div className={s.formItem}>
                                                                        <Form.Item
                                                                            label="Contact type"
                                                                            name={[name, 'contactType']}
                                                                            rules={[
                                                                                { required: true, message: 'Contact type is a required field' },
                                                                            ]}
                                                                        >
                                                                            <Checkbox.Group options={contactTypeOptions} />
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                            )
                                                                    }
                                                                    <div className={s.formBox}>
                                                                        <div className={s.formItem}>
                                                                            <Form.Item
                                                                                label="Default duration"
                                                                                name={[name, 'duration']}
                                                                                rules={[
                                                                                    { required: true, message: 'Default duration is a required field' },
                                                                                ]}
                                                                            >
                                                                                <InputNumber min="0" max="100000" style={{ width: '160px' }} addonAfter="Mins" />
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        switchPatientGroupLimitation && (
                                                                            <>
                                                                                <div className={s.formBox}>
                                                                                    <div className={s.formItem}>
                                                                                        <Form.Item
                                                                                            label="Patient accepted"
                                                                                            name={[name, 'patientGroupIds']}
                                                                                            style={{ marginBottom: '8px' }}
                                                                                            rules={[
                                                                                                { required: true, message: 'Patient accepted is a required field' },
                                                                                            ]}
                                                                                        >
                                                                                            <CheckGroup
                                                                                                options={
                                                                                                    klarityServiceTypePatientGroupList ? klarityServiceTypePatientGroupList.map((e: IKlarityServiceTypePatientGroup) => ({ label: e.patientGroup, value: e.id })) : []
                                                                                                }
                                                                                                showCheckAll
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={s.formBox}>
                                                                                    <div className={s.formItem}>
                                                                                        <Form.Item
                                                                                            label="Limitations"
                                                                                            name={[name, 'limitationIds']}
                                                                                            rules={[
                                                                                                { required: true, message: 'Limitations is a required field' },
                                                                                            ]}
                                                                                        >
                                                                                            <Checkbox.Group>
                                                                                                <Space direction="vertical">
                                                                                                    {
                                                                                                        klarityServiceTypeLimitationList?.map((e: IKlarityServiceTypeLimitation, i: number) => {
                                                                                                            const { id: limitationId, limitation } = e;

                                                                                                            return <Checkbox key={i} value={limitationId?.toString()}>{limitation}</Checkbox>;
                                                                                                        })
                                                                                                    }
                                                                                                </Space>
                                                                                            </Checkbox.Group>
                                                                                        </Form.Item>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </> : null
                                                        }
                                                    </Collapse.Panel>
                                                );
                                            })
                                        }
                                    </Collapse>
                                ); }
                        }
                    </Form.List>
                </div>
            </Form>
        </div>
    );
};

export default Edit;
