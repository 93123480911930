import { Request } from '../request';
import { IJoinCampaignInput } from 'types/campaign';

export const getCampaignList = () => Request.get({ url: '/schedule-server/campaign/providerJoin/getProviderCampaignList' });

export const joinCampaign = (data: IJoinCampaignInput) => Request.post({ url: '/schedule-server/campaign/providerJoin/joinCampaign', params: data });

export const leaveCampaign = (campaignId: number) => Request.put({ url: `/schedule-server/campaign/providerJoin/leaveCampaign/${campaignId}` });

export const getTelehealthVisitAndPrescriptionRefill = () => Request.get({ url: '/schedule-server/provider/home/telehealthVisitAndPrescriptionRefill/info' });
