import React, { useEffect, useState } from 'react';
import { Tooltip, Select, Spin } from 'antd';
import { getDashboard, ETimeType } from 'api/home';
import s from './s.module.less';
import cx from 'classnames';
import PatientReviewEntry from '../PatientReviewEntry';
import PricingReviewEntry from '../PricingReviewEntry';
import { TProfileData } from 'types/home';
import { ICCCDashboardItem } from 'types/ccc';

type IProps = {
    isKlarity: boolean;
    profileData: TProfileData
    setCCCDashboardData?: (val: ICCCDashboardItem[]) => void;
};

type TSummaryData = {
    averageSurveyRating: number | null;
    averageSurveyRatingRate: number | null;
    newPatientServed: number | null;
    newPatientServedRate: number | null;
    preAverageSurveyRating: number | null;
    preNewPatientServed: number | null;
    preProfileViews: number | null;
    preRecurringPatientBookings: number | null;
    preTotalProspect: number | null;
    profileViews: number | null;
    profileViewsRate: number | null;
    recurringPatientBookings: number | null;
    recurringPatientBookingsRate: number | null;
    totalProspect: number | null;
    totalProspectRate: number | null;
};

const Summary: React.FC<IProps> = ({ isKlarity, profileData, setCCCDashboardData }) => {
    const [dateRangeFilter, setDateRangeFilter] = useState<number>(1);
    const [summaryData, setSummaryData] = useState<TSummaryData>(
        {} as TSummaryData,
    );
    const [showEmptyBanner, setShowEmptyBanner] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getData = async (params: { timeRange?: number }) => {
        setIsLoading(true);
        try {
            let range = params.timeRange;
            if (range === undefined || range === null) {
                range = 1;
            }
            const { data, error } = await getDashboard(range);
            if (!error) {
                setSummaryData(data.data);
                setCCCDashboardData?.((data.data.cards || []) as ICCCDashboardItem[]);
                setIsLoading(false);
                setShowEmptyBanner(false);
            } else {
                setSummaryData({} as TSummaryData);
                setIsLoading(false);
                setCCCDashboardData?.([]);
                setShowEmptyBanner(true);
            }

        } catch (e) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getData({
            timeRange: dateRangeFilter,
        });
    }, []);

    const Card = ({
        title,
        tooltips,
        totalData,
        preData,
        rate,
        isStar,
    }: {
        title: string;
        tooltips: string;
        totalData: number | null;
        preData: number | null;
        rate: number | null;
        isStar?: boolean;
    }) => {
        return (
            <div className={s.card}>
                <div className={s.cardHeader}>
                    <h3 className={s.subTitle}>{title}</h3>
                    <Tooltip
                        overlayClassName={s.tipCard}
                        color="#fff"
                        overlayInnerStyle={{
                            padding: '12px',
                            color: '#15191E',
                            backgroundColor: '#fff',
                        }}
                        title={<div className={s.tipText}>{tooltips}</div>}
                    >
                        <div className={s.questionMarkIcon} />
                    </Tooltip>
                </div>
                <div className={s.cardContent}>
                    <div className={cx(s.number, isStar ? s.star : '')}>
                        <span className={s.biggerNumber}>{totalData || '0'}</span>
                        <span className={s.from}>from</span>
                        <span className={s.preData}>{preData || '0'}</span>
                    </div>
                    {rate !== null && rate > 0 && (
                        <div className={cx(s.rate, s.up)}>{`${rate}%` || '0'}</div>
                    )}
                    {rate !== null && rate < 0 && (
                        <div className={cx(s.rate, s.down)}>
                            {`${Math.abs(rate)}%` || '0'}
                        </div>
                    )}
                    {(rate === 0 || rate === null) && (
                        <div className={cx(s.rate, s.minus)}>0%</div>
                    )}
                </div>
            </div>
        );
    };

    const renderKiwiSummary = (
        <div className={s.content}>
            <div className={s.cardWrap}>
                <Card
                    title="Total Prospects"
                    tooltips="Patient has written or called you to inquire service details, or patient has clicked the scheduling link provided."
                    preData={summaryData.preTotalProspect}
                    totalData={summaryData.totalProspect}
                    rate={summaryData.totalProspectRate}
                />
                <Card
                    title="Profile Views"
                    tooltips="Patient has clicked and visited your dedicated profile page for your service details. Note: Some channel data updates monthly. You may notice some delay in data reporting."
                    preData={summaryData.preProfileViews}
                    totalData={summaryData.profileViews}
                    rate={summaryData.profileViewsRate}
                />
                <Card
                    isStar
                    title="Avg. Review Rating"
                    tooltips="Average rating received."
                    preData={summaryData.preAverageSurveyRating}
                    totalData={summaryData.averageSurveyRating}
                    rate={summaryData.averageSurveyRatingRate}
                />
            </div>
        </div>
    );
    const renderKlaritySummary = (
        <div className={s.content}>
            <div className={s.cardWrap}>
                <Card
                    title="Total Prospects"
                    tooltips="Patients with strong interest in your services who have written or called to inquire about service details, or have filled out patient information in Klarity check-out but didn't proceed to payment."
                    preData={summaryData.preTotalProspect}
                    totalData={summaryData.totalProspect}
                    rate={summaryData.totalProspectRate}
                />
                <Card
                    title="Profile Views"
                    tooltips="Patient has clicked and visited your dedicated profile page for your service details. Note: Some channel data updates monthly. You may notice some delay in data reporting."
                    preData={summaryData.preProfileViews}
                    totalData={summaryData.profileViews}
                    rate={summaryData.profileViewsRate}
                />
                <Card
                    title="Avg. Review Rating"
                    tooltips="Average rating received."
                    preData={summaryData.preAverageSurveyRating}
                    totalData={summaryData.averageSurveyRating}
                    rate={summaryData.averageSurveyRatingRate}
                    isStar
                />
            </div>
            <div className={s.cardWrap}>
                <Card
                    title="New Patients Served"
                    tooltips="New patient appointment scheduled."
                    preData={summaryData.preNewPatientServed}
                    totalData={summaryData.newPatientServed}
                    rate={summaryData.newPatientServedRate}
                />
                <Card
                    title="Recurring Patient Bookings"
                    tooltips="Established patient appointment scheduled."
                    preData={summaryData.preRecurringPatientBookings}
                    totalData={summaryData.recurringPatientBookings}
                    rate={summaryData.recurringPatientBookingsRate}
                />
            </div>
        </div>
    );
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <h2 className={s.title}>Performance summary</h2>
                <div className={s.selectWrap}>
                    <Select
                        value={dateRangeFilter}
                        style={{ width: 200 }}
                        onChange={(val) => {
                            setDateRangeFilter(val);
                            getData({
                                timeRange: val,
                            });
                        }}
                        options={[
                            { value: 1, label: 'Last 1 week' },
                            { value: 2, label: 'Last 2 weeks' },
                            { value: 4, label: 'Last 4 weeks' },
                            { value: 8, label: 'Last 8 weeks' },
                            { value: 12, label: 'Last 12 weeks' },
                            { value: 0, label: 'Since you started' },
                        ]}
                    />
                </div>
            </div>
            {
                showEmptyBanner && (
                    <div className={s.errorBanner}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25608 3.09882C9.02069 1.73952 10.9778 1.73952 11.7424 3.09882L17.3227 13.0194C18.0726 14.3526 17.1092 15.9999 15.5795 15.9999H4.41893C2.88927 15.9999 1.92585 14.3526 2.67578 13.0194L8.25608 3.09882ZM10.9991 13C10.9991 13.5523 10.5514 14 9.99915 14C9.44686 14 8.99915 13.5523 8.99915 13C8.99915 12.4477 9.44686 12 9.99915 12C10.5514 12 10.9991 12.4477 10.9991 13ZM9.99915 5C9.44686 5 8.99915 5.44772 8.99915 6V9C8.99915 9.55228 9.44686 10 9.99915 10C10.5514 10 10.9991 9.55228 10.9991 9V6C10.9991 5.44772 10.5514 5 9.99915 5Z" fill="#FBBF24" />
                        </svg>
                        Data is not available yet, please check back later.
                    </div>
                )
            }
            <PatientReviewEntry profileData={profileData} />
            <PricingReviewEntry profileData={profileData} />
            <Spin spinning={isLoading}>
                {isKlarity ? renderKlaritySummary : renderKiwiSummary}
            </Spin>
        </div>
    );
};

export default Summary;
