import React, { useEffect } from 'react';
import s from './s.module.less';
import { Button, Form, Input } from 'antd';
import BannerUploader from '../BannerUploader';
import { IAssessmentEmail } from 'types/operation';

interface IProps {
    data: IAssessmentEmail;
    onSave?: (params: any, successCallback: () => void) => void;
}

const riskLevelInfo = [
    {
        riskLevel: 1,
        title: 'Scenario 1',
    },
    {
        riskLevel: 2,
        title: 'Scenario 2',
    },
    {
        riskLevel: 3,
        title: 'Scenario 3',
    },
];

const Indication = ({
    data,
    onSave,
}: IProps) => {
    const [formInstance] = Form.useForm();
    const [isEdit, setIsEdit] = React.useState(false);
    const { riskLevel, id } = data;
    const title = riskLevelInfo.find((e) => e.riskLevel === riskLevel)?.title || '';

    useEffect(() => {
        formInstance.setFieldsValue(data);
    }, [data, formInstance]);

    const handleStartEdit = React.useCallback(() => {
        setIsEdit(true);
    }, []);

    const handleCancelEdit = React.useCallback(() => {
        setIsEdit(false);
    }, []);

    const handleSave = React.useCallback(() => {
        formInstance.validateFields().then((values) => {
            onSave?.({
                ...values,
                id,
                riskLevel,
            }, () => setIsEdit(false));
        }).catch((e) => {
            console.error(e);
        });
    }, [formInstance, id, onSave, riskLevel]);

    const ButtonRender = React.useMemo(() => {
        if (isEdit) {
            return (
                <>
                    <Button className={s.btn} onClick={handleCancelEdit}>Cancel</Button>
                    <Button htmlType="submit" form="pmform" className={s.saveBtn} onClick={handleSave}>Save</Button>
                </>
            );
        }

        return <Button className={s.btn} onClick={handleStartEdit}>Edit</Button>;
    }, [handleCancelEdit, handleSave, handleStartEdit, isEdit]);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>{title}</div>
                <div className={s.btns}>{ButtonRender}</div>
            </div>
            <div className={s.body}>
                {isEdit ? (
                    <Form id="pmform" form={formInstance} layout="vertical">
                        <Form.Item
                            name="subject"
                            label="Subject"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input subject',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="bannerImageUrl"
                            label="Banner image"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select banner image',
                                },
                            ]}
                        >
                            <BannerUploader
                                title="Banner image"
                                descTitle="Upload your banner image here"
                                descContent="For optimal display, we recommend using a resolution of
                            <span>1280 x 720 px</span> or <span>800 x 450 px</span>. Please
                            ensure the file size does not exceed <span>10 MB</span>."
                            />
                        </Form.Item>
                        <Form.Item
                            name="ctaText"
                            label="CTA text"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the text of CTA',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="ctaUrl"
                            label="CTA URL"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the url of CTA',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                ) : (
                    <div className={s.formWrap}>
                        <div className={s.row}>
                            <div className={s.item}>
                                <div className={s.label}>Subject</div>
                                <div className={s.value}>{data?.subject}</div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.item}>
                                <div className={s.label}>Banner image</div>
                                <div className={s.value}>
                                    <img
                                        src={
                                            data?.bannerImageUrl ||
                        'https://www.helloklarity.com/_next/image?url=https%3A%2F%2Fcontent.helloklarity.com%2Fwp-content%2Fuploads%2F2024%2F08%2FOzempic-babies.jpg&w=3840&q=75'
                                        }
                                        alt="banner"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.item}>
                                <div className={s.label}>CTA text</div>
                                <div className={s.value}>{data?.ctaText}</div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.item}>
                                <div className={s.label}>CTA URL</div>
                                <div className={s.value}>{data?.ctaUrl}</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Indication;
