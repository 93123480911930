// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__statusTag--PlJea {
  display: inline-block;
  margin-bottom: 8px;
  padding: 0 10px 0 5px;
  border-radius: 8px;
  color: var(--white);
  font-family: CircularXX;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.2px;
  background: linear-gradient(90deg, #E94C20 0%, #FDB160 109.71%);
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProviderHomePage/components/CampainOpportunities/campaignTemplate/s.module.less"],"names":[],"mappings":"AAEA;EACI,qBAAA;EACA,kBAAA;EACA,qBAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,+DAAA;AADJ","sourcesContent":[".wrap {\n}\n.statusTag {\n    display: inline-block;\n    margin-bottom: 8px;\n    padding: 0 10px 0 5px;\n    border-radius: 8px;\n    color: var(--white);\n    font-family: CircularXX;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 18.2px;\n    background: linear-gradient(90deg, #E94C20 0%, #FDB160 109.71%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusTag": `s-module__statusTag--PlJea`
};
export default ___CSS_LOADER_EXPORT___;
