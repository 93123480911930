import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Modal, Radio, RadioChangeEvent, Space, message } from 'antd';
import GlobalLayerStore from 'store/GlobalLayer';
import { EType } from 'types/helloKlarity';
import { IBPMappingItem } from 'types/operation';
import { updateHelloKlarityReschedule } from 'api/operation';

interface IProps {
    row?: IBPMappingItem | null;
    type: EType;
    onOk: () => void;
    onCancel: () => void;
}

const AutoRescheduleUpdateModal = ({
    row,
    onOk,
    onCancel,
}: IProps) => {
    const [getGlobalLayer] = GlobalLayerStore.useStore();
    const [value, setValue] = useState(!!row?.autoReschedule);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (row) {
            setValue(!!row?.autoReschedule);
        }
    }, [row]);

    const update = async () => {
        if (row && row.bpProviderId) {
            setLoading(true);
            try {
                const res = await updateHelloKlarityReschedule({
                    bpProviderId: row?.bpProviderId,
                    autoReschedule: value,
                });

                if (res && !res.error) {
                    onOk();
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }

            setLoading(false);
        }
    };

    const handleOk = () => {
        if (row) {
            update();
        }
    };

    const onChange = useCallback((e: RadioChangeEvent) => {
        setValue(e.target.value);
    }, []);

    return (
        <Modal
            title="Update auto-schedule settings"
            width={312}
            open={getGlobalLayer('showAutoScheduleModal')}
            okText="Save"
            onOk={handleOk}
            onCancel={onCancel}
            confirmLoading={loading}
        >
            <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                    <Radio value={true}>
                        <div className={s.radioItem}>
                            On
                        </div>
                    </Radio>
                    <Radio value={false}>
                        <div className={s.radioItem}>
                            Off
                        </div>
                    </Radio>
                </Space>
            </Radio.Group>
        </Modal>
    );
};

export default AutoRescheduleUpdateModal;
