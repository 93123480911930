import React, {
    useState,
    useImperativeHandle,
    forwardRef,
    useEffect,
} from 'react';
import s from './s.module.less';
import { Button, Descriptions, Form, Input } from 'antd';
import type { TAssessmentItem } from 'types/operation';
import commonS from 'styles/common.module.less';
import FileUpload from 'components/form/FileUpload';
import BannerUploader from '../Email/components/BannerUploader';

type IProps = {
    item: TAssessmentItem;
    onSuccess: (obj: Partial<TAssessmentItem>) => void;
};

export interface IntroCardB {
    validateAndSubmit: () => boolean;
}

const TestOverview = forwardRef<IntroCardB, IProps>((props, ref) => {
    const { item, onSuccess } = props;
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm<TAssessmentItem & { active: boolean }>();

    const handleEdit = () => {
        setIsEdit(true);
    };

    const handleCancel = () => {
        setIsEdit(false);
    };

    const handleSave = async () => {
        const formValues = await form.validateFields();
        console.log('formValues: ', formValues);
        onSuccess({
            title: formValues.title,
            description: formValues.description,
            finishMinute: formValues.finishMinute,
            participantsNumber: formValues.participantsNumber,
            imgUrl: formValues.imgUrl,
        });
        setIsEdit(false);
    };

    const renderDescription = () => {
        return (
            <Descriptions
                colon={false}
                title="Test overview"
                layout="vertical"
                extra={<Button onClick={() => handleEdit()}>Edit</Button>}
                column={1}
                size="small"
                contentStyle={{
                    marginBottom: '16px',
                }}
            >
                <Descriptions.Item label="Title">
                    {item.title || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Description">
                    {item.description || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Finishing time">
                    {item.finishMinute ? `${item.finishMinute} mins` : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Participants">
                    {item.participantsNumber || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Image">
                    {item.imgUrl ? (
                        <img
                            src={item.imgUrl}
                            alt={item.assessmentName}
                            className={s.cardImg}
                        />
                    ) : (
                        'No image added'
                    )}
                </Descriptions.Item>
            </Descriptions>
        );
    };

    const renderForm = () => {
        return (
            <div>
                <div className={s.headerBar}>
                    <h4 className={s.subtitle}>Test overview</h4>
                    <div className={s.buttonWrap}>
                        <Button onClick={() => handleCancel()}>Cancel</Button>
                        <Button
                            onClick={() => handleSave()}
                            type="primary"
                            className={s.ml8}
                        >
                            Save changes
                        </Button>
                    </div>
                </div>
                <p className={s.tips}>
                    <span>* </span>
                    Indicates required fields
                </p>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        validateFirst
                        rules={[
                            {
                                required: true,
                                message: 'Title name is required',
                            },
                        ]}
                    >
                        <Input
                            maxLength={100}
                            showCount
                            placeholder="Ex. Could it be ADHD? Take our quick & free self-assessment"
                        />
                    </Form.Item>
                    <Form.Item
                        className={s.rowItem}
                        name="description"
                        label={
                            <div className={s.formHeader}>
                                <span>Description</span>
                                <p className={s.descriptionTips}>
                                    Write a brief, engaging description of the
                                    self-assessment. This text will appear on the landing page
                                    and assessment card, so keep it clear and concise.
                                </p>
                            </div>
                        }
                    >
                        <Input.TextArea rows={5} maxLength={200} showCount />
                    </Form.Item>
                    <Form.Item
                        label="Finishing time"
                        name="finishMinute"
                        validateFirst
                        rules={[
                            {
                                required: true,
                                message: 'Finishing time name is required',
                            },
                        ]}
                    >
                        <Input maxLength={10} suffix="Mins" />
                    </Form.Item>
                    <Form.Item label="Participants" name="participantsNumber">
                        <Input maxLength={10} />
                    </Form.Item>
                    <Form.Item
                        className={s.rowItem}
                        name="imgUrl"
                        label={
                            <div className={s.formHeader}>
                                <span>Image</span>
                                <p className={s.descriptionTips}>
                                    For the best visual experience, upload an image with a 3:2
                                    aspect ratio (width:height). This will ensure the image
                                    fits well within the designated space.
                                </p>
                            </div>
                        }
                    >
                        <BannerUploader
                            maxSize={2}
                            title="Image"
                            descContent="For the best visual experience, upload an image with a 3:2 aspect ratio (width:height). This will ensure the image fits well within the designated space."
                        />
                    </Form.Item>
                </Form>
            </div>
        );
    };

    useImperativeHandle(ref, () => ({
        validateAndSubmit: () => {
            return !isEdit;
        },
    }));

    useEffect(() => {
        if (item) {
            form.setFieldsValue({
                title: item.title,
                description: item.description,
                finishMinute: item.finishMinute,
                participantsNumber: item.participantsNumber,
                imgUrl: item.imgUrl,
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    return (
        <div className={s.wrap}>
            <div className={s.content}>
                {isEdit ? renderForm() : renderDescription()}
            </div>
        </div>
    );
});

export default TestOverview;
