const openWindow = ({ url, title }: { url: string; title: string }) => {
    if (!window) {
        return;
    }
    window.open(
        url,
        title,
        `height=500, width=750, top=${window.innerHeight / 2 - 250
        }, left=${document.body.offsetWidth / 2 - 375
        }, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no`,
    );
};

export const shareToFacebook = (shareUrl: string) => {
    openWindow({
        url: `https://www.facebook.com/dialog/share?app_id=331213246266862&display=popup&href=${shareUrl}&redirect_uri=${shareUrl}`,
        title: 'facebook',
    });
};

export const shareToLinkedin = (shareUrl: string, shareTitle?: string) => {
    openWindow({
        // url: `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`,
        url: `http://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${shareTitle}&summary=${shareUrl}`,
        title: 'Linkedin',
    });
};

export const shareToEmail = (emailTitle:string, emailContent: string) => {
    const emailLink = `mailto:?subject=${encodeURIComponent(
        emailTitle || '',
    )}&body=${encodeURIComponent(emailContent || '')}`;

    window.open(emailLink);
};

export const shareToTwitter = (shareUrl: string, shareTitle: string) => {
    // const url = `https://x.com/share?&title=${encodeURIComponent(shareTitle)}&text=${shareTitle}&url=${encodeURIComponent(shareUrl || '')}`;
    const url = `https://x.com/intent/tweet?text=${encodeURIComponent(shareTitle)}&url=${encodeURIComponent(shareUrl || '')}`;
    openWindow({
        url,
        title: shareTitle,
    });
};
export const shareToPinterest = (shareUrl:string, shareTitle: string, shareImageUrl: string) => {
    const url = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(shareImageUrl || '')}&media=${encodeURIComponent(shareImageUrl || '')}&description=${shareTitle}`;
    openWindow({
        url,
        title: 'pinterest',
    });
};

export const shareToDoximity = (shareUrl:string, doximityContent: string = '') => {
    const url = `https://www.doximity.com/conversations/new?url=${encodeURIComponent(
        `${shareUrl}`,
    )}&message=${encodeURIComponent(doximityContent)}&subject=KiwiHealth`;
    openWindow({
        url,
        title: 'doximity',
    });
};
