import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { Button, Spin, message } from 'antd';
import { getHelloKlairtyProfileLink } from 'api/operation';
import { getUrlParam } from 'utils/common';

const HelloKlarityMappingPauseSuccess = () => {
    const navigate = useNavigate();
    const tableRowId = getUrlParam('id');
    const providerName = getUrlParam('pn') || '';
    const [website, setWebsite] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleBack = useCallback(() => {
        navigate(`${PATH.OPERATION}/${PATH.HELLO_KLARITY_PROVIDERS}`, { replace: true });
    }, [navigate]);

    const getProfileUrl = useCallback(async (id: number) => {
        setLoading(true);
        try {
            const res = await getHelloKlairtyProfileLink(id);

            if (res && !res.error) {
                setWebsite(res.data?.data || []);
            } else {
                message.error(res.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (tableRowId) {
            getProfileUrl(Number(tableRowId));
        }
    }, [getProfileUrl, tableRowId]);

    if (loading) {
        return <div className={s.wrap}><Spin /></div>;
    }

    return (
        <div className={s.wrap}>
            <div className={s.box}>
                <div className={s.successImage} />
                <p className={s.desc}>{`Provider ${decodeURIComponent(providerName)} is now visible on Hello Klarity.com`}</p>
                <Button className={s.backBtn} type="primary" onClick={handleBack}>{'Back to Hello Klarity > Providers page'}</Button>
                <p className={s.exp}>The profile is now visible to patients, but booking functionality is not yet enabled.</p>
                {
                    website?.map((item, index) => {
                        return (
                            <div key={index} className={s.link}>
                                <div className={s.before} />
                                <a className={s.linkText} href={item} target="_blank" rel="noreferrer">{item}</a>
                                <div className={s.after} />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default HelloKlarityMappingPauseSuccess;
