import React from 'react';
import { Button, Input, message, Modal, Space } from 'antd';
import CommonTable from 'components/CommonTable';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import dayjs from 'utils/dayjs';
import s from './s.module.less';
import { SearchOutlined } from '@ant-design/icons';
import { getOpsReferralProgramsList, setReferralProgramStatus } from 'api/operation';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { ECampaignConfigStatus, TCampaignConfigData } from 'types/common';
import Status from './components/Status';
import { getTimeStr } from 'utils/calendar';
import ReferralUpdateModal from './components/ReferralUpdateModal';
import { ok } from 'assert';

const ReferralConfig: React.FC = () => {
    const navigation = useNavigate();
    const [itemList, setItemList] = React.useState<TCampaignConfigData[]>();
    const [loading, setLoading] = React.useState(false);
    const total = itemList?.length || 0;
    const [pageSize, setPageSize] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [pendingNameFilter, setPendingNameFilter] = React.useState('');
    const [nameFilter, setNameFilter] = React.useState<string>('');
    const [show, setShow] = React.useState(false);
    const [submiting, setSubmiting] = React.useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [current, setCurrent] = React.useState<TCampaignConfigData>();
    const [opsStatusFilter, setOpsStatusFilter] = React.useState<string[]>();

    const doFetch = async () => {
        setLoading(true);
        // const reqParam: IGeAddOnResp = {
        //     current,
        //     size: pageSize,
        // };
        // if (providerName) {
        //     reqParam.providerName = providerName;
        // }
        // if (productCodeList && productCodeList.length > 0) {
        //     reqParam.productCodeList = productCodeList;
        // }
        // if (paymentStatusList && paymentStatusList.length === 1) {
        //     reqParam.paymentStatusList = paymentStatusList;
        // }

        // if (orderKey && orderType) {
        //     reqParam.orderKey = orderKey;
        //     reqParam.orderType = orderType;
        // }
        const result = await getOpsReferralProgramsList();

        if (!result.error) {
            const data: TCampaignConfigData[] = result?.data?.data?.records || [];
            data?.sort((a, b) => {
                if (!a.startDateTime || !b.startDateTime) {
                    return 0;
                }
                return dayjs(b.startDateTime).unix() - dayjs(a.startDateTime).unix();
            });
            const total = data.length;
            setItemList(data);
        }
        setLoading(false);
    };
    const handlePausePlan = async (id: number) => {
        setSubmiting(true);
        const result = await setReferralProgramStatus({
            id,
            status: ECampaignConfigStatus.PAUSED,
        });
        console.log({
            result,
        });
        if (!result?.data?.data) {
            message.success('Pause success');
            doFetch();
        }
        setSubmiting(false);
    };

    const handleActivePlan = async (id: number) => {
        setSubmiting(true);
        const result = await setReferralProgramStatus({
            id,
            status: ECampaignConfigStatus.SCHEDULED,
        });
        if (!result?.data?.data) {
            message.success('Active success');
            doFetch();
        } else {
            modal.error({
                title: 'Referral program can’t be activated',
                content: 'You can’t activate this paused referral program. There is an existing program with overlapping dates. Please pause the current program before activating a new one.',
                okButtonProps: {
                    type: 'default',
                },
                okText: 'Done',
                wrapClassName: s.modalWrap,
            });
        }
        setSubmiting(false);
    };

    const handleTableChange =
        (
            paginationData?: TablePaginationConfig,
            filtersData?: any,
            sorter?: any,
            action?: any,
        ) => {
            setPage(paginationData?.current!);
            setPageSize(paginationData?.pageSize!);
            setOpsStatusFilter(filtersData?.status);
        };

    const displayData: TCampaignConfigData[] | undefined = itemList?.filter((item) => {
        let ret = true;
        if (nameFilter) {
            ret = item.name.includes(nameFilter);
        }
        if (!ret) {
            return false;
        }
        if (opsStatusFilter) {
            ret = opsStatusFilter.includes(item.status);
        }
        return ret;
    });

    React.useEffect(() => {
        doFetch();
        // setShow(true);
    }, []);

    const columns: ColumnsType<TCampaignConfigData> = [
        {
            title: 'REFERRAL PROGRAM NAME',
            dataIndex: 'name',
            key: 'name',
            width: '220px',
            filterDropdown: ({ close }: any) => (
                <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder="Search name"
                        value={pendingNameFilter}
                        onChange={
                            (e) => {
                                setPendingNameFilter(e.target.value);
                            }
                        }
                        onPressEnter={() => {
                            setNameFilter(pendingNameFilter);
                            setPage(1);
                            close();
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setNameFilter(pendingNameFilter);
                                setPage(1);
                                close();
                            }
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingNameFilter('');
                                setNameFilter('');
                                setPage(1);
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            render: (name: string, item: TCampaignConfigData) => {
                return (
                    <div className={s.cell}>
                        <div className={s.name}>
                            {item.name}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            width: '160px',
            filters: [
                {
                    text: ECampaignConfigStatus.IN_PROGRESS,
                    value: ECampaignConfigStatus.IN_PROGRESS,
                },
                {
                    text: ECampaignConfigStatus.SCHEDULED,
                    value: ECampaignConfigStatus.SCHEDULED,
                },
                {
                    text: ECampaignConfigStatus.PAUSED,
                    value: ECampaignConfigStatus.PAUSED,
                },
                {
                    text: ECampaignConfigStatus.COMPLETE,
                    value: ECampaignConfigStatus.COMPLETE,
                },
            ],
            render: (val: ECampaignConfigStatus) => {
                return (
                    <div className={s.cell}>
                        <Status status={val} />
                    </div>
                );
            },
        },
        {
            title: 'START DATE',
            dataIndex: 'startDateTime',
            width: '220px',
            // sortDirections: ['ascend', 'descend', 'ascend'],
            // sorter: true,
            // filters: [
            //     {
            //         text: 'None',
            //         value: '0',
            //     },
            //     {
            //         text: '1',
            //         value: '1',
            //     },
            //     {
            //         text: '2',
            //         value: '2',
            //     },
            // ],
            render: (val: string) => {
                return (
                    <div className={s.cell}>
                        {getTimeStr(dayjs(val))}
                    </div>
                );
            },
        },
        {
            title: 'END DATE',
            dataIndex: 'endDateTime',
            width: '220px',
            // sortDirections: ['ascend', 'descend', 'ascend'],
            // sorter: true,
            // filters: [
            //     {
            //         text: 'None',
            //         value: '0',
            //     },
            //     {
            //         text: '1',
            //         value: '1',
            //     },
            //     {
            //         text: '2',
            //         value: '2',
            //     },
            // ],
            render: (val: string) => {
                return (
                    <div className={s.cell}>
                        {val ? getTimeStr(dayjs(val)) : ''}
                    </div>
                );
            },
        },
        {
            title: 'REFERRER',
            dataIndex: 'referrerCount',
            width: '120px',
            render: (val: string) => {
                return (
                    <div className={s.cell}>
                        {val || Number(val) === 0 ? val : 'N/A'}
                    </div>
                );
            },
        },
        {
            title: 'REFEREE',
            dataIndex: 'refereeCount',
            width: '120px',
            render: (val: string) => {
                return (
                    <div className={s.cell}>
                        {val || Number(val) === 0 ? val : 'N/A'}
                    </div>
                );
            },
        },
        {
            title: 'LAST UPDATE',
            dataIndex: 'updateAt',
            width: '220px',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            render: (val: string) => {
                return (
                    <div className={s.cell}>
                        {getTimeStr(dayjs(val))}
                    </div>
                );
            },
        },
        {
            title: '',
            width: '230px',
            fixed: 'right',
            render: (p: TCampaignConfigData) => {
                let text = '';
                if (p.status === ECampaignConfigStatus.IN_PROGRESS || p.status === ECampaignConfigStatus.SCHEDULED) {
                    text = 'Pause';
                }
                if (p.status === ECampaignConfigStatus.PAUSED) {
                    text = 'Activate';
                }
                return (
                    <div className={s.actionWrap}>
                        {
                            !text &&
                            <span style={{ width: '96px', display: 'inline-block' }}>{' '}</span>
                        }
                        {
                            text &&
                            <Button
                                className={s.link}
                                onClick={() => {
                                    if (p.status === ECampaignConfigStatus.IN_PROGRESS || p.status === ECampaignConfigStatus.SCHEDULED) {
                                        handlePausePlan(p.id);
                                    } else {
                                        handleActivePlan(p.id);
                                    }
                                }}
                                type="text"
                            >
                                {text}
                            </Button>
                        }

                        <Button
                            className={s.link}
                            onClick={() => {
                                setCurrent(p);
                                setShow(true);
                            }}
                            type="text"
                        >
                            View
                        </Button>
                    </div>
                );
            },
        },
    ].filter(Boolean) as ColumnsType<TCampaignConfigData>;

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.titleBox}>
                    <div className={s.title}>Referral programs</div>
                    <div className={s.desc}>Please note that only one referral program can be active at a time</div>
                </div>
                <Button
                    onClick={() => {
                        setCurrent(undefined);
                        setShow(true);
                    }}
                    type="primary"
                >Add a new referral program
                </Button>
            </div>
            <CommonTable
                bordered
                rowKey="id"
                pagination={{
                    total,
                    current: page,
                    pageSize,
                    showSizeChanger: false,
                }}
                scroll={{ x: 'max-content' }}
                loading={loading || submiting}
                columns={columns}
                data={displayData || []}
                onChange={handleTableChange}
            />
            <ReferralUpdateModal
                allItems={itemList}
                item={current}
                show={show}
                onCancel={() => {
                    setShow(false);
                }}
                onSuccess={() => {
                    setShow(false);
                    doFetch();
                }}
            />
            {contextHolder}
        </div>
    );
};

export default ReferralConfig;
