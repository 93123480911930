import React, { useMemo } from 'react';
import s from './s.module.less';
import { Button, Input, message, Popover, Spin } from 'antd';
import SubscriptionButton from 'components/SubscriptionButton';
import { SUBSCRIPTION_DETAIL_LINK, GROUP_PRICE_CONTACT_LINK } from 'constants/subscription';
import { TFunStatus, TSubscriptionType } from 'types/subscription';
import Check from './Check.svg';
import Question from './Question.svg';
import useSubscriptionPlan from 'hooks/useSubscriptionPlan';
import { isFreeUser } from 'utils/provider';
import ProviderChannelsStore from 'store/ProviderChannles';
import { Channel } from 'types/common';
import { checkCoupon } from 'api/subscription';
import CouponBg from './couponBg.svg';
import { set } from 'lodash';

const HIGH_LIGHT_SUB_TYPE = TSubscriptionType.GROWTH;

type TCouponRes = {
    productCodes: string[], //[ "prod_QaVnbvrpiVMm6j"],
    couponInfo: {
        created: number, // 1722868690,
        name: string, //"Growth-Monthly-20%OFF",
        amountOff?: number | null,
        percentOff?: number | null //20.0,
        valid: boolean
    }
};

const MOCK_COUPON_DATA = {
    productCodes: [
        'prod_QaVnbvrpiVMm6j',
    ],
    couponInfo: {
        created: 1722868690,
        name: 'Growth-Monthly-20%OFF',
        amountOff: null,
        percentOff: 20.0,
        valid: true,
    },
};

type Prop = {
    hideTitle?: boolean,
    wrapClass?: string,
};

const Subscription = (props: Prop) => {
    const { hideTitle, wrapClass } = props;
    const [tabInx, setTabInx] = React.useState(3);
    const [plansData, loadingSubscriptionPlan, refresh] = useSubscriptionPlan();
    const subscriptionPlan = plansData.kiwiPlan;
    const currentPlan = subscriptionPlan && subscriptionPlan.find((item) => !!item.isCurrent);
    const isFree = isFreeUser();
    const [getMyChannels] = ProviderChannelsStore.useStore();
    const myChannels = getMyChannels('data');
    const [isInputingCoupon, setIsInputingCoupon] = React.useState(true);
    const [coupon, setCoupon] = React.useState('');
    const [checkingCoupon, setCheckingCoupon] = React.useState(false);

    const [validCoupon, setValidCoupon] = React.useState('');
    const [validDiscount, setValidDiscount] = React.useState<TCouponRes>();
    const [validDiscountTimePeriod, setValidDiscountTimePeriod] = React.useState<number>();

    let tabInxClass = s.tabBg1;
    if (tabInx === 2) {
        tabInxClass = s.tabBg2;
    }
    if (tabInx === 3) {
        tabInxClass = s.tabBg3;
    }
    if (tabInx === 4) {
        tabInxClass = s.tabBg4;
    }
    const funList: TFunStatus[] = useMemo(() => {
        let freeChannle = ['WebMD Standard', 'NextDoor'];
        if (myChannels?.freeList && myChannels?.freeList.length > 0) {
            freeChannle = myChannels.freeList.splice(0, 2).map((e: Channel) => e.name);
        }

        const channel1 = freeChannle[0];
        const channel2 = freeChannle[1] ? `and ${freeChannle[1]}` : '';

        const funs = subscriptionPlan[0]?.funList || [];
        return funs.map((f: TFunStatus, i: number) => {
            if (i === 0) {
                return {
                    ...f,
                    des: `${freeChannle.length} basic channels are included in the Free Plan, including ${channel1} ${channel2}`,
                };
            }
            return f;
        });
    }, [myChannels, subscriptionPlan]);

    const checkPromocode = async (code: string) => {
        setCheckingCoupon(true);
        const result = await checkCoupon(code);
        setCheckingCoupon(false);
        if (!result.error && result?.data?.data) {
            const promoData: TCouponRes = result?.data?.data;
            setValidDiscount(promoData);
            setValidCoupon(code);
        }
    };

    React.useEffect(() => {
        if (validDiscount && validDiscount.productCodes && validDiscount.productCodes.length > 0) {
            const discount = validDiscount.couponInfo;
            const productCode = validDiscount.productCodes[0];
            const targerMonthPlan = subscriptionPlan?.find((item) => {
                return item.monthlyProductCode === productCode;
            });
            const targerQuarterPlan = subscriptionPlan?.find((item) => {
                return item.quarterlyProductCode === productCode;
            });
            const targerSemiAnnualPlan = subscriptionPlan?.find((item) => {
                return item.semiAnnualProductCode === productCode;
            });
            const targerYearPlan = subscriptionPlan?.find((item) => {
                return item.yearlyProductCode === productCode;
            });
            if (targerMonthPlan) {
                setTabInx(1);
                setValidDiscountTimePeriod(1);
            } else if (targerQuarterPlan) {
                setTabInx(2);
                setValidDiscountTimePeriod(2);
            } else if (targerSemiAnnualPlan) {
                setTabInx(3);
                setValidDiscountTimePeriod(3);
            } else if (targerYearPlan) {
                setTabInx(4);
                setValidDiscountTimePeriod(4);
            }
        }
    }, [validDiscount]);

    let discountText = '';
    let percentOff = '';
    let planName = '';
    let planPeriod = '';
    if (validDiscount && validDiscount.productCodes && validDiscount.productCodes.length > 0) {
        const discount = validDiscount.couponInfo;
        const productCode = validDiscount.productCodes[0];
        const targerMonthPlan = subscriptionPlan?.find((item) => {
            return item.monthlyProductCode === productCode;
        });
        const targerQuarterPlan = subscriptionPlan?.find((item) => {
            return item.quarterlyProductCode === productCode;
        });
        const targerSemiAnnualPlan = subscriptionPlan?.find((item) => {
            return item.semiAnnualProductCode === productCode;
        });
        const targerYearPlan = subscriptionPlan?.find((item) => {
            return item.yearlyProductCode === productCode;
        });
        if (targerMonthPlan) {
            planName = 'Growth plan';
            if (targerMonthPlan.type === TSubscriptionType.ESSENTIAL) {
                planName = 'Essential plan';
            }
            percentOff = `${discount.percentOff}`;
            planPeriod = 'month';
        } else if (targerQuarterPlan) {
            planName = 'Growth plan';
            if (targerQuarterPlan.type === TSubscriptionType.ESSENTIAL) {
                planName = 'Essential plan';
            }
            percentOff = `${discount.percentOff}`;
            planPeriod = 'quarter';
        } else if (targerSemiAnnualPlan) {
            planName = 'Growth plan';
            if (targerSemiAnnualPlan.type === TSubscriptionType.ESSENTIAL) {
                planName = 'Essential plan';
            }
            percentOff = `${discount.percentOff}`;
            planPeriod = '6-month';
        } else if (targerYearPlan) {
            planName = 'Growth plan';
            if (targerYearPlan.type === TSubscriptionType.ESSENTIAL) {
                planName = 'Essential plan';
            }
            percentOff = `${discount.percentOff}`;
            planPeriod = 'year';
        }
        discountText = `Congrats 🎉! You’ll get ${percentOff}% off on the ${planName} for the upcoming ${planPeriod}`;
    }

    const hasDiscountText = !!planName;

    return (
        <div className={`${s.wrap} ${wrapClass || ''}`}>
            {!hideTitle && <h2>Select the ideal plan tailored to your practice</h2>}
            {
                isFree &&
                <div className={`${s.couponWrap} ${isInputingCoupon ? s.couponWrapInputing : ''}`}>
                    <div className={s.couponLeft}>
                        <div className={s.cLabel}>Have a <span className={s.high}>Headway</span> coupon code? </div>
                        <div className={s.cLabelSub}>Enter it here to unlock your exclusive discounts on Growth plan!</div>
                        <div className={s.cImg} />
                    </div>
                    <div
                        className={s.couponRight}
                    >
                        <div className={s.couponInput}>
                            <Input
                                onChange={(e) => {
                                    setCoupon(e.target.value);
                                }}
                                allowClear
                                onPressEnter={() => {
                                    checkPromocode(coupon);
                                }}
                                value={coupon}
                                className={s.input}
                                size="large"
                                placeholder="Enter coupon code"
                            />
                            <Button
                                disabled={!coupon || coupon === validCoupon}
                                loading={checkingCoupon}
                                onClick={() => {
                                    checkPromocode(coupon);
                                }}
                                className={s.btn}
                                type="primary"
                                size="large"
                            >Apply
                            </Button>
                        </div>
                        <div className={`${s.discountText} ${hasDiscountText ? s.discountTextShow : ''}`}>
                            Congrats 🎉! You’ll get <span className={s.high}>{percentOff}% off</span> on the <span className={s.high}>{planName}</span> for the upcoming <span className={s.high}>{planPeriod}</span>
                        </div>
                    </div>
                </div>
            }
            <div className={s.tabOutOut}>
                <div className={s.tabOut}>
                    <div className={s.tabWrap}>
                        <div className={`${s.tabBg} ${tabInxClass}`} />
                        <div
                            onClick={() => {
                                setTabInx(1);
                            }}
                            className={tabInx === 1 ? `${s.tabItem} ${s.tabItemActive}` : s.tabItem}
                        >
                            Monthly billing
                        </div>
                        <div
                            onClick={() => {
                                setTabInx(2);
                            }}
                            className={tabInx === 2 ? `${s.tabItem} ${s.tabItemActive}` : s.tabItem}
                        >
                            Quarterly billing <span className={s.high}>(Save 15%)</span>
                        </div>
                        <div
                            onClick={() => {
                                setTabInx(3);
                            }}
                            className={tabInx === 3 ? `${s.tabItemLarge} ${s.tabItemActive}` : s.tabItemLarge}
                        >
                            Semi-annual billing <span className={s.high}>(Save 18%)</span>
                        </div>
                        <div
                            onClick={() => {
                                setTabInx(4);
                            }}
                            className={tabInx === 4 ? `${s.tabItem} ${s.tabItemActive}` : s.tabItem}
                        >
                            Yearly <span className={s.high}>(Save 20%)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.content}>
                <div className={s.contentInner}>
                    <Spin wrapperClassName={s.planWrap} spinning={checkingCoupon}>
                        <div className={s.planTitleWrap}>
                            <div className={s.funTitle} />
                            {
                                subscriptionPlan.map((item) => {
                                    let price: number | string = item.monthlyPrice;
                                    let planId = item.monthlyPlanId;
                                    if (tabInx === 2) {
                                        price = item.quarterlyPrice;
                                        planId = item.quarterlyPlanId;
                                    }
                                    if (tabInx === 3) {
                                        price = item.semiAnnualPrice || 0;
                                        planId = item.semiAnnualPlanId;
                                    }
                                    if (tabInx === 4) {
                                        price = item.yearlyPrice;
                                        planId = item.yearlyPlanId;
                                    }
                                    let className = s.planTitle;
                                    if (item.type === HIGH_LIGHT_SUB_TYPE && (!validDiscount?.couponInfo?.percentOff || tabInx !== validDiscountTimePeriod)) {
                                        className = `${s.planTitle} ${s.planTitleHighLight}`;
                                    }
                                    if (loadingSubscriptionPlan) {
                                        price = '';
                                    }
                                    let { isCurrent } = item;
                                    if (isCurrent && isFree) {
                                        //cancel
                                        isCurrent = false;
                                    }
                                    if (item.type === TSubscriptionType.FREE) {
                                        isCurrent = isFree;
                                    }

                                    let text = 'Upgrade';
                                    if (currentPlan && !isFree) {
                                        if (currentPlan.monthlyPrice > item.monthlyPrice) {
                                            text = 'Downgrade';
                                        }
                                    }
                                    let showDiscount = false;
                                    let discountPercent = '';
                                    let discountPrice = price;
                                    if (validDiscount && validDiscount.couponInfo.percentOff
                                        &&
                                        price &&
                                        validDiscount.productCodes && validDiscount.productCodes[0]) {
                                        const code = validDiscount.productCodes[0];
                                        if (tabInx === 1 && item.monthlyProductCode === code) {
                                            showDiscount = true;
                                            discountPercent = `${validDiscount.couponInfo.percentOff}%`;
                                            discountPrice = parseInt(`${((100 - validDiscount.couponInfo.percentOff) * parseInt(`${price}`, 10)) / 100}`, 10);
                                        } else if (tabInx === 2 && item.quarterlyProductCode === code) {
                                            showDiscount = true;
                                            discountPercent = `${validDiscount.couponInfo.percentOff}%`;
                                            discountPrice = parseInt(`${((100 - validDiscount.couponInfo.percentOff) * parseInt(`${price}`, 10)) / 100}`, 10);
                                        } else if (tabInx === 3 && item.semiAnnualProductCode === code) {
                                            showDiscount = true;
                                            discountPercent = `${validDiscount.couponInfo.percentOff}%`;
                                            discountPrice = parseInt(`${((100 - validDiscount.couponInfo.percentOff) * parseInt(`${price}`, 10)) / 100}`, 10);
                                        } else if (tabInx === 4 && item.yearlyProductCode === code) {
                                            showDiscount = true;
                                            discountPercent = `${validDiscount.couponInfo.percentOff}%`;
                                            discountPrice = parseInt(`${((100 - validDiscount.couponInfo.percentOff) * parseInt(`${price}`, 10)) / 100}`, 10);
                                        }
                                    }
                                    if (showDiscount) {
                                        className = `${s.planTitle} ${s.planTitleHighLight} ${s.planTitleSuperHighLight}`;
                                    }
                                    return (
                                        <div className={className} key={`${item.name}`}>
                                            {
                                                showDiscount &&
                                                <div className={s.dealLabel}>Exclusive deal</div>
                                            }
                                            <div className={s.name}>
                                                {item.name} <span className={s.highlight}>{item.highlight}</span>
                                            </div>
                                            <div className={s.des}>{item.des}</div>
                                            <div className={s.price}>
                                                {
                                                    !item.contactOnly && !showDiscount &&
                                                    <span className={s.priceVal}>
                                                        ${price}{price !== 0 && <span className={s.label}>/mo</span>}
                                                    </span>
                                                }
                                                {
                                                    !item.contactOnly && showDiscount &&
                                                    <div className={s.discountValWrap}>
                                                        <span className={s.priceVal}>
                                                            <span className={s.beforePrice}>${price}</span>
                                                            <span className={s.afterPrice}>${discountPrice}</span>
                                                            <span className={s.label}>/mo</span>
                                                        </span>
                                                        <span className={s.discount}>{discountPercent} off</span>
                                                    </div>
                                                }
                                                {
                                                    item.contactOnly &&
                                                    <span>
                                                        Let&apos;s talk
                                                    </span>
                                                }
                                            </div>
                                            <div className={s.priceTips}>
                                                {item.priceTips || ''}
                                            </div>
                                            <div className={s.btnWrap}>
                                                {
                                                    isCurrent &&
                                                    <Button
                                                        style={{
                                                            fontSize: '14px',
                                                        }}
                                                        disabled
                                                        block
                                                        size="large"
                                                    >
                                                        Current
                                                    </Button>
                                                }
                                                {
                                                    !item.contactOnly && !isCurrent &&
                                                    <SubscriptionButton
                                                        planId={planId}
                                                        size="large"
                                                        loading={!!loadingSubscriptionPlan}
                                                        type="primary"
                                                        block
                                                        disabled={!isFree}
                                                        text={text}
                                                        plan={item}
                                                        promotionCode={showDiscount ? validCoupon : ''}
                                                    />
                                                }
                                                {
                                                    !!item.contactOnly && !isCurrent &&
                                                    <Button
                                                        onClick={() => {
                                                            window.open(GROUP_PRICE_CONTACT_LINK, '_blank');
                                                        }}
                                                        loading={!!loadingSubscriptionPlan}
                                                        type="primary"
                                                        block
                                                    >Contact us
                                                    </Button>
                                                }
                                            </div>
                                        </div>);
                                })
                            }
                        </div>
                        {
                            funList.map((fun: TFunStatus) => {
                                return (
                                    <div className={s.planContentWrap} key={fun.name}>
                                        <div className={s.funContent}>
                                            {fun.name} {fun.sign || ''}
                                            <Popover placement="topLeft" content={<div className={s.funContentPop}>{fun.des}</div>} title={null}>
                                                <img src={Question} />
                                            </Popover>
                                        </div>
                                        {
                                            subscriptionPlan.map((item) => {
                                                const target = item.funList?.find((i: TFunStatus) => i.name === fun.name);
                                                const isSupport = target?.support;
                                                const supportText = target?.supportText;
                                                let className = s.planContent;
                                                if (item.type === HIGH_LIGHT_SUB_TYPE && (!validDiscount?.couponInfo?.percentOff || tabInx !== validDiscountTimePeriod)) {
                                                    className = `${s.planContent} ${s.planContentHighLight}`;
                                                }
                                                let showDiscount = false;
                                                if (validDiscount && validDiscount.couponInfo.percentOff) {
                                                    const code = validDiscount.productCodes[0];
                                                    if (tabInx === 1 && item.monthlyProductCode === code) {
                                                        showDiscount = true;
                                                    } else if (tabInx === 2 && item.quarterlyProductCode === code) {
                                                        showDiscount = true;
                                                    } else if (tabInx === 3 && item.semiAnnualProductCode === code) {
                                                        showDiscount = true;
                                                    } else if (tabInx === 4 && item.yearlyProductCode === code) {
                                                        showDiscount = true;
                                                    }
                                                }
                                                if (showDiscount) {
                                                    className = `${s.planContent} ${s.planContentHighLight} ${s.planContentSuperHighLight}`;
                                                }
                                                return (
                                                    <div className={className} key={`${item.name}`}>
                                                        <div className={s.planContentInner}>
                                                            {isSupport && !supportText && <img src={Check} />}
                                                            {supportText && <span>{supportText}</span>}
                                                            {
                                                                !isSupport && !supportText &&
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M5 15L15 5M5 5L15 15" stroke="#D1D5DB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            }
                                                        </div>
                                                    </div>);
                                            })
                                        }
                                    </div>
                                );
                            })
                        }
                    </Spin>
                    <div className={s.seeAll}>
                        <a
                            href={SUBSCRIPTION_DETAIL_LINK}
                            target="_black"
                        >
                            {'See all features ->'}
                        </a>
                    </div>
                    <div className={s.infoWrap}>
                        <div className={s.note}>
                            * Premium Channel subscription fees, Patient Booking fees, and Channel Ads fees, are charged directly by the respective channels and are not included in your subscription.
                        </div>
                        <div className={s.note}>
                            * Virtual phone line is for selected users only. Contact us to learn more about how to get your phone line.
                        </div>
                    </div>
                    <div className={s.groupWrap}>
                        <div className={s.groupInfo}>
                            <div className={s.groupTitle}>
                                Exploring plans for a <span className={s.highlight}>group practice</span>?
                            </div>
                            <div className={s.groupDes}>
                                Uncover our specialized group practice plan, meticulously crafted to foster efficient teamwork, streamline operations, and support the growth of expanding practices.
                            </div>
                        </div>
                        <Button
                            className={s.btn}
                            onClick={() => {
                                window.open(GROUP_PRICE_CONTACT_LINK, '_blank');
                            }}
                            size="large"
                            type="primary"
                        >
                            Contact us
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subscription;
