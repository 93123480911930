import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { message, Spin, Modal, Button } from 'antd';
import s from './s.module.less';
import SideStep from 'components/SideStep';
import Profile from 'components/ApplicationFormComponents/Profile';
import Services from 'components/ApplicationFormComponents/Services';
import KlarityServices from 'components/ApplicationFormComponents/KlarityServices';
import Practice from 'components/ApplicationFormComponents/Practice';
import HealthcareExperience from 'components/ApplicationFormComponents/HealthcareExperience';
import Education from 'components/ApplicationFormComponents/Education';
import AdditionalInformation from 'components/ApplicationFormComponents/AdditionalInformation';
import Insurance from 'components/ApplicationFormComponents/Insurance';
import Biographic from 'components/ApplicationFormComponents/Biographic';
import Preview from 'components/ApplicationFormComponents/Preview';
import Footer from './components/Footer';
import { StepData, StepKeys, STEPS } from './data';
import { EStep, StepValue } from './types';
import channelProviderStore, { initApplicationFormData } from 'store/ChannelApplicationProvider';
import useFetchProvider from './hooks/useFetchProvider';
import { useSearchParams, useNavigate } from 'react-router-dom';
// import ProviderStore from 'store/Provider';
import { updateStepForm } from 'api/applicationForm';
import useEditButtonRendering from './hooks/useEditButtonRendering';
import useStoreCurrentStep from './hooks/useStoreCurrentStep';
import useSetFormId from './hooks/useSetFormId';
import { CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY, CHANNEL_DETAIL, HOME, PRACTICE_FRONT_FROM, UNIPROFILE, UNIPROFILE_CREATION_ALISA } from 'constants/common';
import { transferFormValue, verifyService } from 'utils/channel';
import { NoticeType } from 'antd/es/message/interface';
import globalFormsEditStatusStore from 'store/ChannelApplicationProvider/FormsEditStatus';
import { IProfileFormSubmit } from 'types/channelApplicationProvider';
import { getUrlParam, isLocalhost } from 'utils/common';
import License from 'components/ApplicationFormComponents/License';
import { PATH } from 'constants/path';
import useLoadBasicData from 'hooks/useLoadBasicData';
import { ServiceExt, UniprofileFormData } from 'types/provider';
import useChannelHomeInfo from 'hooks/useChannelHomeInfo';
import { checkHasSubmit, shouldShowKlarityPlanUI } from 'utils/provider';
import UniprofileCheckFinishedTooltipBar from 'components/UniprofileCheckFinishedTooltipBar';
import uniprofileResumeStore from 'store/UniprofileResume';
import stepPercentageStore from 'store/UniprofileResume/stepPercentage';
import useGetUniprofileResumeInfo from 'hooks/useGetUniprofileResumeInfo';
import { getAvaDocumentId } from 'utils/localstore';
import { showAvaTipSteps } from 'constants/AIresume';
import useProviderServiceFlag from 'hooks/useProviderServiceFlag';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';

const CreateUniProfilePage = () => {
    const [showExitWarningModal, setShowExitWarningModal] = useState<boolean>(false);
    const [homeInfo, loadingProviderHome] = useProviderHomeInfo();
    const isKlarityUser = shouldShowKlarityPlanUI(homeInfo);
    const [modal, modalContextHolder] = Modal.useModal();
    const [info, loadingHome] = useChannelHomeInfo();
    const practiceRef = useRef();
    const additionalRef = useRef();
    const rightContentRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const type = getUrlParam('type');
    const isAva = getUrlParam('isAva') === 'true';
    const from = getUrlParam('from');
    const hideTopRequiredTipSteps = useRef([EStep.BIOGRAPHIC, EStep.KLARITY_SERVICES]);
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [showTopRequiredTip, setShowTopRequiredTip] = useState(true);
    const [showLeavingWarningModal, setShowLeavingWarningModal] = useState<boolean>(false);
    const [params] = useSearchParams();
    const channelId = Number(params.getAll('channelId')[0]);
    const [currentStep, setCurrentStep] = useState(Number(sessionStorage.getItem(CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY)) || EStep.PROFILE);
    // const [currentStep, setCurrentStep] = useState(Number(sessionStorage.getItem(CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY)) || EStep.LICENSE);
    // const [submitSuccess, setSubmitSuccess] = useState(false);
    // const [formId, setFormId] = useState(StepValue.PROFILE);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [hideTip, setHideTip] = useState<boolean>(false);
    const [getProvider] = channelProviderStore.useStore();
    const [getUniprofileResume] = uniprofileResumeStore.useStore();

    // const [getUser] = ProviderStore.useStore();
    // const providerData = getProvider('data');
    // const providerData = getUniprofileResume('data');

    const [providerData, setProviderData] = useState<UniprofileFormData>(initApplicationFormData);
    const {
        profile,
        services,
        practice,
        experienceList,
        educationList,
        addtional,
        insurance,
        licenses,
        malpracticeInsuranceCertificate,
        physicians,
        biographic,
        conversationId,
        deaLicensePrescribeStatus,
        identificationList,
    } = providerData!;
    const [serviceData, setServiceData] = useState<Array<ServiceExt>>([]);
    const profileStatus = info?.profileStatus;
    const [getStepPercentage, setStepPercentage] = stepPercentageStore.useStore();
    const stepPercentage = getStepPercentage('data');
    const uniprofileResumeData = getUniprofileResume('data');
    const uniprofileCreationData = getProvider('data');
    const [, infoLoading, , fetchResuInfo] = useGetUniprofileResumeInfo();
    const { refetch, loading: fetchProviderLoading } = useFetchProvider({ ignore: isAva });

    const [disallowNext, setDisallowNext] = useState(false);
    const [disallowNextTips, setDisallowNextTips] = useState('');
    const step = getUrlParam('step');
    const [uniProfileFlag, uniProfileFlagLoading, refetchUniProfileFlag] = useProviderServiceFlag();
    const showKlarityService = uniProfileFlag?.klarityServiceFlag;
    const showKiwiService = uniProfileFlag?.kiwiServiceFlag;
    const [showUseAvaTip, setShowUseAvaTip] = useState(true);
    const [forceCloseUseAvaTip, setForceCloseUseAvaTip] = useState(false);
    const uniprofileSubmitted = !!(homeInfo?.uniprofileSubmitted);
    // console.log({
    //     showKlarityService,
    //     showKiwiService,
    // });
    useEffect(() => {
        if (forceCloseUseAvaTip) {
            setShowUseAvaTip(false);
        } else {
            setShowUseAvaTip(!isAva && currentStep !== EStep.PREVIEW);
        }
    }, [isAva, currentStep]);

    // null 说明不允许到下一步，undefined 说明走下一步的逻辑，有数值走这个数值的步骤
    const stepClicked = useRef<EStep | null | undefined>();

    // const [refetchCallback, setRefetchCallback] = useState(refetch);
    const refetchCallback = useRef(refetch);

    const hasSubmit = checkHasSubmit(profileStatus);
    const initPracticeName = useMemo(
        () => `${profile.firstName} ${profile.lastName}'s Practice`,
        [profile],
    );

    useEffect(() => {
        if (isAva) {
            const documentId = getAvaDocumentId();
            if (documentId) {
                fetchResuInfo(documentId);
            }
        }
    }, [isAva]);
    useEffect(() => {
        if (hasSubmit && !step) {
            //todo to remove
            // if (isLocalhost()) {
            //     setCurrentStep(EStep.KLARITY_SERVICES);
            //     return;
            // }
            setCurrentStep(EStep.PREVIEW);
        }
    }, [hasSubmit, step]);

    useEffect(() => {
        if (step) {
            setCurrentStep(Number(step));
        }
    }, [step]);

    useStoreCurrentStep(currentStep);
    const { formId, setFormId } = useSetFormId(currentStep);
    const [getGlobalEditStatus, setGloalEditStatus] = globalFormsEditStatusStore.useStore();
    const globalFormsEditStatus = getGlobalEditStatus('globalEditStatus');

    useEffect(() => {
        if (services && services.length > 0) {
            setServiceData(services);
        }
    }, [services]);

    useEffect(() => {
        setGloalEditStatus('globalEditStatus', false);

        setTimeout(() => {
            rightContentRef.current?.scrollTo({
                left: 0,
                top: 0,
            });
        }, 100);

        return () => setGloalEditStatus('globalEditStatus', false);
    }, [currentStep]);

    const [loadBaiscData] = useLoadBasicData();

    useEffect(() => {
        loadBaiscData();
    }, [loadBaiscData]);

    // 隐藏顶部的Indicates required fields文案
    useEffect(() => {
        setShowTopRequiredTip(!hideTopRequiredTipSteps.current.includes(currentStep));
    }, [currentStep]);

    // const user = getUser('data');

    // step ref
    const stepRef = useRef();

    useEffect(() => {
        if (isAva) {
            if (uniprofileResumeData) {
                setProviderData(uniprofileResumeData);
            }
        } else if (uniprofileCreationData) {
            setProviderData(uniprofileCreationData);
        }
    }, [isAva, uniprofileResumeData, uniprofileCreationData]);

    useEffect(() => {
        if (isAva) {
            refetchCallback.current = async () => {
                const documentId = getAvaDocumentId();
                if (documentId) {
                    await fetchResuInfo(documentId);
                }
            };
        } else {
            refetchCallback.current = refetch;
        }
    }, [isAva]);

    const [showEditButtons] = useEditButtonRendering(currentStep);

    const handleMessageContent = useCallback((nType: NoticeType, content: string) => {
        messageApi.open({
            type: nType,
            content,
        });
    }, [messageApi]);

    const onExit = useCallback(() => {
        setShowExitWarningModal(false);
        sessionStorage.removeItem(CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY);
        if (from === PRACTICE_FRONT_FROM) {
            navigate(`${PATH.DASHBOARD}/${PATH.PRACTICE_FRONT}`, { replace: true });
        } else if (from === CHANNEL_DETAIL) {
            navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL_DETAIL}?channelId=${channelId}`, { replace: true });
        } else if (from === HOME) {
            navigate(`${PATH.DASHBOARD}/${PATH.PROVIDER_HOME}`, { replace: true });
        } else {
            navigate(`${PATH.DASHBOARD}/${PATH.PROVIDER_HOME}`, { replace: true });
        }
        // setGloalEditStatus('globalEditStatus', false);
    }, [channelId, from, navigate]);

    const handleSubmitRequest = useCallback((formValue: Partial<IProfileFormSubmit>, step: StepValue) => {
        let stepValue = step;

        // 后端写错了，所以需要强制改为LICENCE
        if (step === StepValue.LICENSE) {
            stepValue = 'LICENCE';
        }
        const submitData = {
            id: profile.id,
            channelId,
            // providerId: user?.id,
            ...transferFormValue(formValue, step),
            step: stepValue.toUpperCase() || formId.toUpperCase(),
        };

        return updateStepForm(submitData);
    }, [channelId, formId, profile.id]);

    // for 'save changes' button submit
    const onSubmit = useCallback(async (formValue: Partial<IProfileFormSubmit>, step: StepValue) => {
        setLoading(true);
        // setSubmitSuccess(false);

        const submitRes = await handleSubmitRequest(formValue, step);
        if (!submitRes.error) {
            // setSubmitSuccess(true);
            stepRef.current?.setStepEditStatus?.(false, step);
            const index = STEPS.findIndex((e) => e === step);
            setStepPercentage('data', {
                ...stepPercentage,
                [StepKeys[index]]: {
                    percent: 1,
                    unfinished: [],
                },
            });
            setLoading(false);
            // refetch();
            refetchCallback.current?.();

            return true;
        } else {
            handleMessageContent('error', 'save failed!');
            console.error(submitRes);
        }

        setLoading(false);
    }, [handleMessageContent, handleSubmitRequest, setStepPercentage, stepPercentage]);

    const handleLeavingPage = useCallback(() => { }, []);
    const hideLeavingModal = useCallback(() => {
        setShowLeavingWarningModal(false);
    }, []);
    // for next button submit
    const onNext = useCallback(async (formValue: any) => {
        if (stepClicked.current === null) {
            return;
        }
        const isEditing = globalFormsEditStatus;
        let submitResult;

        if (!isEditing) {
            let kiwiServiceFlag = showKiwiService;
            let klarityServiceFlag = showKlarityService;
            if (formValue && currentStep !== EStep.KLARITY_SERVICES && currentStep !== EStep.SERVICES && currentStep !== EStep.PRACTICE && currentStep !== EStep.ADDITIONAL_INFORMATION) {
                let submitData = formValue;
                if (STEPS[currentStep] === StepValue.PROFILE) {
                    submitData = {
                        ...formValue,
                        credential: formValue?.credential?.join(','),
                    };
                }
                // console.log({
                //     submitData,
                // })
                // return;
                submitResult = await onSubmit(submitData, STEPS[currentStep]);
                // 因为新用户显示哪种service需要根据license来决定，所以在license保存完之后，重新调用一下checkUniprofileFlag。
                if (currentStep === EStep.LICENSE) {
                    const flagResult = await refetchUniProfileFlag();

                    if (flagResult) {
                        kiwiServiceFlag = flagResult.kiwiServiceFlag;
                        klarityServiceFlag = flagResult.klarityServiceFlag;
                    }
                }
                refetchCallback.current?.();
                // setGloalEditStatus('globalEditStatus', false);
            } else if (currentStep === EStep.PRACTICE) {
                const res = await practiceRef.current?.submitForm();

                submitResult = res && !res.error;
            } else if (currentStep === EStep.ADDITIONAL_INFORMATION) {
                const res = await additionalRef.current?.submitForm();

                submitResult = res && !res.error;
            } else if (currentStep === EStep.SERVICES) {
                const verifyResult = verifyService(services);

                if (!verifyResult.verifyResult) {
                    setServiceData(verifyResult.services);
                    message.error(verifyResult.errorMessage);
                    submitResult = false;
                } else {
                    submitResult = true;
                }
            } else {
                submitResult = true;
            }

            if (submitResult) {
                setTimeout(() => {
                    const nextStep = typeof stepClicked.current === 'number' ? stepClicked.current : currentStep + 1;
                    let currentStepVal = nextStep > EStep.PREVIEW ? EStep.PREVIEW : nextStep;

                    if (currentStepVal === EStep.SERVICES && !kiwiServiceFlag) {
                        currentStepVal = EStep.KLARITY_SERVICES;
                    }
                    if (currentStepVal === EStep.KLARITY_SERVICES && !klarityServiceFlag) {
                        currentStepVal = EStep.INSURANCE;
                    }

                    setFormId(STEPS[currentStepVal]);
                    setCurrentStep(currentStepVal);
                    stepClicked.current = undefined;
                }, 100);
            }
        } else {
            handleMessageContent('error', 'Please save your changes before proceeding!');
        }
    }, [currentStep, globalFormsEditStatus, handleMessageContent, onSubmit, refetchUniProfileFlag, services, setFormId, showKiwiService, showKlarityService]);

    const onBack = useCallback(() => {
        const nextStep = currentStep - 1;
        if (isAva && nextStep < EStep.PROFILE) {
            navigate(`/${PATH.UNIPROFILE_RESUME}?from=${UNIPROFILE_CREATION_ALISA}`);
        } else {
            let currentStepVal = nextStep < EStep.PROFILE ? EStep.PROFILE : nextStep;
            if (currentStepVal === EStep.KLARITY_SERVICES && !showKlarityService) {
                currentStepVal = EStep.SERVICES;
            }
            if (currentStepVal === EStep.SERVICES && !showKiwiService) {
                currentStepVal = EStep.LICENSE;
            }
            setFormId(STEPS[currentStepVal]);
            setCurrentStep(currentStepVal);
        }
        // setGloalEditStatus('globalEditStatus', false);
    }, [currentStep, isAva, navigate, setFormId, showKiwiService, showKlarityService]);

    const handlePreviewModalVisible = useCallback((show: boolean) => {
        setShowPreviewModal(show);
    }, []);

    const onPreviewSubmit = useCallback(() => {
        const isEditing = globalFormsEditStatus;
        // 初始化service，检查service里有无错误数据，因为service的初始化数据会残缺
        // 比如 price，初始化都不会设置，但是一开始service并不会进入编辑状态
        // 所以需要在非编辑状态下检测数据完整性，避免用户之间点next或者submit
        const verifyResult = verifyService(services);

        if (isEditing) {
            handleMessageContent('error', 'Please save your changes before proceeding!');
        } else if (!verifyResult.verifyResult) {
            setServiceData(verifyResult.services);
            message.error(verifyResult.errorMessage);
        } else if (type === UNIPROFILE) {
            handlePreviewModalVisible(true);
        } else {
            stepRef.current?.handlePreviewConfirmSubmit?.();
        }
    }, [globalFormsEditStatus, handleMessageContent, handlePreviewModalVisible, services, type]);

    const handleStepClick = useCallback((step: number) => {
        if (uniprofileSubmitted) {
            // stepClicked.current = step;

            // if (showKlarityService && currentStep === EStep.KLARITY_SERVICES) {

            setCurrentStep(step);
            // }
        }
        // if (isAva) {
        //     const address = practice?.addressList;

        //     if (step === EStep.SERVICES && ((!address || address.length === 0) || (address && address[0] && !address[0].practiceAddress))) {
        //         //..检查Practice address
        //         stepClicked.current = null;
        //         modal.warning({
        //             title: 'Complete the Practice section first',
        //             content: 'Looks like your Practice section isn\'t finished yet. Completing this section is essential before you can add details to the Service section. It ensures your services are accurately aligned with your practice information.',
        //             width: 512,
        //             okText: 'Go to Practice',
        //             onOk: () => {
        //                 setCurrentStep(EStep.PRACTICE);
        //             },
        //         });
        //     } else {
        //         stepClicked.current = step;
        //     }
        // }
    }, [isAva, modal, practice, uniprofileSubmitted, showKlarityService, currentStep]);

    const handleHideTip = useCallback(() => setHideTip(true), []);

    const usedStep = StepData?.map((item) => {
        if (!showKiwiService && item.title === 'SERVICES') {
            return {
                ...item,
                hide: true,
            };
        }
        if (!showKlarityService && item.title === 'KLARITY SERVICE AND PRICE') {
            return {
                ...item,
                hide: true,
            };
        }
        return item;
    });

    const stepContent = useMemo(() => {
        let content = null;

        switch (currentStep) {
            case EStep.PROFILE:
                content = <Profile isAva={isAva} ref={stepRef} channelId={channelId} editable showEditButtons={showEditButtons} initFormValue={profile} onNext={onNext} onSubmit={onSubmit} refetch={refetchCallback.current} conversationId={conversationId} />;
                break;
            case EStep.SERVICES:
                content = <Services addressList={practice?.addressList} onShowMessage={handleMessageContent} initFormValue={serviceData} refetch={refetchCallback.current} />;
                break;
            case EStep.KLARITY_SERVICES:
                content = <KlarityServices checkTargetSpecialty={true} addressList={practice?.addressList} licenses={licenses} onShowMessage={handleMessageContent} refetch={refetchCallback.current} setDisallowNext={setDisallowNext} setDisallowNextTips={setDisallowNextTips} />;
                break;
            case EStep.PRACTICE:
                content = <Practice ref={stepRef} allStartEditing ref={practiceRef} editable showEditButtons={false} initFormValue={practice} initPracticeName={initPracticeName} onNext={onNext} onSubmit={handleSubmitRequest} refetch={refetchCallback.current} providerId={profile.id} />;
                break;
            case EStep.HEALTHCARE_EXPERIENCE:
                content = <HealthcareExperience editable showEditButtons={showEditButtons} initFormValue={experienceList} onNext={onNext} onSubmit={onSubmit} />;
                break;
            case EStep.EDUCATION:
                content = <Education editable showEditButtons={showEditButtons} initFormValue={educationList} onNext={onNext} onSubmit={onSubmit} />;
                break;
            case EStep.ADDITIONAL_INFORMATION:
                content = <AdditionalInformation allStartEditing ref={additionalRef} editable showEditButtons={showEditButtons} initFormValue={addtional} onNext={onNext} onSubmit={onSubmit} refetch={refetchCallback.current} />;
                break;
            case EStep.INSURANCE:
                content = <Insurance ref={stepRef} editable initFormValue={insurance} showEditButtons={showEditButtons} onNext={onNext} onSubmit={onSubmit} />;
                break;
            case EStep.BIOGRAPHIC:
                content = <Biographic ref={stepRef} editable initFormValue={biographic} showEditButtons={showEditButtons} onNext={onNext} onSubmit={onSubmit} />;
                break;
            case EStep.LICENSE:
                content = (
                    <License
                        deaLicensePrescribeStatus={deaLicensePrescribeStatus}
                        currentStep={currentStep}
                        isKlarityUser={showKlarityService}
                        onShowMessage={handleMessageContent}
                        editable
                        initLicenseFormValue={licenses}
                        initPhysicianFormValue={physicians}
                        initPersonalIdentificationValue={identificationList?.[0]}
                        initMalpracticeInsuranceCertificateValue={
                            malpracticeInsuranceCertificate
                        }
                        onNext={onNext}
                        onSubmit={onSubmit}
                        refetch={refetchCallback.current}
                    />
                );
                break;
            case EStep.PREVIEW:
                // 这里需要传serviceData给preview， 之前preview是从源provider data里拿的
                // 但是现在service需要校验信息。所以不能从provider data里直接拿原始service
                content = <Preview isKlarityUser={isKlarityUser} deaLicensePrescribeStatus={deaLicensePrescribeStatus} uniProfileFlag={uniProfileFlag} isAva={isAva} from={from} service={serviceData} onShowMessage={handleMessageContent} channelId={channelId} ref={stepRef} onSubmit={onSubmit} showAuthModal={showPreviewModal} refetch={refetchCallback.current} onShowAuthModal={handlePreviewModalVisible} />;
                break;
            default: content = null;
        }

        return content;
    }, [currentStep, channelId, showEditButtons, profile, onNext, onSubmit, conversationId, practice, handleMessageContent, serviceData, licenses, initPracticeName, handleSubmitRequest, experienceList, educationList, addtional, insurance, biographic, physicians, malpracticeInsuranceCertificate, uniProfileFlag, isAva, from, showPreviewModal, handlePreviewModalVisible, deaLicensePrescribeStatus]);

    const isLoading = loading || fetchProviderLoading || loadingHome || uniProfileFlagLoading || loadingProviderHome;

    return (
        <Spin tip="Loading" size="large" spinning={isLoading}>
            {modalContextHolder}
            <Modal
                title="Leave the page?"
                centered
                open={showLeavingWarningModal}
                okText="Leave the page"
                onOk={handleLeavingPage}
                onCancel={hideLeavingModal}
                width={543}
            >
                <p>You have made changes that have not been saved. Are you sure you want to leave the page now? You will lose all the progress on this page.</p>
            </Modal>
            <Modal
                width={780}
                closable={false}
                open={showExitWarningModal}
                footer={
                    <div className={s.modalFooter}>
                        <Button onClick={onExit}>Exit anyway</Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                setShowExitWarningModal(false);
                            }}
                        >Continue creating UniProfile
                        </Button>
                    </div>
                }
            >
                <div className={s.modalWrap}>
                    <div className={s.tipIcon} />
                    <div className={s.content}>
                        <div className={s.title}>Confirm exit: unsaved information</div>
                        <div className={s.descBox}>
                            <div className={s.desc}>You’re about to leave the UniProfile setup process. If you exit now, any unsaved information may be lost, which could delay the completion of your profile. We highly recommend finishing and submitting your UniProfile to ensure your practice is fully set up and ready to launch on our platform. Completing your UniProfile will help you get the most out of our services, including patient engagement and visibility across all channels.</div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className={s.wrapper}>
                <div className={s.header}>
                    <img
                        onClick={() => {
                            navigate(`${PATH.DASHBOARD}`);
                        }}
                        src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_kiwihealth_by_klarity_380x96.png"
                        className={s.logo}
                    />
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.292894 9.70711C-0.0976307 9.31658 -0.0976307 8.68342 0.292894 8.29289L3.58579 5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292894C0.683417 -0.0976312 1.31658 -0.0976312 1.70711 0.292894L5.70711 4.29289C6.09763 4.68342 6.09763 5.31658 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683418 10.0976 0.292894 9.70711Z" fill="#4B5563" />
                    </svg>
                    <span>UniProfile</span> */}
                </div>
                {contextHolder}
                <div className={s.contentWrap}>
                    <div className={s.sideStepWrap}>
                        <SideStep isAva={isAva} percentages={stepPercentage} steps={usedStep} currentStep={currentStep} onStepClick={handleStepClick} onClickNext={onNext} uniprofileSubmitted={uniprofileSubmitted} />
                    </div>

                    <div id="ks-form-content" ref={rightContentRef} className={currentStep === EStep.KLARITY_SERVICES ? `${s.formContent} ${s.formContentWithPadding}` : s.formContent}>
                        {
                            !hideTip && (from === PRACTICE_FRONT_FROM || from === CHANNEL_DETAIL) &&
                            <div className={s.tip}>
                                <div className={s.tipContent}>Please complete UniProfile first before {from === CHANNEL_DETAIL ? 'listing on any channel.' : 'creating your Practice Front.'} </div>
                                <div className={s.close} onClick={handleHideTip} />
                            </div>
                        }
                        {
                            showUseAvaTip && (
                                <div className={s.useAvaTip} id="ava-tips">
                                    <div className={s.useAvaTipInner}>
                                        <div className={s.tipText}>💡 Tired of manual entry? Upload your resume & let <span className={s.avaLogo}>Ava</span> do the work!</div>
                                        <div className={s.actionBox}>
                                            <Button
                                                className={s.backToUploadButton}
                                                onClick={() => {
                                                    navigate(`/${PATH.UNIPROFILE_RESUME}?type=uniprofile`);
                                                }}
                                            >Upload my resume
                                            </Button>
                                            <div
                                                className={s.closeIcon}
                                                onClick={() => {
                                                    setShowUseAvaTip(false);
                                                    setForceCloseUseAvaTip(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className={s.indicate}>
                            {
                                showTopRequiredTip &&
                                <>
                                    <a>*</a> Indicates required fields
                                </>
                            }
                        </div>
                        {
                            isAva && showAvaTipSteps.includes(STEPS[currentStep]) &&
                            <div className={s.checkFinishedTooltip} style={{ top: showUseAvaTip ? '86px' : 0 }}>
                                <UniprofileCheckFinishedTooltipBar step={STEPS[currentStep]} fields={stepPercentage[StepKeys[currentStep]]?.unfinished} />
                            </div>
                        }
                        <div className={s.box}>
                            {stepContent}
                        </div>
                    </div>
                </div>
                {/* todo keep hidebackbutton */}
                <Footer
                    isAva={isAva}
                    hideBackButton={(!!hasSubmit && currentStep === EStep.PREVIEW)}
                    formId={formId}
                    disallowNext={disallowNext}
                    disallowNextTips={disallowNextTips}
                    step={currentStep}
                    loading={loading}
                    onClickExit={() => {
                        if (!uniprofileSubmitted) {
                            setShowExitWarningModal(true);
                        } else {
                            onExit();
                        }
                    }}
                    onClickNext={onNext}
                    onClickBack={onBack}
                    onClickSubmit={onPreviewSubmit}
                />
            </div>
        </Spin>
    );
};

export default CreateUniProfilePage;
